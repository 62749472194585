import React, { useState } from "react";
import CustomModal from ".";
import { useTranslation } from "react-i18next";
import Button from "../buttons";

interface Props {
  content: any;
  setModal: (data: any) => void;
}

export default function FaqReadMore({ content, setModal }: Props) {
  const { t } = useTranslation();
  console.log(content);

  const closeModal = () => {
    setModal(false);
  };

  return (
    <CustomModal
      className="read-more-modal"
      isOpen={content ? true : false}
      closeModal={closeModal}
    >
      <div className="react-modal-header">
        <div className="d-flex-center">
          <img src={content.icon} width={36} alt="icon" />
          <div className="ml-3">
            <h5 className="size-5 natural-2 mb-1 text-bold">
              {t(content.title)}
            </h5>
            <h6 className="size-7 primary-1 text-bold mb-0">
              {t(content.subTitle)}
            </h6>
          </div>
        </div>
        <Button
          className="modal-close-btn natural-6-border"
          onClick={closeModal}
        >
          <img src="/icons/close.svg" width={24} alt="close" />
        </Button>
      </div>
      <div className="react-modal-body">
        <h5 className="size-7 natural-4 text-bold description">
          {t(content.description)}
        </h5>
        <h6 className="size-7 natural-4 text-bold mb-0">
          {t(content.subDescription)}
        </h6>
      </div>
    </CustomModal>
  );
}
