import React, { ChangeEvent, useState } from "react";
import CustomModal from ".";
import { useTranslation } from "react-i18next";
import Button from "../buttons";
import { SERVICES } from "../constants";
import { shopRecordService, trackingRecordService } from "@/api/order";
import { toast } from "react-toastify";
import LoaderLayout from "../LoaderLayout";

interface Props {
  content: any;
  setModal: (data: any) => void;
}

export default function Services({ content, setModal }: Props) {
  const { t } = useTranslation();
  const [services, setServices] = useState(content.additional_services || []);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setModal({ service: false, content: content });
  };

  const handleSelect = (selected: ServiceType) => {
    const isActive = services.find(
      (ser: ServiceType) => ser.service === selected.service
    );
    if (isActive) {
      return setServices(
        services.filter((s: ServiceType) => s.service !== selected.service)
      );
    }
    setServices([...services, selected]);
  };

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newServices = services.map((item: ServiceType) => {
      if (item.service === "save_packing") {
        return { ...item, value: e.target.value };
      }
      return item;
    });
    setServices(newServices);
  };

  const submit = async () => {
    setLoading(true);
    try {
      if (content.link) {
        const { data } = await shopRecordService(content.id, {
          additional_services: services,
        });
        setModal({ content: data, service: false });
      } else {
        const { data } = await trackingRecordService(content.id, {
          additional_services: services,
        });
        setModal({ content: data, service: false });
      }
      toast.success("Services successfully added");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <CustomModal
      className="service-modal"
      isOpen={content ? true : false}
      closeModal={closeModal}
    >
      <div className="react-modal-header">
        <div className="">
          <h5 className="size-6 natural-2 mb-1 lh-32 text-bold">
            {t("Дополнительные услуги")}
          </h5>
          <h6 className="size-9 natural-4 lh-20 mb-0">
            {t(
              "Вы можете выбрать адрес получателя из сохранившехся адресов, либо добавить новый адрес"
            )}
          </h6>
        </div>
        <Button
          className="modal-close-btn natural-6-border"
          onClick={closeModal}
        >
          <img src="/icons/close.svg" width={24} alt="close" />
        </Button>
      </div>
      <hr className="my-4" />
      <div className="react-modal-body row">
        {SERVICES.map((service) => {
          const isActive = services.find(
            (s: ServiceType) => s.service === service.service
          );
          return (
            <div className="col-md-6 col-12" key={service.service}>
              <button
                onClick={(e: any) => {
                  if (e.target.tagName.toLowerCase() !== "select") {
                    handleSelect(service);
                  }
                }}
                className={`simple-btn service-btn ${isActive ? "active" : ""}`}
              >
                <img src={service.logo} width={64} alt="service-logo" />
                <div className="header-info ml-3 align-left w-100">
                  <h4 className="size-7 natural-2 text-bold mb-1 lh-24">
                    {t(service.service)}
                  </h4>
                  <div className="d-flex-center">
                    {service.service === "save_packing" && (
                      <select
                        className="custom-select text-bold natural-3 lh-24 mr-3"
                        name="save-packing"
                        defaultValue={isActive?.value}
                        disabled={!isActive}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <option value="">{t("Выбирать")}</option>
                        <option value="bubble">{t("Пузырьковый лист")}</option>
                        <option value="carton">{t("Картон")}</option>
                        <option value="foam_plastic">{t("Пенопласт")}</option>
                      </select>
                    )}
                    <h4 className="size-7 primary-5 text-bold mb-0 lh-24">
                      {service.price > 0
                        ? `${service.price} $ ${t("за")} 1${t("кг")}`
                        : t("Бесплатно")}
                    </h4>
                  </div>
                </div>
              </button>
            </div>
          );
        })}
      </div>
      <hr className="mt-1 mb-4" />
      <Button className="add-btn w-100 primary-1-bg natural-8" onClick={submit}>
        <LoaderLayout
          style={{ width: "14px" }}
          loading={loading}
          children={t("Добавить")}
        />
      </Button>
    </CustomModal>
  );
}
