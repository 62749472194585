import Button from "@/components/buttons";
import LinkButton from "@/components/buttons/LinkButton";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Context, copy } from "@/components/utils";
import { SET_CONFIRM, SET_USER } from "@/controllers/actions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConfirmModal from "@/components/modals/ConfirmModal";

export default function ProfileRouter() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    state: {
      confirm,
      user: { customer },
    },
    dispatch,
  } = useContext(Context);
  const [copied, setCopied] = useState({
    id: false,
    id_code: false,
  });

  const logOut = () => {
    dispatch({ type: SET_CONFIRM, payload: false });
    localStorage.removeItem("token");
    dispatch({ type: SET_USER, payload: null });
    navigate("/login");
  };

  const copyContent = (htmlId: string) => {
    setCopied({ ...copied, [htmlId]: true });
    copy(htmlId);
    setTimeout(() => {
      setCopied({ ...copied, [htmlId]: false });
    }, 3000);
  };

  return (
    <>
      <div className="profile-router box-shadow">
        <div className="user-info">
          <h4 className="size-6 text-bold m-0">
            {customer.first_name} {customer.last_name}
          </h4>
          <div className="d-flex-center justify-content-between flex-wrap">
            <button
              onClick={() => copyContent("id")}
              className="px-0 border-0 bg-transparent pointer copy-btn fw-normal natural-4 size-8"
            >
              ID:&nbsp;
              <span id="id" className="natural-3 text-bold">
                {customer.id}
              </span>
              <img
                src={`/icons/${copied.id ? "copied" : "copy"}.svg`}
                width={16}
                alt="copy"
              />
            </button>
            <button
              onClick={() => copyContent("id_code")}
              className="px-0 border-0 bg-transparent pointer copy-btn fw-normal natural-4 size-8"
            >
              {t("Или")}:&nbsp;
              <span id="id_code" className="natural-3 text-bold">
                {customer.id_code}
              </span>
              <img
                src={`/icons/${copied.id_code ? "copied" : "copy"}.svg`}
                width={16}
                alt="copy"
              />
            </button>
          </div>
        </div>
        <div className="nav-list">
          <Link
            to="profile"
            className={`d-flex-center nav-item size-8 text-bold ${
              pathname === "/profile" ? "natural-2" : "natural-4"
            }`}
          >
            <img src="/icons/home.svg" className="mr-2" alt="star" />
            {t("Мой кабинет")}
          </Link>
          <Link
            to="/parcels/orders"
            className={`d-flex-center nav-item size-8 text-bold ${
              pathname.startsWith("/parcels/") ? "natural-2" : "natural-4"
            }`}
          >
            <img src="/icons/box.svg" className="mr-2" alt="star" />
            {t("Мои посылки")}
          </Link>
          <Link
            to="addresses"
            className={`d-flex-center nav-item size-8 text-bold ${
              pathname === "/addresses" ? "natural-2" : "natural-4"
            }`}
          >
            <img src="/icons/location.svg" className="mr-2" alt="star" />
            {t("Мои адреса")}
          </Link>
          <Link
            to="balance"
            className={`d-flex-center nav-item size-8 text-bold ${
              pathname === "/balance" ? "natural-2" : "natural-4"
            }`}
          >
            <img src="/icons/wallet.svg" className="mr-2" alt="star" />
            {t("Мой баланс")}
          </Link>
          <Link
            to="settings"
            className={`d-flex-center nav-item size-8 text-bold ${
              pathname === "/settings" ? "natural-2" : "natural-4"
            }`}
          >
            <img src="/icons/settings.svg" className="mr-2" alt="star" />
            {t("Мои настройки")}
          </Link>
          <button
            onClick={() => dispatch({ type: SET_CONFIRM, payload: true })}
            className="btn p-0 bg-transparent d-flex-center nav-item size-8 natural-4 text-bold"
          >
            <img src="/icons/logout.svg" className="mr-2" alt="star" />
            {t("Выйти")}
          </button>
          <LinkButton
            to="help"
            className="simple-btn natural-6-border natural-2 size-8"
          >
            {t("Помощь")}
          </LinkButton>
          <LinkButton
            to="contacts"
            className="simple-btn natural-6-border natural-2 size-8"
          >
            {t("Связаться с нами")}
          </LinkButton>
        </div>
      </div>
      {confirm && (
        <ConfirmModal onClick={logOut} text="Вы действительно хотите выйти?" />
      )}
      {/* <OrderCountsByStatus /> */}
    </>
  );
}
