import Banner from "@/components/banners";
import PagePagination from "@/components/page-navigation";
import React from "react";
import { useTranslation } from "react-i18next";

const links = [
  {
    to: "/",
    text: "Главная",
    className: "natural-4 text-bold size-8",
  },
  {
    to: "/services",
    text: "Услуги",
    className: "natural-2 text-bold size-8",
  },
];

export default function Services() {
  const { t } = useTranslation();

  return (
    <div className="services-page">
      <PagePagination backText={t("Back")} links={links} />
      <div className="container">
        <div className="row first-row">
          <div className="col-md-5 pr-md-0 col-12">
            <h6 className="size-6 text-bold lh-32 primary-1 mb-3">
              {t("Услуги и цены")}
            </h6>
            <h4 className="size-4 text-bold mb-3">
              {t(
                "В стоимость отправки включено множество базовых бесплатных услуг."
              )}
            </h4>
            <span className="size-7 natural-4 lh-24 text-bold ls-0">
              {t(
                "Кроме того, вы можете воспользоваться дополнительными опциями: например, добавить дополнительную упаковку, измерить вещь и проверить работоспособность товара через систему спецзапросов или выбрать услугу “Помощь при покупке”."
              )}
            </span>
          </div>
          <div className="col-md-7">
            <Banner width="560px" src="/images/computer.png" />
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container">
          <div className="row info-row mb-4">
            <div className="col-md-4 col-12">
              <div className="d-flex h-100 flex-column justify-content-between">
                <h4 className="size-4 text-bold natural-3 m-0">
                  {t("Консолидация посылок")}
                </h4>
                <h6 className="size-6 text-bold lh-32 primary-4 m-0">
                  {t("Бесплатно")}
                </h6>
              </div>
            </div>
            <div className="col-md-8 col-12 pr-0">
              <span className="size-7 lh-24 text-bold ls-0 ws-pw">
                {t(
                  "Бандеролька объединит все ваши покупки, не взяв за это ни цента. Вы можете отправить товары из разных интернет-магазинов одной посылкой, сэкономив на доставке.\n\nВнимание: товары, заказанные в Турции через «Помощь при покупке», невозможно объединить в одну отправку с самостоятельными заказами."
                )}
              </span>
            </div>
          </div>
          <div className="row info-row mb-4">
            <div className="col-md-4 col-12">
              <div className="d-flex h-100 flex-column justify-content-between">
                <h4 className="size-4 text-bold natural-3 m-0">
                  {t("Упаковочные материалы")}
                </h4>
                <h6 className="size-6 text-bold lh-32 primary-4 m-0">
                  {t("$3.5 — $10")}
                </h6>
              </div>
            </div>
            <div className="col-md-8 col-12 pr-0">
              <p className="size-7 lh-24 text-bold ls-0 ws-pw mb-3">
                {t(
                  "Мы всегда на связи; Готовы выполнить любой ваш запрос или найдем достойную альтернативу Вы всегда можете рассчитывать на нашу помощь в любом вопросе"
                )}
              </p>
              <Banner src="/images/boxes.png" />
            </div>
          </div>
          <div className="row info-row">
            <div className="col-md-4 col-12">
              <div className="d-flex h-100 flex-column justify-content-between">
                <h4 className="size-4 text-bold natural-3 m-0">
                  {t("Хранение посылок")}
                </h4>
                <h6 className="size-6 text-bold lh-32 primary-4 m-0">
                  {t("Входящих —до 45 дней бесплатно")}
                </h6>
              </div>
            </div>
            <div className="col-md-8 col-12 pr-0">
              <span className="size-7 lh-24 text-bold ls-0 ws-pw">
                {t(
                  "Хранить входящие посылки на складе Бандерольки можно бесплатно в течение 45 дней для складов США и Великобритании и 15 дней для складов в Германии и Турции, затем стоимость хранения составит $0.5 в день за посылку с первого дня платного хранения. Максимальный срок хранения входящей посылки — 100 дней. Если посылка не была востребована, по истечении 100 дней Бандеролька имеет право утилизировать её содержимое или распорядиться товарами по своему усмотрению.\n\nБесплатное хранение исходящих (сформированных) отправок на складе Бандерольки (с момента выставления счета на услуги) составляет семь дней, затем стоимость составит $2 в день. Общий срок хранения готовой к отправке посылки не должен превышать двух месяцев. Если такая посылка не была востребована, по истечении этого времени Бандеролька имеет право утилизировать её содержимое или распорядиться товарами по своему усмотрению."
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="size-9 natural-4 lh-20 ws-pw question">
          {t(
            "Хотите быстро получить ответ на свой вопрос?\nИзучите раздел сайта Вопросы-ответы, в котором мы собрали ответы на самые популярные вопросы.\n\nЕсли ваш вопрос связан с тем, когда внесут входящую посылку в систему, то напоминаем, что на внесение груза нам нужно 1-2 рабочих дня, в редких случаях чуть больше. Советуем писать по этому вопросу только если груз не внесен более 3-х дней."
          )}
        </div>
      </div>
    </div>
  );
}
