import PagePagination from "@/components/page-navigation";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@/components/buttons";
import { accordionContent } from "./AccordionContent";
import FaqAccordion from "./FaqAccordion";

const links = [
  {
    to: "/",
    text: "Главная",
    className: "natural-4 text-bold size-8",
  },
  {
    to: "/help",
    text: "Помощь",
    className: "natural-2 text-bold size-8",
  },
];

const helpRoutes = [
  {
    label: "Регистрация",
    value: 1,
  },
  {
    label: "Доставка",
    value: 2,
  },
  {
    label: "Оплата",
    value: 3,
  },
  {
    label: "Услуги",
    value: 4,
  },
  {
    label: "Получение",
    value: 5,
  },
  {
    label: "Покупки",
    value: 6,
  },
];

export default function Help() {
  const { t } = useTranslation();
  const [active, setActive] = useState<number>(1);

  return (
    <div className="help-page">
      <PagePagination backText={t("Back")} links={links} />
      <div className="container">
        <div className="help-body">
          <h6 className="size-6 text-bold lh-32 primary-1 m-0">{t("Помощь")}</h6>
          <h3 className="size-3 text-bold">
            {t("Часто задаваемые вопросы")}
          </h3>
          <div className="row">
            <div className="col-md-3">
              <div className="help-router box-shadow">
                {helpRoutes.map((route) => (
                  <Button
                    key={route.value}
                    className={`${
                      active === route.value ? "natural-2" : "natural-4"
                    }`}
                    onClick={() => setActive(route.value)}
                  >
                    {t(route.label)}
                  </Button>
                ))}
              </div>
            </div>
            <div className="col-md-9">
              <FaqAccordion questions={accordionContent(active)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
