import { getPickupTimes } from "@/api/order";
import { months } from "@/components/constants";
import Input from "@/components/form-components";
import Checkbox from "@/components/form-components/Checkbox";
import Select from "@/components/form-components/Select";
import {
  Context,
  currentDay,
  currentMonth,
  formatDateByDateInfo,
  getMonthDays,
  pickupTimeOptions,
} from "@/components/utils";
import { SET_NEW_ORDER } from "@/controllers/actions";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  currentStep: number;
  countryId: number;
}

export default function PickupTime({ currentStep, countryId }: Props) {
  const { t } = useTranslation();
  const [times, setTimes] = useState<PickupTimeType[]>([]);
  const {
    state: { newOrder },
    dispatch,
  } = useContext(Context);
  const {
    watch,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      pickup: newOrder.order.pickup ?? {
        day: currentDay,
        month: currentMonth,
        year: new Date().getFullYear(),
      },
    },
  });

  const getPickupAviebleTime = async () => {
    const { data } = await getPickupTimes(
      formatDateByDateInfo(watch("pickup")),
      countryId
    );
    setTimes(data);
    try {
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async (values: object) => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        ...newOrder,
        prevStep: newOrder.currentStep,
        currentStep: currentStep,
        order: { ...newOrder.order, ...values },
      },
    });
  };

  useEffect(() => {
    getPickupAviebleTime();
  }, [watch("pickup.day"), watch("pickup.month")]);

  return (
    <form>
      <div className="d-flex-center gap-20 mb-32 mt-4">
        <Select
          register={register}
          options={getMonthDays(watch("pickup.month"))}
          name="pickup.day"
          label={t("День *")}
          parentClass="w-100"
          labelClass="size-7 natural-5 text-medium m-0"
          inputClass="natural-6-border text-bold pointer"
        />
        <Select
          register={register}
          options={months}
          parentClass="w-100"
          name="pickup.month"
          label={t("Месяц *")}
          labelClass="size-7 natural-5 text-medium m-0"
          inputClass="natural-6-border text-bold pointer"
        />
        <Input
          parentClass="w-100"
          register={register}
          inputClass="natural-6-border text-bold"
          name="pickup.year"
          readonly
          errors={errors}
          label={t("Год *")}
          labelClass="size-7 natural-5 text-medium m-0"
        />
      </div>
      <Select
          key={times.length}
          register={register}
          options={pickupTimeOptions(times)}
          parentClass="w-100"
          validations={{ required: t("This is required.") }}
          name="pickup.time"
          label={t("Выберите время доставки *")}
          labelClass="size-7 natural-5 text-medium m-0"
          inputClass="natural-6-border text-bold pointer mb-32"
        />
      <Input
        parentClass="w-100"
        register={register}
        inputClass="natural-6-border text-bold"
        name="pickup.comment"
        errors={errors}
        label={t("Оставить комментарий")}
        placeholder="..."
        labelClass="size-7 natural-5 text-medium m-0"
      />
      <button
        id="pickup-submit"
        style={{ position: "absolute", opacity: 0, zIndex: -1 }}
        onClick={handleSubmit(submit)}
      />
    </form>
  );
}
