import React, { useEffect, useState } from "react";
import CustomModal from "..";
import { useTranslation } from "react-i18next";
import Button from "../../buttons";
import { useFieldArray, useForm } from "react-hook-form";
import SearchProducts from "../../form-components/SearchProducts";
import Input from "../../form-components";
import { deleteTrackingOrderById, updateTrackingOrder } from "@/api/order";
import { useNavigate } from "react-router-dom";
import LoaderLayout from "@/components/LoaderLayout";
import { toast } from "react-toastify";
import Services from "../Services";
interface Props {
  order: any;
  modal: any;
  setModal: (data: any) => void;
  setOrder: (data: object) => void;
}

export default function UpdateTrackingOrder({
  order,
  setOrder,
  modal,
  setModal,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [locales, setLocales] = useState({
    loading: false,
    content: order,
    service: false,
  });
  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: order });
  const { fields, remove } = useFieldArray({
    control,
    name: "records",
    keyName: "_id",
  });

  const closeModal = () => {
    setModal({ edit: false });
  };

  const submit = async (values: any) => {
    setLocales({ ...locales, loading: true });

    if (fields.length < 1) {
      try {
        await deleteTrackingOrderById(order.id);
        toast.success("Successfully deleted");
        return navigate(-1);
      } catch (error) {
        console.log(error);
      }
      setLocales({ ...locales, loading: false });
      return;
    }

    const products = values.records.map((product: ProductType) => ({
      ...product,
      product_id: product.product_id ?? product.product.id,
    }));

    const orderObject = {
      ...values,
      records: products,
      recipient_address_id: values.recipient_address.id,
    };

    try {
      const { data } = await updateTrackingOrder(values.id, orderObject);
      setOrder(data);
      setModal({ edit: false });
      toast.success("Successfully updated");
    } catch (error) {
      console.log(error);
      setLocales({ ...locales, loading: false });
    }
  };

  useEffect(() => setOrder(locales.content), [locales.service]);

  return (
    <CustomModal
      className="order-update-modal"
      isOpen={modal.edit}
      closeModal={closeModal}
    >
      <div className="react-modal-header">
        <div className="">
          <h5 className="size-6 natural-2 mb-1 lh-32 text-bold">
            {t("Обновить заказ")}
          </h5>
        </div>
        <Button
          className="modal-close-btn natural-6-border"
          onClick={closeModal}
        >
          <img src="/icons/close.svg" width={24} alt="close" />
        </Button>
      </div>
      <hr className="my-4" />
      <div className="order-info tracking">
        <div className="info-body">
          <Input
            parentClass="d-flex flex-column truck-number mb-0"
            register={register}
            inputClass="natural-6-border text-bold"
            name="tracking"
            errors={errors}
            validations={{ required: t("This is required.") }}
            label={t("Трек-номер")}
            placeholder={t("Трек-номер заказа")}
            labelClass="size-7 natural-5 text-medium m-0"
          />
          <Button
            className={`services-btn size-8 ${
              order.additional_services?.length > 0 ? "active" : ""
            }`}
            onClick={() =>
              setLocales({
                ...locales,
                service: true,
              })
            }
          >
            <img
              width={16}
              src={`/icons/primary${
                order.additional_services.length > 0 ? "-4" : ""
              }-add.svg`}
              alt="add"
            />
          </Button>
        </div>
      </div>
      {fields.length > 0 ? (
        fields.map((field, i) => {
          return (
            <div className="order-info d-table w-100" key={field._id}>
              <div className="info-body w-100 table-cell">
                <Button className="delete-btn" onClick={() => remove(i)}>
                  <img src="/icons/delete.svg" alt="delete" />
                </Button>
                <SearchProducts
                  parentClass="d-flex flex-column"
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  name={`records[${i}]`}
                  labelClass="size-7 natural-5 text-medium m-0"
                  validations={{ required: t("This is required.") }}
                  label={t("Название товара *")}
                  errors={errors}
                  inputClass="natural-6-border text-bold"
                  placeholder={t("Пример: Игровая консоль")}
                />
                <div className="d-flex-center gap-20">
                  <Input
                    parentClass="d-flex flex-column flex-grow"
                    register={register}
                    name={`records[${i}].quantity`}
                    type="number"
                    labelClass="size-7 natural-5 text-medium m-0"
                    validations={{ required: t("This is required.") }}
                    label={t("Количество *")}
                    errors={errors}
                    inputClass="natural-6-border text-bold"
                    placeholder={t("Кол-во")}
                  />
                  <Input
                    parentClass="d-flex flex-column flex-grow"
                    register={register}
                    name={`records[${i}].price`}
                    type="number"
                    labelClass="size-7 natural-5 text-medium m-0"
                    validations={{ required: t("This is required.") }}
                    label={t("Стоимость *")}
                    errors={errors}
                    inputClass="natural-6-border text-bold"
                    placeholder="$"
                  />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <img
          width={100}
          className="d-block mx-auto mb-4"
          src="/images/empty.png"
          alt="empty"
        />
      )}
      <hr className="mt-1 mb-4" />
      <Button
        className="add-btn w-100 primary-1-bg natural-8"
        onClick={handleSubmit(submit)}
      >
        <LoaderLayout
          loading={locales.loading}
          style={{ width: "16px" }}
          children={t("Сохранять")}
        />
      </Button>
      {locales.service && <Services setModal={setLocales} content={order} />}
    </CustomModal>
  );
}
