import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../../components/buttons";
import FaqReadMore from "../../components/modals/FaqReadMore";
import { Autoplay } from "swiper/modules";
import { faqBreakpoints } from "@/components/constants";

const faqs = [
  {
    id: 1,
    icon: "/images/earth.png",
    title: "Таможенный лимит Узбекистан",
    subTitle: "$1000 / 20 кг",
    description:
      "Максимальная сумма стоимости вложений необлагаемая налогом — 1000$.(В в течение одного календарного квартала для товаров поступающих в адрес физического лица в международных курьерских отправлениях) беспошлинно.",
    subDescription: "Максимальный вес - 30 кг.",
  },
  {
    id: 2,
    icon: "/images/faq-airplane.png",
    title: "Таможенный лимит Узбекистан",
    subTitle: "$1000 / 20 кг",
    description:
      "Максимальная сумма стоимости вложений необлагаемая налогом — 1000$.(В в течение одного календарного квартала для товаров поступающих в адрес физического лица в международных курьерских отправлениях) беспошлинно.",
    subDescription: "Максимальный вес - 30 кг.",
  },
  {
    id: 3,
    icon: "/images/lego.png",
    title: "Таможенный лимит Узбекистан",
    subTitle: "$1000 / 20 кг",
    description:
      "Максимальная сумма стоимости вложений необлагаемая налогом — 1000$.(В в течение одного календарного квартала для товаров поступающих в адрес физического лица в международных курьерских отправлениях) беспошлинно.",
    subDescription: "Максимальный вес - 30 кг.",
  },
  {
    id: 4,
    icon: "/images/box.png",
    title: "Таможенный лимит Узбекистан",
    subTitle: "$1000 / 20 кг",
    description:
      "Максимальная сумма стоимости вложений необлагаемая налогом — 1000$.(В в течение одного календарного квартала для товаров поступающих в адрес физического лица в международных курьерских отправлениях) беспошлинно.",
    subDescription: "Максимальный вес - 30 кг.",
  },
  {
    id: 5,
    icon: "/images/box.png",
    title: "Таможенный лимит Узбекистан",
    subTitle: "$1000 / 20 кг",
    description:
      "Максимальная сумма стоимости вложений необлагаемая налогом — 1000$.(В в течение одного календарного квартала для товаров поступающих в адрес физического лица в международных курьерских отправлениях) беспошлинно.",
    subDescription: "Максимальный вес - 30 кг.",
  },
];

export default function FAQ() {
  const { t } = useTranslation();
  const [modalContent, setModalContent] = useState<object>();

  return (
    <div className="faq-content">
      <h5 className="size-6 m-0 text-bold natural-2">
        {t("Часто задаваемые вопросы")}
      </h5>
      <Swiper
        autoplay
        spaceBetween={8}
        slidesPerView={3.5}
        modules={[Autoplay]}
        loop={true}
        breakpoints={faqBreakpoints}
      >
        {faqs.map((faq, i) => (
          <SwiperSlide key={i}>
            <div className="faq-box natural-6-gradient-bg">
              <div className="faq-box-image primary-2-gradient-bg">
                <img src={faq.icon} alt="icon" />
              </div>
              <h6 className="text-bold text-center size-8 natural-2">
                {faq.title}
              </h6>
              <h5 className="text-bold text-center size-7 primary-1">
                {faq.subTitle}
              </h5>
              <Button
                onClick={() => setModalContent(faq)}
                className="simple-btn m-auto natural-6-border size-8 natural-2"
              >
                {t("Подробнее")}
              </Button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="warning d-flex">
        <h5 className="size-7 natural-2 w-50 text-bold m-0">
          {t("Внимание!")}
        </h5>
        <h6 className="size-9 natural-4 m-0">
          {t(
            "Просим обязательно заполнить поле Address Line 2 во время оформления заказа в магазине. Поскольку оно является Вашим идентификационным кодом. Если не найдете соответсвующее поле, заполните рядом с Вашим именем."
          )}
        </h6>
      </div>
      {modalContent && (
        <FaqReadMore content={modalContent} setModal={setModalContent} />
      )}
    </div>
  );
}
