import React, { useContext, useState } from "react";
import { changePassword } from "@/api/profile";
import LoaderLayout from "@/components/LoaderLayout";
import Button from "@/components/buttons";
import Input from "@/components/form-components";
import Checkbox from "@/components/form-components/Checkbox";
import { Context } from "@/components/utils";
import { SET_AUTH_STEP } from "@/controllers/actions";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import PasswordInput from "@/components/form-components/PasswordInput";

export default function ChangePassword() {
  const { t } = useTranslation();
  const {
    state: { user },
    dispatch,
  } = useContext(Context);
  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const submit = async (values: object) => {
    setLoading(true);
    try {
      await changePassword({
        ...values,
        code: user.code,
      });
      toast.success("Password changed");
      dispatch({
        type: SET_AUTH_STEP,
        payload: { prevStep: 0, currentStep: 1 },
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(true);
  };

  return (
    <div className="change-pass">
      <h1 className="natural-2 text-semi-bold my-3 size-3">
        {t("Восстановить пароль")}
      </h1>
      <form className="form">
        <div className="d-flex-center justify-content-between gap-20">
          <PasswordInput
            parentClass="d-flex flex-column flex-grow"
            register={register}
            name="old-password"
            errors={errors}
            validations={{ required: t("This is required.") }}
            label={t("Пароль *")}
            inputClass="natural-6-border text-bold"
            labelClass="size-7 natural-5 text-medium m-0"
          />
          <PasswordInput
            parentClass="d-flex flex-column flex-grow"
            register={register}
            name="password"
            errors={errors}
            label={t("Повторите пароль *")}
            inputClass="natural-6-border text-bold"
            labelClass="size-7 natural-5 text-medium m-0"
            validations={{
              required: t("This is required."),
              validate: {
                matchesPreviousPassword: (value: string) =>
                  watch("old-password") === value || t("Passwords must match."),
              },
            }}
          />
        </div>
        <div className="warning d-flex-center">
          <img src="/icons/warning.svg" alt="warning" />
          <div className="description ml-2">
            <h5 className="size-7 natural-3 text-bold m-0">
              {t(
                "Введите свой пароль, и повторите ввод пароля, чтобы исключить возможность опечатки."
              )}
            </h5>
            <span className="size-9 natural-4">
              {t("Все поля обязательны для заполнения")}
            </span>
          </div>
        </div>
        <Checkbox
          name="isAgree"
          register={register}
          errors={errors}
          inputClass="w-auto"
          labelClass="m-0 natural-1 size-8 text-semi-bold pointer"
          label={t("Я прочитал и согласен с Условиями использования")}
          parentClass="d-flex-center flex-row-reverse justify-content-end"
          validations={{ required: t("This is required.") }}
        />
      </form>
      <div className="bottom-side d-flex-center justify-content-end">
        <Button
          className="submit-btn primary-1-bg natural-8 size-7"
          onClick={handleSubmit(submit)}
        >
          {t("Войти в систему")}
          <LoaderLayout
            loading={loading}
            style={{ width: "16px", marginRight: "12px" }}
            children={
              <img src="/icons/arrow-left.svg" width={16} alt="arrow" />
            }
          />
        </Button>
      </div>
    </div>
  );
}
