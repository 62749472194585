import { mainCaller } from "./mainCaller";

export const getRegionsByCountryId = (id: number) => {
  return mainCaller(`/regions?size=100&country=${id}`, "GET");
};
export const getDistrictsByRegionId = (id: number) => {
  return mainCaller(`/districts?region=${id}`, "GET");
};
export const getAddressesLimits = (ids: string) => {
  return mainCaller(`/addresses/period/limit?${ids}`, "GET");
};

export const createAddress = (data: object) => {
  return mainCaller("/addresses", "POST", data);
};

export const updateAddress = (data: OptionType) => {
  return mainCaller(`/addresses/${data.id}`, "PUT", data);
};
