import Button from "@/components/buttons";
import Input from "@/components/form-components";
import SearchProducts from "@/components/form-components/SearchProducts";
import { Context } from "@/components/utils";
import { SET_NEW_ORDER } from "@/controllers/actions";
import React, { useContext, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function TrackingOrder() {
  const { t } = useTranslation();
  const {
    state: { newOrder },
    dispatch,
  } = useContext(Context);
  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: newOrder.order.records ? newOrder.order : { records: [{}] },
  });
  const { fields, remove, append } = useFieldArray({
    control,
    name: "records",
    keyName: "_id",
  });

  const handleBack = () => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        prevStep: 4,
        currentStep: 3,
        order: { ...newOrder.order, records: null, tracking: null },
      },
    });
  };

  const submit = async (values: object) => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        prevStep: 4,
        currentStep: 8,
        order: { ...newOrder.order, ...values },
      },
    });
  };

  return (
    <>
      <Button className="size-3 back-btn" onClick={handleBack}>
        <img
          src="/icons/arrow-left.svg"
          className="mr-3"
          width={40}
          alt="back"
        />
        {t("Добавить ожидаемый заказ")}
      </Button>
      <div className="content-body mx-auto">
        <div className="content">
          <h6 className="text-bold size-6 lh-32">
            <span className="primary-1 text-bold">4. </span>
            {t("Заполните декоарацию")}
          </h6>
          <h6 className="size-8 natural-4 lh-20 m-0">
            {t(
              "Пожалуйста, внимательно опишите каждый товар в заказе. Эти данные будут использоваться для оформления таможенной декларации."
            )}
          </h6>
          <form className="online-order-form">
            <Input
              parentClass="d-flex flex-column truck-number"
              register={register}
              inputClass="natural-6-border text-bold"
              name="tracking"
              errors={errors}
              validations={{ required: t("This is required.") }}
              label={t("Трек-номер")}
              placeholder={t("Трек-номер заказа")}
              labelClass="size-7 natural-5 text-medium m-0"
            />
            <hr className="my-4" />
            <div className="box-shadow">
              {fields.map((_, i) => {
                return (
                  <div className="order-info d-table w-100" key={i}>
                    {fields.length > 1 && (
                      <Button className="delete-btn" onClick={() => remove(i)}>
                        <img src="/icons/delete.svg" alt="delete" />
                      </Button>
                    )}
                    <div className="number size-8 text-bold natural-4 d-table-cell align-middle">
                      №&nbsp;{i + 1}
                    </div>
                    <div className="info-body w-100 table-cell">
                      <SearchProducts
                        parentClass="d-flex flex-column"
                        register={register}
                        watch={watch}
                        setValue={setValue}
                        name={`records[${i}]`}
                        labelClass="size-7 natural-5 text-medium m-0"
                        validations={{ required: t("This is required.") }}
                        label={t("Название товара *")}
                        errors={errors}
                        inputClass="natural-6-border text-bold"
                        placeholder={t("Пример: Игровая консоль")}
                      />
                      <div className="d-flex-center gap-20">
                        <Input
                          parentClass="d-flex flex-column flex-grow"
                          register={register}
                          name={`records[${i}].quantity`}
                          type="number"
                          labelClass="size-7 natural-5 text-medium m-0"
                          validations={{
                            required: t("This is required."),
                            min: { value: 1, message: "Minimum is 1" },
                          }}
                          label={t("Количество *")}
                          errors={errors}
                          inputClass="natural-6-border text-bold"
                          placeholder={t("Кол-во")}
                        />
                        <Input
                          parentClass="d-flex flex-column flex-grow"
                          register={register}
                          name={`records[${i}].price`}
                          type="number"
                          labelClass="size-7 natural-5 text-medium m-0"
                          validations={{
                            required: t("This is required."),
                            min: { value: 1, message: "Minimum is 1" },
                          }}
                          label={t("Стоимость *")}
                          errors={errors}
                          inputClass="natural-6-border text-bold"
                          placeholder="$"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              {fields.length < 8 && (
                <Button
                  className="add-button size-8 w-100 primary-1 primary-1-border"
                  onClick={() => append({})}
                >
                  {t("Добавить ещё")}
                </Button>
              )}
              <hr className="my-4" />
              <Button
                className="submit-btn size-7 w-100 natural-8 primary-1-bg"
                onClick={handleSubmit(submit)}
              >
                {t("Продолжить")}{" "}
                <img src="/icons/star.svg" width={16} alt="star" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
