import React from "react";
import ContentHeader from "./ContentHeader";
import { useTranslation } from "react-i18next";
import FAQ from "./FAQ";
import { Link, Outlet, useLocation } from "react-router-dom";

export default function UserParcels() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div className="parcels-content">
      <ContentHeader title="Мои посылки" />
      <div className="d-flex-center parcel-nav mb-4">
        <Link
          className={`nav-link text-bold p-0 ${
            pathname === "/parcels/orders" ? "natural-8" : "natural-4"
          }`}
          to="/parcels/orders"
        >
          {t("Посылки")}
        </Link>
        <Link
          className={`nav-link text-bold p-0 ${
            pathname === "/parcels/shop-orders" ? "natural-8" : "natural-4"
          }`}
          to="/parcels/shop-orders"
        >
          {t("Помощь в покупке")}
        </Link>
        <Link
          className={`nav-link text-bold p-0 ${
            pathname === "/parcels/trackings" ? "natural-8" : "natural-4"
          }`}
          to="/parcels/trackings"
        >
          {t("Ваши покупки")}
        </Link>
      </div>
      <div className="tab-content tab-inner">
        <div className="tab-pane fade show active">
          <Outlet />
        </div>
      </div>
      <hr className="styled-hr" />
      <FAQ />
    </div>
  );
}
