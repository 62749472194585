import { getPassVerifCode, getVerifyCode, signUp } from "@/api/auth";
import LoaderLayout from "@/components/LoaderLayout";
import Button from "@/components/buttons";
import { Context, maskedNumber } from "@/components/utils";
import { SET_AUTH_STEP, SET_USER } from "@/controllers/actions";
import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function CheckCode() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { user, authStep },
    dispatch,
  } = useContext(Context);
  const [loader, setLoader] = useState(false);
  const [timer, setTimer] = useState(60);
  const [reset, setReset] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const submit = async (values: object) => {
    if (authStep.currentStep === 5) {
      try {
        const { data } = await signUp({ ...user, ...values });
        dispatch({ type: SET_AUTH_STEP, payload: { currentStep: 1 } });
        localStorage.setItem("token", data.access_token);
        toast.success("Successfully signed up");
        navigate("/profile");
      } catch (error) {
        console.log(error);
      }
    } else {
      dispatch({ type: SET_USER, payload: { ...user, ...values } });
      dispatch({
        type: SET_AUTH_STEP,
        payload: { prevStep: 1, currentStep: 2 },
      });
    }
  };

  const sendCode = async () => {
    try {
      if (authStep.currentStep === 5) {
        await getVerifyCode(user);
      } else await getPassVerifCode(user);
      setTimer(60);
      setReset(!reset);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [reset]);

  return (
    <div className="check-pass">
      <h1 className="natural-2 text-semi-bold my-3 size-3">
        {t("Мы отправили код на Ваш номер")}
      </h1>
      <h2 className="m-0 primary-1 text-semi-bold">
        {maskedNumber(user.phone)}
      </h2>
      <div className="form position-relative">
        <Controller
          name="code"
          control={control}
          rules={{
            required: "Code is required",
            minLength: {
              value: 5,
              message: "Code must be 5 characters",
            },
          }}
          render={({ field }) => (
            <ReactCodeInput
              className="w-100 custom-input"
              {...field}
              inputStyle={{
                width: "100%",
                border: "2px solid #E6E8EC",
                fontSize: "24px",
                fontFamily: "Gilroy-SemiBold",
              }}
              type="number"
              fields={5}
              inputMode={"tel"}
              onChange={(value) => field.onChange(value)}
              value={field.value}
            />
          )}
        />
        {errors && (
          <span className="primary-3 d-block size-8 error-message mt-1 position-absolute">
            <ErrorMessage errors={errors} name="code" />
          </span>
        )}
      </div>
      <div className="bottom-side d-flex-center justify-content-between">
        <button
          disabled={timer > 0}
          className="btn p-0 primary-1 text-bold bg-transparent"
          onClick={sendCode}
        >
          {t("Отправить ещё раз")}:{" "}
          {timer > 0 ? (
            timer
          ) : (
            <img width={20} src="/icons/refresh.svg" alt="refresh" />
          )}
        </button>
        <Button
          className="submit-btn primary-1-bg natural-8 size-7"
          onClick={handleSubmit(submit)}
        >
          {t("Подтвердите номер")}
          <LoaderLayout
            loading={loader}
            style={{ width: "16px", marginRight: "12px" }}
            children={
              <img src="/icons/arrow-left.svg" width={16} alt="arrow" />
            }
          />
        </Button>
      </div>
    </div>
  );
}
