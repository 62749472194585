import {
  SET_AUTH_STEP,
  SET_NEW_ORDER,
  SET_SETTINGS,
  SET_USER,
  SET_CONFIRM,
  SET_PAYMENT,
} from "./actions";

export const initialState: StateType = {
  user: {},
  settings: {},
  authStep: {
    prevStep: undefined,
    currentStep: 1,
  },
  newOrder: {
    prevStep: 0,
    currentStep: 1,
    order: {},
  },
  confirm: false,
  payment: {
    step: 1,
    amount: 0,
    isOpen: false,
    paymentMethod: "stripe",
  },
};

export default function reducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case SET_AUTH_STEP:
      return {
        ...state,
        authStep: action.payload,
      };
    case SET_NEW_ORDER:
      return {
        ...state,
        newOrder: action.payload,
      };
    case SET_CONFIRM:
      return {
        ...state,
        confirm: action.payload,
      };
    case SET_PAYMENT:
      return {
        ...state,
        payment: action.payload,
      };
    default:
      return state;
  }
}

export function loadState() {
  const serializedState = localStorage.getItem("global-state");
  if (serializedState === null) {
    return initialState;
  }
  return JSON.parse(serializedState);
}

export function saveState(state: object) {
  const serializedState = JSON.stringify(state);
  localStorage.setItem("global-state", serializedState);
}
