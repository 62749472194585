import { Context } from "@/components/utils";
import { SET_PAYMENT } from "@/controllers/actions";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@/components/buttons";
import { cardConfirmCode, paymePay, resendCardCode } from "@/api/profile";
import Input from "@/components/form-components";
import { useForm } from "react-hook-form";
import TimerButton from "@/components/buttons/TimerButton";

export default function Payme() {
  const { t } = useTranslation();
  const {
    state: { payment },
    dispatch,
  } = useContext(Context);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [locales, setLocales] = useState({
    loading: false,
    code: "",
  });

  const closeModal = () => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        step: 1,
        amount: 0,
        isOpen: false,
      },
    });
  };

  const back = () => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        ...payment,
        step: 2,
      },
    });
  };

  const sendCode = async () => {
    try {
      await resendCardCode();
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async (values: any) => {
    setLocales({ ...locales, loading: true });
    try {
      if (values.code && values.code === locales.code) {
        const { data } = await paymePay({ locales, amount: payment.amount });
        console.log(data);
      } else {
        // alert("Test code: 123123")
        // return setLocales({ loading: false, code: "123123" });
        // const { data } = await cardConfirmCode(values);
      }
    } catch (error) {
      setLocales({ ...locales, loading: false });
      console.log(error);
    }
  };

  return (
    <div className="balance-modal stripe">
      <div className="react-modal-header">
        <button
          className="back-btn bg-transparent border-0 p-0 d-flex-center gap-10 size-5 text-bold"
          onClick={back}
        >
          <img src="/icons/arrow-left.svg" width={24} alt="arrow" />
          {t("Назад")}
        </button>
        <Button
          className="modal-close-btn natural-6-border"
          onClick={closeModal}
        >
          <img src="/icons/close.svg" width={24} alt="close" />
        </Button>
      </div>
      <div className="react-modal-body">
        {locales.code ? (
          <div className="d-flex align-items-end justify-content-between my-5">
            <Input
              parentClass="d-flex flex-column w-50"
              register={register}
              name="code"
              errors={errors}
              label={t("Введите код *")}
              placeholder={t("Код *")}
              validations={{ required: t("This is required.") }}
              inputClass="natural-6-border text-bold"
              labelClass="size-7 natural-5 text-medium m-0"
            />
            <TimerButton
              onClick={sendCode}
              className="text-bold primary-1 ml-3"
              text={t("Отправить ещё раз")}
            />
          </div>
        ) : (
          <div className="d-flex-center gap-20 my-5">
            <Input
              parentClass="d-flex flex-column w-75"
              register={register}
              name="card_number"
              errors={errors}
              label={t("Номер карты *")}
              placeholder="---- ---- ---- ----"
              validations={{ required: t("This is required.") }}
              inputClass="natural-6-border text-bold"
              labelClass="size-7 natural-5 text-medium m-0"
            />
            <Input
              parentClass="d-flex flex-column w-25"
              register={register}
              name="expire"
              errors={errors}
              label={t("Дата окончания *")}
              validations={{ required: t("This is required.") }}
              placeholder={t("ММ/ГГ")}
              inputClass="natural-6-border text-bold"
              labelClass="size-7 natural-5 text-medium m-0"
            />
          </div>
        )}
        <div className="d-flex-center justify-content-between mb-5 px-1">
          <h5 className="m-0 text-medium">{t("Сумма оплаты")} :</h5>
          <h4 className="text-bold m-0">$ {payment.amount}</h4>
        </div>
        <Button
          className="submit-btn primary-1-bg w-100 natural-8 size-7"
          onClick={handleSubmit(submit)}
        >
          {t("Отправить")}
        </Button>
      </div>
    </div>
  );
}
