import React from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import FAQ from "./FAQ";
import UpdatePass from "./UpdatePass";
import UpdateUser from "./UpdateUser";

export default function UserSettings() {
  const { t } = useTranslation();

  return (
    <div className="settings-content">
      <h1 className="natural-2 text-semi-bold m-0 size-4">
        {t("Мои настройки")}
      </h1>
      <Tabs>
        <TabList>
          <Tab className="simple-btn size-8">{t("Личные данные")}</Tab>
          <Tab className="simple-btn size-8">{t("Сменить пароль")}</Tab>
        </TabList>
        <TabPanel>
          <UpdateUser />
        </TabPanel>
        <TabPanel>
          <UpdatePass />
        </TabPanel>
      </Tabs>
      <FAQ />
    </div>
  );
}
