import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { emailValidation } from "@/components/constants";
import Input from "../../components/form-components";
import Button from "../../components/buttons";
import Select from "../../components/form-components/Select";
import { doc_types } from "../../components/constants";
import FileUpload from "../../components/form-components/FileUpload";
import { updateUser } from "@/api/profile";
import { Context, filterDocuments } from "../../components/utils";
import LoaderLayout from "@/components/LoaderLayout";
import { toast } from "react-toastify";
import { SET_USER } from "@/controllers/actions";

export default function UpdateUser() {
  const { t } = useTranslation();
  const {
    state: { user },
    dispatch,
  } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: user.customer });
  const { fields, remove, append } = useFieldArray({
    control,
    name: "documents",
    keyName: "_id",
  });

  const submit = async (values: any) => {
    console.log(values);

    setLoading(true);
    try {
      values.phones[0] = values.phones[0].replace(/\D/g, "");
      values.documents = filterDocuments(values.documents);
      const { data } = await updateUser(values);
      dispatch({ type: SET_USER, payload: { ...user, customer: data } });
      toast.success("Successfully updated");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className="update-user-content">
      <form className="form">
        <div className="d-flex-center justify-content-between flex-wrap">
          <Input
            parentClass="d-flex flex-column flex-grow"
            register={register}
            name="first_name"
            errors={errors}
            label={t("Имя *")}
            validations={{ required: t("This is required.") }}
            placeholder={t("Введите свое имя")}
            inputClass="natural-6-border text-bold"
            labelClass="size-7 natural-5 text-medium m-0"
          />
          <Input
            parentClass="d-flex flex-column flex-grow"
            register={register}
            name="last_name"
            errors={errors}
            validations={{ required: t("This is required.") }}
            label={t("Фамилия *")}
            inputClass="natural-6-border text-bold"
            placeholder={t("Введите свое фамилия")}
            labelClass="size-7 natural-5 text-medium m-0"
          />
        </div>
        <Input
          parentClass="d-flex flex-column"
          register={register}
          name="birth_date"
          type="date"
          errors={errors}
          label={t("Дата рождения")}
          inputClass="natural-6-border text-bold"
          placeholder={t("день.месяц.год")}
          labelClass="size-7 natural-5 text-medium m-0"
        />
        <div className="d-flex-center justify-content-between flex-wrap">
          <Input
            parentClass="d-flex flex-column w-50"
            register={register}
            name="phones[0]"
            errors={errors}
            label={t("Номер телефона *")}
            validations={{
              required: t("This is required."),
            }}
            placeholder="+998"
            inputClass="natural-6-border text-bold"
            labelClass="size-7 natural-5 text-medium m-0"
          />
          <Input
            parentClass="d-flex flex-column flex-grow"
            register={register}
            name="email"
            errors={errors}
            validations={{
              required: t("This is required."),
              ...emailValidation,
            }}
            label={t("Электронный адрес *")}
            inputClass="natural-6-border text-bold"
            placeholder={t("Введите электронный адрес")}
            labelClass="size-7 natural-5 text-medium m-0"
          />
        </div>
        <div className="form-documents box-shadow">
          {fields.map((_, i) => {
            return (
              <div className="doc-info" key={i}>
                <Button className="delete-btn" onClick={() => remove(i)}>
                  <img src="/icons/delete.svg" alt="delete" />
                </Button>
                <div className="row align-items-end">
                  <div className="col-md-4">
                    <Select
                      register={register}
                      options={doc_types}
                      name={`documents[${i}].type`}
                      label="Тип документа будет *"
                      labelClass="size-7 natural-5 text-medium m-0"
                      inputClass="natural-6-border text-bold pointer"
                    />
                  </div>
                  <div className="col-md-8 pl-md-0">
                    <Input
                      parentClass="d-flex flex-column"
                      register={register}
                      name={`documents[${i}].number`}
                      type="text"
                      validations={{ required: t("This is required.") }}
                      errors={errors}
                      inputClass="natural-6-border text-bold"
                      placeholder={t("Серия и номер “Грин-карта”")}
                    />
                  </div>
                </div>
                <div className="d-flex-center upload-area">
                  <FileUpload
                    register={register}
                    validations={{ required: true, maxLength: 2 }}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                    parentClass="h-100 flex-1"
                    label="JPG, PNG"
                    name={`documents[${i}].file`}
                    labelClass="m-0 size-8 natural-4 text-bold"
                    inputClass="natural-6-border text-bold"
                  />
                  <FileUpload
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    parentClass="h-100 flex-1"
                    label="JPG, PNG"
                    name={`documents[${i}].backFile`}
                    labelClass="m-0 size-8 natural-4 text-bold"
                    inputClass="natural-6-border text-bold"
                  />
                </div>
                <h6 className="size-8 natural-5 text-medium mt-1">
                  {t("Прикрепите фотографию с 2 сторон документа")}
                </h6>
                <hr className="mb-4" />
              </div>
            );
          })}
          {fields.length < 8 && (
            <Button
              className="add-button size-8 primary-1 primary-1-border"
              onClick={() => append({})}
            >
              {t("Добавить документ")}
            </Button>
          )}
        </div>
      </form>
      <Button
        className="submit-btn primary-1-bg natural-8 size-7"
        onClick={handleSubmit(submit)}
      >
        {t("Сохранить изменения")}
        <LoaderLayout
          loading={loading}
          style={{ width: "16px", marginRight: "12px" }}
          children={<img src="/icons/arrow-left.svg" width={16} alt="arrow" />}
        />
      </Button>
    </div>
  );
}
