import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../buttons";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Context } from "@/components/utils";
import { SET_PAYMENT } from "@/controllers/actions";

export default function Balance() {
  const { t } = useTranslation();
  const {
    state: { payment, user },
    dispatch,
  } = useContext(Context);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const closeModal = () => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        step: 1,
        amount: 0,
        isOpen: false,
      },
    });
  };

  const submit = async (values: object) => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        ...payment,
        ...values,
        step: 2,
      },
    });
  };

  useEffect(() => {
    return () => closeModal();
  }, []);

  return (
    <div className="balance-modal">
      <div className="react-modal-header">
        <h5 className="size-5 natural-2 mb-0 text-bold">
          {t("Пополнение баланса")}
        </h5>
        <Button
          className="modal-close-btn natural-6-border"
          onClick={closeModal}
        >
          <img src="/icons/close.svg" width={24} alt="close" />
        </Button>
      </div>
      <div className="react-modal-body position-relative">
        <div className="balance-content-body d-flex-center justify-content-between">
          <div className="balance-info">
            <h6 className="size-8 natural-8 text-medium mb-1">
              {t("Текущий остаток")}
            </h6>
            <h5 className="size-6 natural-8 text-bold m-0">
              $ {user.customer.wallet_amount ?? 0}
            </h5>
          </div>
        </div>
        <div className="custom-input position-relative d-flex flex-column">
          <label htmlFor="amount" className="size-7 natural-5 text-medium m-0">
            {t("Сумма, которую вы хотите пополнить *")}
          </label>
          <input
            type="number"
            {...register("amount", { required: t("This is required.") })}
            className="form-control natural-6-border text-bold"
            placeholder={t("Введите сумму")}
          />
          {errors && (
            <span className="primary-3 size-8 error-message mt-1 position-absolute">
              <ErrorMessage errors={errors} name="amount" />
            </span>
          )}
        </div>
        <Button
          className="submit-btn primary-1-bg w-100 natural-8 size-7 mt-5"
          onClick={handleSubmit(submit)}
        >
          {t("Далее")}
        </Button>
      </div>
    </div>
  );
}

// Пополнение баланса
