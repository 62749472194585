import Button from "@/components/buttons";
import PagePagination from "@/components/page-navigation";
import { Context } from "@/components/utils";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const links = [
  {
    to: "/",
    text: "Главная",
    className: "natural-4 text-bold size-8",
  },
  {
    to: "/rates",
    text: "Тарифы",
    className: "natural-2 text-bold size-8",
  },
];

export default function Rates() {
  const { t } = useTranslation();
  const tariffs = useContext(Context).state.settings.tariffs;

  return (
    <div className="rates-page">
      <PagePagination backText={t("Back")} links={links} />
      <div className="container my-5">
        <h4 className="size-4 text-bold">{t("Тарифы")}</h4>
        <div className="rates mt-4 row">
          {tariffs.map((tariff: TariffType) => (
            <div className="col-md-4 col-12 mb-md-5 mb-3">
              <div className="rate-box w-100" key={tariff.id}>
                <h6 className="size-6 natural-2 text-bold lh-32 m-0">
                  {tariff.name}
                </h6>
                <div className="rate-box-body">
                  <h6 className="size-8 natural-4 text-medium lh-20">
                    {t("Минимум")}:{" "}
                    <b className="natural-2">{tariff.min_price}$</b>
                  </h6>
                  <h6 className="size-7 natural- 2 text-bold lh-20 m-0">
                    {tariff.weight_rate.rate_kg} $ {t("за")} 1 {t("kg/sm")}
                    <br />
                    {tariff.weight_rate.rate} $ {t("за")} 1 {t("lb/inch")}
                  </h6>
                </div>
                <hr className="mb-3" />
                <button
                  className="primary-5 pointer text-bold bg-transparent border-0 d-block mx-auto"
                  onClick={() => alert(tariff.name)}
                >
                  {t("Узнать больше")}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
