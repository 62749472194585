import Button from "@/components/buttons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  questions: QuestionType[];
}

export default function FaqAccordion({ questions }: Props) {
  const { t } = useTranslation();
  const [active, setActive] = useState(0);

  const handleChange = (index: number) => {
    setActive(active === index ? 0 : index);
  };

  return (
    <div className="question-accordion">
      {questions.map((question) => (
        <div
          key={question.id}
          className={`question-accordion-item ${
            active === question.id ? "active" : ""
          }`}
        >
          <Button
            className="w-100 natural-2"
            onClick={() => handleChange(question.id)}
          >
            {question.question}
            <img src="/icons/down-arrow.svg" alt="arrow" />
          </Button>
          <div className="question-accordion-item-info">
            <h6 className="size-7 mt-2 mb-3 natural-4 text-medium lh-24">
              {question.answer}
            </h6>
            <a
              href={question.link}
              target="_blank"
              className="simple-btn size-8 text-bold natural-6-border natural-2"
            >
              {t("Подробнее")}
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
