import React, { useEffect, useState } from "react";
import CustomModal from "..";
import { useTranslation } from "react-i18next";
import Button from "../../buttons";
import { useFieldArray, useForm } from "react-hook-form";
import SearchProducts from "../../form-components/SearchProducts";
import Input from "../../form-components";
import { deleteShopOrderById, updateShopOrder } from "@/api/order";
import LoaderLayout from "@/components/LoaderLayout";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Services from "../Services";

interface Props {
  order: any;
  modal: any;
  setModal: (data: any) => void;
  setOrder: (data: object) => void;
}

export default function UpdateShopOrder({
  order,
  setOrder,
  modal,
  setModal,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [locales, setLocales] = useState({
    loading: false,
    content: { id: null },
    service: false,
  });
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ defaultValues: order });
  const { fields, remove, update } = useFieldArray({
    control,
    name: "records",
    keyName: "_id",
  });

  const closeModal = () => {
    setModal({ edit: false });
  };

  const submit = async (values: any) => {
    setLocales({ ...locales, loading: true });

    if (fields.length < 1) {
      try {
        await deleteShopOrderById(order.id);
        toast.success("Successfully deleted");
        return navigate(-1);
      } catch (error) {
        console.log(error);
      }
      setLocales({ ...locales, loading: false });
      return;
    }

    const orderObject = {
      ...values,
      recipient_address_id: values.recipient_address.id,
    };

    try {
      const { data } = await updateShopOrder(values.id, orderObject);
      setOrder(data);
      setModal({ edit: false });
      toast.success("Successfully updated");
    } catch (error) {
      console.log(error);
    }
    setLocales({ ...locales, loading: false });
  };

  useEffect(() => {
    const index = fields.findIndex(
      (field: any) => field.id === locales.content.id
    );
    update(index, locales.content);
  }, [locales.service]);

  return (
    <CustomModal
      className="order-update-modal"
      isOpen={modal.edit}
      closeModal={closeModal}
    >
      <div className="react-modal-header">
        <h5 className="size-6 natural-2 mb-1 lh-32 text-bold">
          {t("Обновить заказ")}
        </h5>
        <Button
          className="modal-close-btn natural-6-border"
          onClick={closeModal}
        >
          <img src="/icons/close.svg" width={24} alt="close" />
        </Button>
      </div>
      <hr className="my-4" />
      {fields.length > 0 ? (
        fields.map((field: any, i) => {
          return (
            <div className="order-info d-table w-100" key={field._id}>
              <div className="info-body w-100 table-cell">
                <Button
                  className={`services-btn size-8 ${
                    field.additional_services?.length > 0 ? "active" : ""
                  }`}
                  onClick={() =>
                    setLocales({
                      ...locales,
                      content: field,
                      service: true,
                    })
                  }
                >
                  <img
                    width={16}
                    src={`/icons/primary${
                      field.additional_services?.length > 0 ? "-4" : ""
                    }-add.svg`}
                    alt="add"
                  />
                </Button>

                <Button className="delete-btn" onClick={() => remove(i)}>
                  <img src="/icons/delete.svg" alt="delete" />
                </Button>
                <SearchProducts
                  parentClass="d-flex flex-column"
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  name={`records[${i}]`}
                  labelClass="size-7 natural-5 text-medium m-0"
                  validations={{ required: t("This is required.") }}
                  label={t("Название товара *")}
                  errors={errors}
                  inputClass="natural-6-border text-bold"
                  placeholder={t("Пример: Игровая консоль")}
                />
                <Input
                  parentClass="d-flex flex-column flex-grow"
                  register={register}
                  name={`records[${i}].link`}
                  type="text"
                  labelClass="size-7 natural-5 text-medium m-0"
                  validations={{ required: t("This is required.") }}
                  label={t("Ссылка *")}
                  errors={errors}
                  inputClass="natural-6-border text-bold"
                  placeholder={t("Ссылка на продукт")}
                />
                <div className="d-flex-center gap-20">
                  <Input
                    parentClass="d-flex flex-column flex-grow"
                    register={register}
                    name={`records[${i}].quantity`}
                    type="number"
                    labelClass="size-7 natural-5 text-medium m-0"
                    validations={{ required: t("This is required.") }}
                    label={t("Количество *")}
                    errors={errors}
                    inputClass="natural-6-border text-bold"
                    placeholder={t("Кол-во")}
                  />
                  <Input
                    parentClass="d-flex flex-column flex-grow"
                    register={register}
                    name={`records[${i}].price`}
                    type="number"
                    labelClass="size-7 natural-5 text-medium m-0"
                    validations={{ required: t("This is required.") }}
                    label={t("Стоимость *")}
                    errors={errors}
                    inputClass="natural-6-border text-bold"
                    placeholder="$"
                  />
                </div>
                <div className="d-flex-center gap-20 mt-4">
                  <Input
                    parentClass="d-flex flex-column flex-grow"
                    register={register}
                    name={`records[${i}].color`}
                    type="text"
                    labelClass="size-7 natural-5 text-medium m-0"
                    validations={{ required: t("This is required.") }}
                    label={t("Цвет *")}
                    errors={errors}
                    inputClass="natural-6-border text-bold"
                    placeholder={t("Цвет")}
                  />
                  <Input
                    parentClass="d-flex flex-column flex-grow"
                    register={register}
                    name={`records[${i}].size`}
                    type="text"
                    labelClass="size-7 natural-5 text-medium m-0"
                    validations={{ required: t("This is required.") }}
                    label={t("Размер *")}
                    errors={errors}
                    inputClass="natural-6-border text-bold"
                    placeholder="Размер"
                  />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <img
          width={100}
          className="d-block mx-auto mb-4"
          src="/images/empty.png"
          alt="empty"
        />
      )}
      <hr className="mt-1 mb-4" />
      <Button
        className="add-btn w-100 primary-1-bg natural-8"
        onClick={handleSubmit(submit)}
      >
        <LoaderLayout
          loading={locales.loading}
          style={{ width: "16px" }}
          children={t("Сохранять")}
        />
      </Button>
      {locales.service && (
        <Services setModal={setLocales} content={locales.content} />
      )}
    </CustomModal>
  );
}
