import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export type LinkType = {
  text: string;
  to: string;
  className: string;
};

export interface Props {
  links: LinkType[];
}

export default function BreadCrumb({ links }: Props) {
  const { t } = useTranslation();

  return (
    <div className="bread-crumb">
      <div className="d-flex-center">
        {links.map((link, index) => (
          <Link to={link.to} key={index} className={link.className}>
            {t(link.text)}
            <img src="/icons/down-arrow.svg" className="mx-2" alt="arrow" />
          </Link>
        ))}
      </div>
    </div>
  );
}
