import Button from "@/components/buttons";
import Input from "@/components/form-components";
import Checkbox from "@/components/form-components/Checkbox";
import SearchProducts from "@/components/form-components/SearchProducts";
import WeightInput from "@/components/form-components/WeightInput";
import { Context } from "@/components/utils";
import { SET_NEW_ORDER } from "@/controllers/actions";
import React, { useContext } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function SendOrder() {
  const { t } = useTranslation();
  const {
    state: { newOrder },
    dispatch,
  } = useContext(Context);
  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: newOrder.order.boxes
      ? newOrder.order
      : { boxes: [{ products: [{}] }] },
  });
  const { fields, remove, append } = useFieldArray({
    control,
    name: "boxes[0].products",
    keyName: "_id",
  });

  const handleBack = () => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        prevStep: 6,
        currentStep: 3,
        order: { ...newOrder.order, boxes: null },
      },
    });
  };

  const submit = async (values: object) => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        prevStep: 6,
        currentStep: 7,
        order: { ...newOrder.order, ...values },
      },
    });
  };

  return (
    <>
      <Button className="size-3 back-btn" onClick={handleBack}>
        <img
          src="/icons/arrow-left.svg"
          className="mr-3"
          width={40}
          alt="back"
        />
        {t("Добавить ожидаемый заказ")}
      </Button>
      <div className="content-body mx-auto">
        <div className="content">
          <h6 className="text-bold size-6 lh-32">
            <span className="primary-1 text-bold">4. </span>
            {t("Заполните декоарацию")}
          </h6>
          <h6 className="size-8 natural-4 lh-20 m-0">
            {t(
              "Пожалуйста, внимательно опишите каждый товар в заказе. Эти данные будут использоваться для оформления таможенной декларации."
            )}
          </h6>
          <form className="online-order-form">
            <hr className="my-4" />
            <div className="box-shadow">
              {fields.map((_, i) => {
                return (
                  <div className="order-info d-table w-100" key={i}>
                    {fields.length > 1 && (
                      <Button className="delete-btn" onClick={() => remove(i)}>
                        <img src="/icons/delete.svg" alt="delete" />
                      </Button>
                    )}
                    <div className="number size-8 text-bold natural-4 d-table-cell align-middle">
                      №&nbsp;{i + 1}
                    </div>
                    <div className="info-body w-100 table-cell">
                      <SearchProducts
                        parentClass="d-flex flex-column"
                        register={register}
                        setValue={setValue}
                        watch={watch}
                        name={`boxes[0].products[${i}]`}
                        labelClass="size-7 natural-5 text-medium m-0"
                        validations={{ required: t("This is required.") }}
                        label={t("Название товара *")}
                        errors={errors}
                        inputClass="natural-6-border text-bold"
                        placeholder={t("Пример: Игровая консоль")}
                      />
                      <div className="d-flex-center gap-20">
                        <Input
                          parentClass="d-flex flex-column flex-grow"
                          register={register}
                          name={`boxes[0].products[${i}].quantity`}
                          type="number"
                          labelClass="size-7 natural-5 text-medium m-0"
                          validations={{
                            required: t("This is required."),
                            min: { value: 1, message: "Minimum is 1" },
                          }}
                          label={t("Количество *")}
                          errors={errors}
                          inputClass="natural-6-border text-bold"
                          placeholder={t("Кол-во")}
                        />
                        <Input
                          parentClass="d-flex flex-column flex-grow"
                          register={register}
                          name={`boxes[0].products[${i}].weight`}
                          type="number"
                          labelClass="size-7 natural-5 text-medium m-0"
                          label={t("Вес")}
                          errors={errors}
                          inputClass="natural-6-border text-bold"
                          placeholder="Вес"
                        />
                        <Input
                          parentClass="d-flex flex-column flex-grow"
                          register={register}
                          name={`boxes[0].products[${i}].price`}
                          type="number"
                          labelClass="size-7 natural-5 text-medium m-0"
                          validations={{
                            required: t("This is required."),
                            min: { value: 1, message: "Minimum is 1" },
                          }}
                          label={t("Стоимость *")}
                          errors={errors}
                          inputClass="natural-6-border text-bold"
                          placeholder="$"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              {fields.length < 8 && (
                <Button
                  className="add-button size-8 w-100 primary-1 primary-1-border"
                  onClick={() => append({})}
                >
                  {t("Добавить ещё")}
                </Button>
              )}
              <hr className="my-4" />
              <div className="d-flex-center gap-20">
                <div className="warning d-flex-center p-3">
                  <img src="/icons/warning.svg" width={32} alt="warning" />
                  <h5 className="size-8 natural-3 text-bold m-0 lh-24 ls-0 ml-3">
                    {t(
                      "Укажите общий вес посылки (если знаете). Если не знаете, оставьте это поле пустым наш сотрудник взвесит вашу посылку на складе и сам заполнит это поле"
                    )}
                  </h5>
                </div>
                <WeightInput
                  name="boxes[0].weight"
                  selectName="unit"
                  register={register}
                  validations={{
                    required: watch("boxes[0].dimension")
                      ? false
                      : t("This is required."),
                    min: { value: 1, message: "Minimum is 1" },
                  }}
                  errors={errors}
                  labelClass="size-7 natural-5 text-medium m-0"
                  label={t("Вес")}
                />
              </div>
              <Checkbox
                name="boxes[0].dimension"
                register={register}
                errors={errors}
                inputClass="w-auto"
                labelClass="m-0 natural-1 size-6 text-bold pointer"
                label={t("Расчет стоимости доставки крупногабаритных посылок")}
                parentClass="d-flex-center flex-row-reverse justify-content-end my-3"
              />
              {watch("boxes[0].dimension") && (
                <div className="d-flex-center gap-20 mt-4">
                  <Input
                    parentClass="d-flex flex-column flex-grow"
                    register={register}
                    name="boxes[0].width"
                    validations={{
                      required: t("This is required."),
                      min: { value: 1, message: "Minimum is 1" },
                    }}
                    type="number"
                    labelClass="size-7 natural-5 text-medium m-0"
                    label={t("Ширина")}
                    errors={errors}
                    inputClass="natural-6-border text-bold"
                    placeholder={t("Ширина")}
                  />
                  <Input
                    parentClass="d-flex flex-column flex-grow"
                    register={register}
                    name="boxes[0].height"
                    validations={{
                      required: t("This is required."),
                      min: { value: 1, message: "Minimum is 1" },
                    }}
                    type="number"
                    labelClass="size-7 natural-5 text-medium m-0"
                    label={t("Высота")}
                    errors={errors}
                    inputClass="natural-6-border text-bold"
                    placeholder={t("Высота")}
                  />
                  <Input
                    parentClass="d-flex flex-column flex-grow"
                    register={register}
                    validations={{
                      required: t("This is required."),
                      min: { value: 1, message: "Minimum is 1" },
                    }}
                    name="boxes[0].length"
                    type="number"
                    labelClass="size-7 natural-5 text-medium m-0"
                    label={t("Длина")}
                    errors={errors}
                    inputClass="natural-6-border text-bold"
                    placeholder={t("Длина")}
                  />
                </div>
              )}
              <hr className="my-4" />
              <Button
                className="submit-btn size-7 w-100 natural-8 primary-1-bg"
                onClick={handleSubmit(submit)}
              >
                {t("Продолжить")}{" "}
                <img src="/icons/star.svg" width={16} alt="star" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
