import React, { useContext } from "react";
import CustomModal from ".";
import { useTranslation } from "react-i18next";
import Button from "../buttons";
import LoaderLayout from "../LoaderLayout";
import { Context } from "../utils";
import { SET_CONFIRM } from "@/controllers/actions";

interface Props {
  text: string;
  onClick: () => void;
  loading?: boolean;
}

export default function ConfirmModal({
  text,
  onClick,
  loading = false,
}: Props) {
  const { t } = useTranslation();
  const {
    state: { confirm },
    dispatch,
  } = useContext(Context);

  const closeModal = () => {
    dispatch({ type: SET_CONFIRM, payload: false });
  };

  return (
    <CustomModal
      className="confirm-modal"
      isOpen={confirm}
      closeModal={closeModal}
    >
      <div className="react-modal-header">
        <h5 className="size-6 natural-2 mb-1 text-bold text-center mb-4">
          {t(text)}
        </h5>
      </div>
      <div className="d-flex-center justify-content-center gap-10">
        <Button
          className="natural-6-border w-25"
          onClick={closeModal}
        >
          {t("Нет")}
        </Button>
        <Button className="natural-6-border w-25" onClick={onClick}>
          <LoaderLayout
            loading={loading}
            style={{ width: "16px" }}
            children={<>{t("Да")}</>}
          />
        </Button>
      </div>
    </CustomModal>
  );
}
