import React, { ReactNode, useContext, useEffect } from "react";
import { getSettings } from "./api";
import { Context } from "./components/utils";
import { SET_SETTINGS } from "./controllers/actions";

type Props = {
  children: ReactNode;
};

export default function InitialLoadData({ children }: Props) {
  const dispatch = useContext(Context).dispatch;

  const getInitialData = async () => {
    try {
      const { data } = await getSettings();
      dispatch({ type: SET_SETTINGS, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return <>{children}</>;
}
