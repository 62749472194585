import React from "react";

interface Props {
  id?: string;
  onClick: () => void;
  className: string;
  children: any;
}

export default function Button({ onClick, className, children, id }: Props) {
  return (
    <button type="button" id={id} onClick={onClick} className={`simple-btn ${className}`}>
      {children}
    </button>
  );
}
