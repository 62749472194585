import { createStripeIntents, getStripePublishingKey } from "@/api/profile";
import LoaderLayout from "@/components/LoaderLayout";
import { Context } from "@/components/utils";
import { SET_PAYMENT } from "@/controllers/actions";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import React, { useContext, useEffect, useState } from "react";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { useTranslation } from "react-i18next";
import Button from "@/components/buttons";

export default function StripeElement() {
  const { t } = useTranslation();
  const {
    state: { payment },
    dispatch,
  } = useContext(Context);
  const [locales, setLocales] = useState<{
    loading: boolean;
    intentsKey?: string | null;
    loadedStripe?: Stripe | null;
  }>({
    loading: false,
    intentsKey: null,
    loadedStripe: null,
  });

  const closeModal = () => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        step: 1,
        amount: 0,
        isOpen: false,
      },
    });
  };

  const back = () => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        ...payment,
        step: 2,
      },
    });
  };

  const successCallback = () => {
    console.log("succes!");
  };

  const fetchStripe = async () => {
    setLocales({ loading: true });
    try {
      const { data: key } = await getStripePublishingKey();
      const { data: securityKey } = await createStripeIntents(payment);
      return setLocales({
        loading: false,
        intentsKey: String(securityKey),
        loadedStripe: await loadStripe(key),
      });
    } catch (error) {
      console.log(error);
    }
    setLocales({ intentsKey: null, loadedStripe: null, loading: false });
  };

  useEffect(() => {
    fetchStripe();
  }, []);

  return (
    <div className="balance-modal stripe">
      <div className="react-modal-header">
        <button
          className="back-btn bg-transparent border-0 p-0 d-flex-center gap-10 size-5 text-bold"
          onClick={back}
        >
          <img src="/icons/arrow-left.svg" width={24} alt="arrow" />
          {t("Назад")}
        </button>
        <Button
          className="modal-close-btn natural-6-border"
          onClick={closeModal}
        >
          <img src="/icons/close.svg" width={24} alt="close" />
        </Button>
      </div>
      <LoaderLayout
        style={{ width: "100px", margin: "0 auto" }}
        loading={locales.loading}
        children={
          <Elements
            stripe={locales.loadedStripe!}
            options={{ clientSecret: locales.intentsKey! }}
          >
            <StripeCheckoutForm
              successCallback={successCallback}
              amount={payment.amount}
            />
          </Elements>
        }
      />
    </div>
  );
}
