import { getPassVerifCode, signIn } from "@/api/auth";
import Button from "@/components/buttons";
import Input from "@/components/form-components";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoaderLayout from "@/components/LoaderLayout";
import { toast } from "react-toastify";
import { Context } from "@/components/utils";
import { SET_AUTH_STEP, SET_USER } from "@/controllers/actions";
import PasswordsInput from "@/components/form-components/PasswordInput";
import PasswordInput from "@/components/form-components/PasswordInput";

export default function SignIn() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { user },
    dispatch,
  } = useContext(Context);
  const {
    handleSubmit,
    register,
    clearErrors,
    setError,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: user });
  const [loading, setLoading] = useState(false);

  const submit = async (values: any) => {
    setLoading(true);
    try {
      const { data } = await signIn(values);
      localStorage.setItem("token", data.access_token);
      toast.success("Successfully signed in");
      navigate("/profile");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const forgotPass = async () => {
    const phoneNumber = watch("phone");
    if (!phoneNumber) {
      return setError("phone", {
        type: "required",
        message: "This field is required.",
      });
    }
    clearErrors("phone");
    try {
      await getPassVerifCode({ phone: phoneNumber });
      dispatch({ type: SET_USER, payload: { phone: phoneNumber } });
      dispatch({
        type: SET_AUTH_STEP,
        payload: { prevStep: 1, currentStep: 100 },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="login-side">
      <form className="form">
        <Input
          parentClass="d-flex flex-column"
          register={register}
          name="phone"
          type="tel"
          errors={errors}
          validations={{ required: t("This is required.") }}
          label={t("Номер телефона")}
          placeholder="+998"
          inputClass="natural-6-border text-bold natural-2"
          labelClass="size-7 natural-5 text-medium m-0"
        />
        <PasswordInput
          parentClass="d-flex flex-column"
          register={register}
          name="password"
          errors={errors}
          validations={{ required: t("This is required.") }}
          label={t("Пароль")}
          inputClass="natural-6-border text-bold natural-2"
          labelClass="size-7 natural-5 text-medium m-0"
        />
      </form>
      <div className="bottom-side d-flex-center justify-content-between">
        <Button className="forgot-btn primary-1" onClick={forgotPass}>
          {t("Я забыл пароль")}
        </Button>
        <Button
          className="submit-btn primary-1-bg natural-8 size-7"
          onClick={handleSubmit(submit)}
        >
          {t("Войти в систему")}
          <LoaderLayout
            loading={loading}
            style={{ width: "16px", marginRight: "12px" }}
            children={
              <img src="/icons/arrow-left.svg" width={16} alt="arrow" />
            }
          />
        </Button>
      </div>
    </div>
  );
}
