import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useTranslation } from "react-i18next";
import { getSummery } from "@/api/order";
import TrackingOrders from "./TrackingOrders";

export default function UserTrackings() {
  const { t } = useTranslation();
  const [summery, setSummery] = useState<SummeryType[]>([]);

  const getSummaries = async () => {
    const { data } = await getSummery("trackings");
    setSummery(data);
  };

  useEffect(() => {
    getSummaries();
  }, []);
  return (
    <>
      <Tabs>
        <TabList className="d-flex-center react-tabs__tab-list text-center p-0 m-0">
          <Tab className="react-tabs-tab flex-grow size-7 natural-1 text-bold">
            <span className="natural-4 text-bold">{t("В ожидании")}</span>:{" "}
            {summery.find((t) => t.status === "1")?.count ?? 0}
          </Tab>
          <Tab className="react-tabs-tab flex-grow size-7 natural-1 text-bold">
            <span className="natural-4 text-bold">{t("Одобренные товары")}</span>:{" "}
            {summery.find((t) => t.status === "2")?.count ?? 0}
          </Tab>
        </TabList>
        <TabPanel>
          <TrackingOrders status="1" />
        </TabPanel>
        <TabPanel>
          <TrackingOrders status="2" />
        </TabPanel>
      </Tabs>
    </>
  );
}
