import React, { useContext, useEffect } from "react";
import OrderStepSwitcher from "./OrderStepSwitcher";
import { useLocation } from "react-router-dom";
import { Context } from "@/components/utils";
import { SET_NEW_ORDER } from "@/controllers/actions";
import HowIsItWork from "@/components/how-is-it-work";

export default function NewOrder() {
  const { pathname } = useLocation();
  const {
    state: { newOrder },
    dispatch,
  } = useContext(Context);

  useEffect(() => {
    return () => {
      dispatch({
        type: SET_NEW_ORDER,
        payload: { currentStep: 1, prevStep: 0, order: {} },
      });
    };
  }, [pathname]);

  return (
    <div className="new-order-page">
      <div className="container">
        <div className="page-body">
          <div className="process-box">
            <span className={`process d-block step-${newOrder.currentStep}`} />
          </div>
          <OrderStepSwitcher currentStep={newOrder.currentStep} />
        </div>
      </div>
      <HowIsItWork />
    </div>
  );
}
