import Button from "@/components/buttons";
import Rates from "@/components/form-components/SelectRates";
import { Context } from "@/components/utils";
import { SET_NEW_ORDER } from "@/controllers/actions";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function RateSelector() {
  const { t } = useTranslation();
  const {
    state: { newOrder },
    dispatch,
  } = useContext(Context);
  const {
    dimension,
    unit,
    length,
    width,
    height,
    weight = 0,
  } = newOrder.order.boxes[0];

  const handleBack = () => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        prevStep: 11,
        currentStep: 8,
        order: { ...newOrder.order, shipment_type: null },
      },
    });
  };

  const setRate = (name: string, rate: any) => {
    let total = 0;
    let newWeight = weight;
    if (rate) {
      let dimensionWeight = 0;
      if (dimension) {
        if (unit === "kg/sm") {
          dimensionWeight =
            ((length || 0) * (width || 0) * (height || 0)) / 5000;
        } else {
          dimensionWeight =
            ((length || 0) * (width || 0) * (height || 0)) / 139;
        }
      }
      newWeight = (dimension ? dimensionWeight : weight)
      const field = unit === "kg/sm" ? "rate_kg" : "rate";
      total = +(
        newWeight < 1 ? +rate.min_price : newWeight * +rate.weight_rate[field]
      ).toFixed(2);
    } else alert("Please select a rate");

    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        ...newOrder,
        order: {
          ...newOrder.order,
          shipment_type: rate,
          ratePrice: total,
          boxes: [{ ...newOrder.order.boxes[0], weight: newWeight }],
        },
      },
    });
  };

  const handleSubmit = () => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        ...newOrder,
        prevStep: 9,
        currentStep: 10,
      },
    });
  };

  return (
    <>
      <Button className="size-3 back-btn" onClick={handleBack}>
        <img
          src="/icons/arrow-left.svg"
          className="mr-3"
          width={40}
          alt="back"
        />
        {t("Добавить ожидаемый заказ")}
      </Button>
      <div className="content-body mx-auto">
        <div className="content">
          <h6 className="text-bold size-6 lh-32">
            <span className="primary-1 text-bold">6. </span>
            {t("Выберите тарифный план доставки ")}
          </h6>
          <h6 className="size-8 natural-4 lh-20 m-0">
            {t(
              "Вы можете выбрать адрес получателя из сохранившехся адресов, либо добавить новый адрес"
            )}
          </h6>
          <hr />
          <Rates
            order={newOrder.order}
            setValue={setRate}
            rate={newOrder.order.shipment_type}
            parentClass="d-flex-center"
            labelClass=""
          />
          <hr />
          <Button
            className="submit-btn size-7 w-100 natural-8 primary-1-bg"
            onClick={handleSubmit}
          >
            {t("Продолжить")}{" "}
            <img src="/icons/star.svg" width={16} alt="star" />
          </Button>
        </div>
      </div>
    </>
  );
}
