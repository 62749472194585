import { mainCaller } from "./mainCaller";

export const getSettings = () => {
  return mainCaller("/settings", "GET");
};

export const getShops = (query: object) => {
  return mainCaller("/shop-sites", "GET", undefined, {}, query);
};

export const getBranches = () => {
  return mainCaller("/branches", "GET");
};
