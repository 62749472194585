import React, { useContext, useEffect, useState } from "react";
import ContentHeader from "./ContentHeader";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons";
import AddressCard from "./AddressCard";
import Address from "../../components/modals/Address";
import { getAddresses } from "@/api/profile";
import Pagination from "../../components/pagination";
import { Context, getAddressDefaultValues } from "../../components/utils";
import LoaderLayout from "@/components/LoaderLayout";

const initialQuery = {
  size: 6,
  page: 1,
  items: [],
};

export default function UserAddresses() {
  const { t } = useTranslation();
  const countries = useContext(Context).state.settings.countries;
  const [loading, setLoading] = useState(false);
  const [adresses, setAddresses] = useState<any>({
    ...initialQuery,
    country: countries[0],
  });
  const [modal, setModal] = useState<{ isOpen: boolean; content?: object }>({
    isOpen: false,
  });

  const onPageChange = (e: any) => {
    setAddresses({ ...adresses, page: e.selected + 1 });
  };

  const setCountry = (country: CountryType) => {
    setAddresses({ country: country, ...initialQuery });
  };

  const setAddress = (address: object) => {
    setModal({ isOpen: true, content: getAddressDefaultValues(address) });
  };

  const getAllAddresses = async () => {
    setLoading(true);
    try {
      const { data } = await getAddresses({
        country: adresses.country.id,
        page: adresses.page,
        size: adresses.size,
      });
      setAddresses({ ...adresses, ...data });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllAddresses();
  }, [adresses.country.id, adresses.page]);

  return (
    <div className="addresses-content">
      <ContentHeader title="Мои адреса" />
      <div className="d-flex-center">
        {countries.map((country: CountryType) => (
          <Button
            key={country.id}
            className={`size-8 mr-2 ${
              country.id === adresses.country.id
                ? "primary-1-bg natural-8"
                : "natural-4"
            }`}
            onClick={() => setCountry(country)}
          >
            {t(country.name)}
          </Button>
        ))}
      </div>
      <div className="row address-list">
        <LoaderLayout
          loading={loading}
          style={{ width: "50px", margin: "16px auto" }}
          children={
            adresses.items.length ? (
              adresses.items.map((address: object, i: number) => (
                <div className="col-md-6 col-12" key={i}>
                  <AddressCard
                    address={address}
                    setAddress={setAddress}
                    key={i}
                  />
                </div>
              ))
            ) : (
              <div className="m-auto">
                <img
                  width={100}
                  className="d-block m-auto"
                  src="/images/empty.png"
                  alt="empty"
                />
                <p className="m-0 mt-1 text-bold">{t("Ничего не найдено")}</p>
              </div>
            )
          }
        />
        <div className="col-md-6 col-12">
          <button
            id="new-address"
            className="btn add-btn h-100 w-100 bg-transparent"
            onClick={() =>
              setModal({
                ...modal,
                isOpen: true,
              })
            }
          >
            <img src="/icons/primary-star.svg" alt="star" width={16} />
            <h6 className="size-8 primary-1 text-bold mt-2 mb-0">
              {t("Добавить новый адрес")}
            </h6>
          </button>
        </div>
      </div>
      {adresses.meta && (
        <Pagination
          className="mt-5"
          pageCount={Math.ceil(adresses.meta.total / adresses.size)}
          onChange={onPageChange}
        />
      )}
      {modal.isOpen && (
        <Address
          modal={modal}
          setModal={setModal}
          setAddresses={setAddresses}
          addresses={adresses}
        />
      )}
    </div>
  );
}
