import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function HowIsItWork() {
  const { t } = useTranslation();

  return (
    <div className="how-is-it-work">
      <div className="container text-center">
        <h3 className="size-3 text-bold mb-3">{t("Как всё работает")}</h3>
        <h6 className="size-8 natural-4 text-medium w-50 mx-auto mb-md-5 mb-3">
          {t("Choose")}&nbsp;
          <b className="natural-2">{t("“Single”")}</b>&nbsp;
          {t("if you want your collectible to be one of a kind or")}&nbsp;
          <b className="natural-2">{t("“Multiple”")}</b>&nbsp;
          {t("if you want to sell one collectible multiple times")}
        </h6>
        <div className="row">
          <div className="col-md-3 col-12">
            <div className="how-card">
              <div className="star d-flex-center justify-content-center border-r">
                <img src="/icons/primary-star.svg" alt="star" />
              </div>
              <h6 className="size-7 text-bold lh-24">{t("Регистрируйтесь")}</h6>
              <h6 className="natural-4 lh-20 size-9">
                {t(
                  "Зарегистрируйтесь на сайте, чтобы получить персональный адрес в США.Это займет 1 минуту."
                )}
              </h6>
              <Link
                to="/login"
                className="size-8 primary-1 text-semi-bold lh-24"
              >
                {t("Получить личный адрес")}
              </Link>
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div className="how-card">
              <div className="star d-flex-center justify-content-center border-r">
                <img src="/icons/primary-star.svg" alt="star" />
              </div>
              <h6 className="size-7 text-bold lh-24">{t("Регистрируйтесь")}</h6>
              <h6 className="natural-4 lh-20 size-9">
                {t(
                  "Зарегистрируйтесь на сайте, чтобы получить персональный адрес в США.Это займет 1 минуту."
                )}
              </h6>
              <Link
                to="/login"
                className="size-8 primary-1 text-semi-bold lh-24"
              >
                {t("Получить личный адрес")}
              </Link>
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div className="how-card">
              <div className="star d-flex-center justify-content-center border-r">
                <img src="/icons/primary-star.svg" alt="star" />
              </div>
              <h6 className="size-7 text-bold lh-24">{t("Регистрируйтесь")}</h6>
              <h6 className="natural-4 lh-20 size-9">
                {t(
                  "Зарегистрируйтесь на сайте, чтобы получить персональный адрес в США.Это займет 1 минуту."
                )}
              </h6>
              <Link
                to="/login"
                className="size-8 primary-1 text-semi-bold lh-24"
              >
                {t("Получить личный адрес")}
              </Link>
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div className="how-card">
              <div className="star d-flex-center justify-content-center border-r">
                <img src="/icons/primary-star.svg" alt="star" />
              </div>
              <h6 className="size-7 text-bold lh-24">{t("Регистрируйтесь")}</h6>
              <h6 className="natural-4 lh-20 size-9">
                {t(
                  "Зарегистрируйтесь на сайте, чтобы получить персональный адрес в США.Это займет 1 минуту."
                )}
              </h6>
              <Link
                to="/login"
                className="size-8 primary-1 text-semi-bold lh-24"
              >
                {t("Получить личный адрес")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
