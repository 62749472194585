import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../components/form-components";
import { useForm } from "react-hook-form";
import Button from "../../components/buttons";
import LoaderLayout from "@/components/LoaderLayout";
import { updatePassword } from "@/api/profile";
import { toast } from "react-toastify";
import PasswordInput from "@/components/form-components/PasswordInput";

export default function UpdatePass() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm();

  const submit = async (values: object) => {
    setLoading(true);
    try {
      await updatePassword(values);
      toast.success("Password updated");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className="update-pass-content">
      <div className="warning d-flex-center">
        <img src="/icons/warning.svg" width={32} alt="warning" />
        <div className="description ml-3">
          <h5 className="size-7 natural-3 text-bold m-0">
            {t(
              "Введите свой текущий пароль, новый пароль, и повторите ввод нового пароля, чтобы исключить возможность опечатки."
            )}
          </h5>
          <span className="size-9 natural-4">
            {t("Все поля обязательны для заполнения")}
          </span>
        </div>
      </div>
      <form className="form">
        <div className="d-flex-center justify-content-between flex-wrap">
          <PasswordInput
            parentClass="d-flex flex-column flex-grow"
            register={register}
            name="old-password"
            errors={errors}
            validations={{ required: t("This is required.") }}
            label={t("Пароль *")}
            inputClass="natural-6-border text-bold"
            labelClass="size-7 natural-5 text-medium m-0"
          />
          <PasswordInput
            parentClass="d-flex flex-column flex-grow"
            register={register}
            name="current_password"
            errors={errors}
            label={t("Повторите пароль *")}
            inputClass="natural-6-border text-bold"
            labelClass="size-7 natural-5 text-medium m-0"
            validations={{
              required: t("This is required."),
              validate: {
                matchesPreviousPassword: (value: string) =>
                  watch("old-password") === value || t("Passwords must match."),
              },
            }}
          />
        </div>
        <PasswordInput
          parentClass="d-flex flex-column"
          register={register}
          name="password"
          errors={errors}
          validations={{ required: t("This is required.") }}
          label={t("Введите новый пароль *")}
          inputClass="natural-6-border text-bold"
          labelClass="size-7 natural-5 text-medium m-0"
        />
      </form>
      <Button
        className="submit-btn primary-1-bg natural-8 size-7"
        onClick={handleSubmit(submit)}
      >
        {t("Сохранить изменения")}
        <LoaderLayout
          loading={loading}
          style={{ width: "16px", marginRight: "12px" }}
          children={<img src="/icons/arrow-left.svg" width={16} alt="arrow" />}
        />
      </Button>
    </div>
  );
}
