import React from "react";
interface Props {
  src: string;
  className?: string;
  width?: string;
  height?: string;
}

export default function Banner({ width, height, src, className = "" }: Props) {
  return (
    <div
      style={{ width: width, height: height }}
      className={`banner ${className}`}
    >
      <img src={src} alt="banner" width="100%" height="100%" />
    </div>
  );
}
