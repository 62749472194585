import { getSummery } from "@/api/order";
import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Orders from "./Orders";
import { useTranslation } from "react-i18next";

const initials = {
  in_customer: 0,
  in_warehouse: 0,
  shipped: 0,
  arrive_warehouse: 0,
  delivered: 0,
};

export default function UserOrders() {
  const { t } = useTranslation();
  const [summery, setSummery] = useState<SummeryType[]>([initials]);

  const getSummaries = async () => {
    try {
      const { data } = await getSummery("orders");
      setSummery(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSummaries();
  }, []);
  
  return (
    <>
      <Tabs>
        <TabList className="d-flex-center react-tabs__tab-list text-center p-0 m-0">
          <Tab className="react-tabs-tab flex-grow size-7 natural-1 text-bold">
            <span className="natural-4 text-bold">{t("Ожидаемые")}</span>:{" "}
            {summery[0]["in_customer"]??0}
          </Tab>
          <Tab className="react-tabs-tab flex-grow size-7 natural-1 text-bold">
            <span className="natural-4 text-bold">{t("Подтверждено")}</span>:{" "}
            {summery[0]["in_warehouse"]??0}
          </Tab>
          <Tab className="react-tabs-tab flex-grow size-7 natural-1 text-bold">
            <span className="natural-4 text-bold">{t("Отправленные")}</span>:{" "}
            {summery[0]["shipped"]??0}
          </Tab>
          <Tab className="react-tabs-tab flex-grow size-7 natural-1 text-bold">
            <span className="natural-4 text-bold">{t("На складе")}</span>:{" "}
            {summery[0]["arrive_warehouse"]??0}
          </Tab>
          <Tab className="react-tabs-tab flex-grow size-7 natural-1 text-bold">
            <span className="natural-4 text-bold">{t("Доставленны")}</span>:{" "}
            {summery[0]["delivered"]??0}
          </Tab>
        </TabList>
        <TabPanel>
          <Orders status="in_customer" />
        </TabPanel>
        <TabPanel>
          <Orders status="in_warehouse" />
        </TabPanel>
        <TabPanel>
          <Orders status="shipped" />
        </TabPanel>
        <TabPanel>
          <Orders status="arrive_warehouse" />
        </TabPanel>
        <TabPanel>
          <Orders status="delivered" />
        </TabPanel>
      </Tabs>
    </>
  );
}
