import Button from "@/components/buttons";
import Input from "@/components/form-components";
import Checkbox from "@/components/form-components/Checkbox";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { emailValidation } from "@/components/constants";
import { Context } from "@/components/utils";
import { SET_AUTH_STEP, SET_USER } from "@/controllers/actions";
import { getVerifyCode } from "@/api/auth";
import PasswordInput from "@/components/form-components/PasswordInput";

export default function SignUp() {
  const { t } = useTranslation();
  const {
    state: { user },
    dispatch,
  } = useContext(Context);
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: user });

  const submit = async (values: object) => {
    try {
      await getVerifyCode(values);
      dispatch({ type: SET_USER, payload: values });
      dispatch({
        type: SET_AUTH_STEP,
        payload: { prevStep: 1, currentStep: 5 },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const sendCode = async () => {};

  return (
    <div className="signup-side">
      <form className="form">
        <div className="d-flex-center justify-content-between">
          <Input
            parentClass="d-flex flex-column w-50 pr-md-2"
            register={register}
            name="customer.first_name"
            errors={errors}
            label={t("Имя *")}
            validations={{ required: t("This is required.") }}
            placeholder={t("Введите свое имя")}
            inputClass="natural-6-border text-bold"
            labelClass="size-7 natural-5 text-medium m-0"
          />
          <Input
            parentClass="d-flex flex-column w-50 pl-md-2"
            register={register}
            name="customer.last_name"
            errors={errors}
            validations={{ required: t("This is required.") }}
            label={t("Фамилия *")}
            inputClass="natural-6-border text-bold"
            placeholder={t("Введите свое фамилия")}
            labelClass="size-7 natural-5 text-medium m-0"
          />
        </div>
        <Input
          parentClass="d-flex flex-column"
          register={register}
          name="phone"
          errors={errors}
          label={t("Номер телефона *")}
          validations={{ required: t("This is required.") }}
          placeholder="+998"
          inputClass="natural-6-border text-bold"
          labelClass="size-7 natural-5 text-medium m-0"
        />
        <Input
          parentClass="d-flex flex-column"
          register={register}
          name="customer.email"
          errors={errors}
          validations={{ required: t("This is required."), ...emailValidation }}
          label={t("Электронный адрес *")}
          inputClass="natural-6-border text-bold"
          placeholder={t("Введите электронный адрес")}
          labelClass="size-7 natural-5 text-medium m-0"
        />
        <div className="d-flex-center justify-content-between passwords">
          <PasswordInput
            parentClass="d-flex flex-column w-50 pr-md-2"
            register={register}
            name="password"
            errors={errors}
            validations={{ required: t("This is required.") }}
            label={t("Пароль *")}
            inputClass="natural-6-border text-bold"
            labelClass="size-7 natural-5 text-medium m-0"
          />
          <PasswordInput
            parentClass="d-flex flex-column w-50 pr-md-2"
            register={register}
            name="repead-pass"
            errors={errors}
            label={t("Повторите пароль *")}
            inputClass="natural-6-border text-bold"
            labelClass="size-7 natural-5 text-medium m-0"
            validations={{
              required: t("This is required."),
              validate: {
                matchesPreviousPassword: (value: string) =>
                  watch("password") === value || t("Passwords must match."),
              },
            }}
          />
        </div>
        <div className="warning d-flex-center">
          <img src="/icons/warning.svg" alt="warning" />
          <div className="description ml-2">
            <h5 className="size-7 natural-3 text-bold m-0">
              {t(
                "Введите свой пароль, и повторите ввод пароля, чтобы исключить возможность опечатки."
              )}
            </h5>
            <span className="size-9 natural-4">
              {t("Все поля обязательны для заполнения")}
            </span>
          </div>
        </div>
        <Checkbox
          name="isAgree"
          register={register}
          errors={errors}
          inputClass="w-auto"
          labelClass="m-0 natural-1 size-8 text-semi-bold pointer"
          label={t("Я прочитал и согласен с Условиями использования")}
          parentClass="d-flex-center flex-row-reverse justify-content-end"
          validations={{ required: t("This is required.") }}
        />
      </form>
      <div className="bottom-side d-flex-center justify-content-end">
        <Button
          className="submit-btn primary-1-bg natural-8 size-7"
          onClick={handleSubmit(submit)}
        >
          {t("Войти в систему")}
          <img src="/icons/arrow-left.svg" width={16} alt="arrow" />
        </Button>
      </div>
    </div>
  );
}
