import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  user: any;
}

export default function LoginButton({ user }: Props) {
  const { t } = useTranslation();

  return user && user.access_token ? (
    <Link to="/profile" className="d-flex-center ml-6 profile-btn">
      <img
        src={user.customer?.image ?? `/icons/person.svg`}
        alt="user"
        width={46}
      />
      <div>
        <h5 className="text-bold natural-2 mb-1 text-truncate">
          {user.customer.first_name} {user.customer.last_name?.charAt(0)}
        </h5>
        <h6 className="text-bold natural-4">ID: {user.customer.id}</h6>
      </div>
    </Link>
  ) : (
    <Link
      to="/login"
      className="simple-btn size-8 text-bold natural-6-border natural-2 login-btn"
    >
      {t("Авторизироваться")}
    </Link>
  );
}
