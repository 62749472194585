import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getUserOrders } from "@/api/profile";
import Pagination from "../../../components/pagination";
import LoaderLayout from "@/components/LoaderLayout";

interface Props {
  status: string;
}
const initialQuery = {
  size: 6,
  page: 1,
  active: 0,
  items: [],
};

export default function ShopOrders({ status }: Props) {
  const { t } = useTranslation();
  const [localStore, setLocalStore] = useState<any>(initialQuery);
  const [loading, setLoading] = useState(false);

  const handleActive = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
    e.preventDefault();
    setLocalStore({ ...localStore, active: id === localStore.active ? 0 : id });
  };

  const getParcels = async () => {
    setLoading(true);
    try {
      const { data } = await getUserOrders("shop-orders", {
        page: localStore.page,
        size: localStore.size,
        status: status,
      });
      setLocalStore({ ...localStore, ...data });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onPageChange = (e: any) => {
    setLocalStore({ ...localStore, page: e.selected + 1 });
  };

  useMemo(() => setLocalStore(initialQuery), [status]);

  useEffect(() => {
    getParcels();
  }, [localStore.page, status]);

  return (
    <div className="parcels-list row" key={localStore.item}>
      <LoaderLayout
        loading={loading}
        style={{ width: "50px", margin: "16px auto" }}
        children={
          localStore.items.length ? (
            localStore.items.map((parcel: any, i: number) => (
              <div className="col-md-6 col-12" key={i}>
                <Link to={`${parcel.id}`} className="parcel-box mt-3 d-block">
                  <div className="parcel-box-header primary-1-gradient-bg">
                    <div className="d-flex-center mb-2">
                      <img src="/icons/time.svg" width={20} alt="time" />
                      <h5 className="text-bold natural-2 size-7 m-0 mx-2">
                        №{parcel.id}
                      </h5>
                      <h6 className="m-0 natural-4 size-7 text-medium">
                        {parcel.created_at.split("T")[0].replaceAll("-", ".")}{" "}
                        {parcel.created_at.split("T")[1].slice(0, 5)}
                      </h6>
                    </div>
                    <div
                      className={`parcel-box-header-info ${
                        localStore.active === parcel.id ? "active" : ""
                      }`}
                    >
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="">
                          <h5 className="text-medium natural-4 size-7 m-0 mb-1">
                            {t("Информация")}
                          </h5>
                          <h5 className="text-bold natural-2 size-7 m-0">
                            {parcel.location}
                          </h5>
                        </div>
                        <button
                          className="btn more-btn p-0"
                          onClick={(e) => handleActive(e, parcel.id)}
                        >
                          <img
                            src="/icons/down-arrow.svg"
                            width={24}
                            alt="arrow"
                          />
                        </button>
                      </div>
                      <div className="d-flex align-items-start justify-content-between mb-2">
                        <h5 className="text-medium natural-4 size-8 m-0 mb-1">
                          {t("Адрес получателя")}
                        </h5>
                        <h5 className="text-bold natural-2 size-8 m-0 text-right">
                          {parcel.recipient_address.region?.name},{" "}
                          {parcel.recipient_address.district?.name}
                        </h5>
                      </div>
                      <div className="d-flex align-items-start justify-content-between mb-2">
                        <h5 className="text-medium natural-4 size-8 m-0 mb-1">
                          {t("Комментарий")}
                        </h5>
                        <h5 className="text-bold natural-2 size-8 m-0 text-right w-50">
                          {parcel.note}
                        </h5>
                      </div>
                    </div>
                    <div className="parcel-details d-flex-center">
                      <div className="flex-grow">
                        <h5 className="text-medium natural-4 size-7 mb-1">
                          {t("Товары")}
                        </h5>
                        <h5 className="text-bold natural-2 size-7 m-0">
                          {parcel.records.length}
                          &nbsp;{t("шт")}
                        </h5>
                      </div>
                      <div className="flex-grow">
                        <h5 className="text-medium natural-4 size-7 mb-1">
                          {t("Услуга")}
                        </h5>
                        <h5 className="text-bold natural-2 size-7 m-0">
                          $ {parcel.fee}
                        </h5>
                      </div>
                      <div className="flex-grow">
                        <h5 className="text-medium natural-4 size-7 mb-1">
                          {t("Стоимость")}
                        </h5>
                        <h5 className="text-bold natural-2 size-7 m-0">
                          $ {parcel.total_cost}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`parcel-box-footer text-center parcel-box-footer-bg-${status}`}
                  >
                    <h5 className={`text-bold size-7 m-0 ${status}`}>
                      {t("Доставлено")}
                    </h5>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="ml-auto mr-auto mt-3">
              <img
                width={100}
                className="d-block m-auto"
                src="/images/empty.png"
                alt="empty"
              />
              <p className="m-0 mt-1 text-bold">{t("Ничего не найдено")}</p>
            </div>
          )
        }
      />
      {localStore.meta && (
        <Pagination
          className="mt-5 mb-0 w-100"
          pageCount={Math.ceil(localStore.meta.total / localStore.size)}
          onChange={onPageChange}
        />
      )}
    </div>
  );
}
