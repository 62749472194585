import React from "react";
import Modal from "react-modal";

interface Props {
  children: any;
  className?: string;
  isOpen: boolean;
  closeModal: () => void;
}

const customStyles = {
  overlay: {
    backgroundColor: "rgba(35, 38, 47,0.9)",
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90%",
  },
};

Modal.setAppElement("#root");

export default function CustomModal({
  children,
  className,
  isOpen,
  closeModal,
}: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles || {}}
      className={className}
    >
      {children}
    </Modal>
  );
}
