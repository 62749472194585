import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  successCallback: () => void;
  amount: string;
}

export default function StripeCheckoutForm({ successCallback, amount }: Props) {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [locales, setLocales] = useState({ loading: false, error: "" });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLocales({
      loading: true,
      error: "",
    });

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/stripe-completion`,
        payment_method_data: {
          billing_details: {
            address: {
              country: "auto",
              postal_code: "auto",
            },
          },
        },
      },
      redirect: "if_required",
    });

    if (error) {
      return setLocales({ loading: false, error: String(error.message) });
    } else if (paymentIntent.status === "succeeded") {
      console.log("Payment succeeded 🎉");
      return successCallback();
    }
  };

  return (
    <form className="react-modal-body" onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="d-flex-center justify-content-between mt-4">
        <h5 className="m-0 text-medium">{t("Сумма оплаты")} :</h5>
        <h4 className="text-semi-bold m-0">$ {amount}</h4>
      </div>
      <button
        id="submit"
        type="submit"
        className="simple-btn submit-btn primary-1-bg w-100 natural-8 size-7 mt-5"
        disabled={locales.loading}
      >
        {!locales.loading ? t("Оплатить") : t("Обработка...")}
      </button>
      {locales.error ? <p>{locales.error}</p> : ""}
    </form>
  );
}
