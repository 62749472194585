import Banner from "@/components/banners";
import { Context } from "@/components/utils";
import React, { useContext } from "react";

export default function AuthMediaSwitcher() {
  const currentStep = useContext(Context).state.authStep.currentStep;

  switch (currentStep) {
    case 1:
      return <Banner width="592px" src="/images/auth-banner-1.png" />;
    case 2:
      return <Banner width="592px" src="/images/auth-banner-2.png" />;
    case 3:
      return <Banner width="592px" src="/images/auth-media-2.png" />;
    default:
      return <Banner width="592px" src="/images/auth-media-2.png" />;
  }
}
