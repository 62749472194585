import React from "react";
import AboutFgfSection from "@/components/about-fgf-section";
import BannersSection from "@/components/banners-section/BannersSection";
import HowItWorksSection from "@/components/how-it-works-section/HowItWorksSection";
import MapSection from "@/components/map-section";
import ReviewsSection from "@/components/reviews-section";
import SendEmailSection from "@/components/send-email-section";
import SliderSection from "@/components/slider-section";
import TopVideoSection from "@/components/top-video-section/TopVideoSection";
import WhyUsSection from "@/components/why-us-section";

export default function Home() {
  return (
    <div className="home-page">
      <TopVideoSection />
      <div className="container-fluid">
        <BannersSection />
        <HowItWorksSection />
        <AboutFgfSection />
      </div>
      <ReviewsSection />
      <WhyUsSection />
      <SliderSection />
      <MapSection />
      <SendEmailSection />
    </div>
  );
}
