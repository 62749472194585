import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../banners";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Calculator from "../modals/Calculator";

export default function Footer() {
  const { t } = useTranslation();
  const [opener, setOpener] = useState<{ calc?: boolean }>({
    calc: false,
  });

  return (
    <footer className="footer-section">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-12">
            <Banner src="/images/fgf-logo.png" width="116px" />
            <h6 className="size-7 my-3 text-bold lh-24 natural-3">
              {t("Сервис доставки товаров из зарубежных магазинов")}
            </h6>
            <h6 className="size-8 mb-3 text-bold lh-24 natural-4">
              {t(
                "После регистрации вам доступны адреса наших складов в 30 странах мира."
              )}
            </h6>
            <div className="d-flex-center">
              <a href="#" target="_blank">
                <img src="/icons/twitter.svg" alt="twitter" />
              </a>
              <a href="#" className="mx-3" target="_blank">
                <img src="/icons/instagram.svg" alt="instagram" />
              </a>
              <a href="#" className="mr-3" target="_blank">
                <img src="/icons/facebook.svg" alt="facebook" />
              </a>
              <a href="#" target="_blank">
                <img src="/icons/vk.svg" alt="vk" />
              </a>
            </div>
          </div>
          <div className="offset-md-1 d-md-block d-none" />
          <div className="col-md-2 col-6">
            <h6 className="size-7 text-bold lh-24">{t("О компании")}</h6>
            <Link to="/services" className="size-8 natural-4 text-bold">
              {t("О сервисе")}
            </Link>
            <Link to="/rates" className="size-8 natural-4 text-bold">
              {t("Тарифы и цены")}
            </Link>
            <Link to="/services" className="size-8 natural-4 text-bold">
              {t("Наши услуги")}
            </Link>
            <HashLink to="/#reviews" className="size-8 natural-4 text-bold">
              {t("Отзывы клиентов")}
            </HashLink>
            <Link to="/contacts" className="size-8 natural-4 text-bold">
              {t("Контакты")}
            </Link>
          </div>
          <div className="col-md-2 col-6">
            <h6 className="size-7 text-bold lh-24">{t("Покупателям")}</h6>
            <Link to="/" className="size-8 natural-4 text-bold">
              {t("Распродажи")}
            </Link>
            <Link to="/" className="size-8 natural-4 text-bold">
              {t("Отследить посылку")}
            </Link>
            <Link to="/help" className="size-8 natural-4 text-bold">
              {t("Помощь")}
            </Link>
            <Link to="/" className="size-8 natural-4 text-bold">
              {t("Помощь в покупке")}
            </Link>
            <Link to="/" className="size-8 natural-4 text-bold">
              {t("Ограничения и запреты")}
            </Link>
            <Link to="/shops" className="size-8 natural-4 text-bold">
              {t("Популярные магазины")}
            </Link>
          </div>
          <div className="col-md-2 col-6">
            <h6 className="size-7 text-bold lh-24">{t("Полезное")}</h6>
            <Link to="/" className="size-8 natural-4 text-bold">
              {t("Доставка")}
            </Link>
            <Link to="/" className="size-8 natural-4 text-bold">
              {t("Отправка посылок")}
            </Link>
            <Link to="/" className="size-8 natural-4 text-bold">
              {t("Таможенные лимиты")}
            </Link>
            <Link to="/" className="size-8 natural-4 text-bold">
              {t("Таблица размеров")}
            </Link>
            <button
              className="btn d-block bg-transparent p-0 size-8 natural-4 text-bold"
              onClick={() => setOpener({ calc: true })}
            >
              {t("Расчет стоимости")}
            </button>
          </div>
          <div className="col-md-2 col-6">
            <h6 className="size-7 text-bold lh-24">{t("Контакты")}</h6>
            <a href="#" target="_blank" className="size-8 natural-4 text-bold">
              {t("Соединенные Штаты Америки 645 W 1st AveRoselle, NJ 07203")}
            </a>
            <a href="#" target="_blank" className="size-8 natural-4 text-bold">
              {t("support@fgf.uz")}
            </a>
          </div>
        </div>
        <hr className="mt-5 mb-0" />
        <div className="footer-bottom d-flex-center justify-content-between">
          <span className="size-9 natural-4 lh-20">
            {t("© 2024 ООО «FGF Cabinet technical». Все права защищены")}
          </span>
          <span className="size-9 lh-20">
            {t("Мы используем файлы cookie для лучшего обслуживания.")}
          </span>
        </div>
      </div>
      {opener.calc && <Calculator modal={opener} setModal={setOpener} />}
    </footer>
  );
}
