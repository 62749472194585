import i18next from "i18next";
import React from "react";
import { date, months, orderTypes, pickups } from "./constants";
import { initialState } from "@/controllers/reducer";

export const Context = React.createContext({
  state: initialState,
  dispatch: (data: ActionType) => {},
});

export function toTop() {
  window.scroll({
    top: 0,
    behavior: "smooth",
  });
}

export const maskedNumber = (number: string) => {
  const first = number.slice(0, 4);
  const second = number.slice(4, 6);
  const three = number.slice(6, 9);
  const four = number.slice(12);

  return `${first} ${second} ${three} ** * ${four}`;
};

export const copy = (htmlId: string) => {
  const copyText = document.getElementById(htmlId)?.innerText || "";
  console.log(copyText);
  
  navigator.clipboard.writeText(copyText);
};

interface Tasks {
  [key: string]: any;
}

const tasks: Tasks = {};

export function debounce(
  callBack: () => void,
  nameTask: string,
  duration = 1000
) {
  // get task
  let task = tasks[nameTask];

  // remove timeout if it has
  task && clearTimeout(task);

  // set new task or update
  tasks[nameTask] = setTimeout(callBack, duration);
}

export const getMonthDays = (month: string) => {
  const monthIndex =
    months.find((m) => m.value === month)?.index || new Date().getMonth();

  const date = new Date(2024, monthIndex + 1, 0);
  const numDays = date.getDate();
  const daysArray = Array.from({ length: numDays }, (_, i) => ({
    label: String(i + 1).padStart(2, "0"),
    value: String(i + 1).padStart(2, "0"),
  }));

  return daysArray;
};

export const orderStepNavigator = (currentStep: number, order_type: number) => {
  if (currentStep === 7 && order_type === 6) {
    return 8;
  } else if (currentStep === 8 && order_type === 6) {
    return 9;
  } else return 10;
};

export const formatDateByDateInfo = (date_info: DateInfoType) => {
  if (date_info) {
    return `2024-${date_info.month}-${date_info.day}`;
  } else return `${date.getFullYear()}-${date.getMonth()}-${date.getDay() + 1}`;
};

export const currentMonth =
  date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;

export const currentDay =
  date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

export const pickupTimeOptions = (times: PickupTimeType[]) => {
  const initialOptions = [{ label: "Выберите время доставки", value: null }];
  if (times.length) {
    const timesOptions = times.map((time) => {
      const startTime = time.start_time.slice(0, 5);
      const endTime = time.end_time.slice(0, 5);
      return {
        label: `С ${startTime} AM по ${endTime} AM`,
        value: `${startTime} - ${endTime}`,
      };
    });
    return [...initialOptions, ...timesOptions];
  } else return initialOptions;
};

export const formatToOptions = (list: OptionType[]) => {
  return list.map((item) => ({ label: item.name, value: item.id }));
};

export const margeLimitAddress = (
  list: any[],
  limits: CustomsLimitType[],
  order: any
) => {
  var newList = list;
  var orderSum = 0;

  if (order.boxes) {
    orderSum = order.boxes[0].products.reduce((acc: number, order: any) => {
      return (acc = acc + +order.price);
    }, 0);
  } else {
    orderSum = order.records.reduce((acc: number, order: any) => {
      return (acc = acc + +order.price);
    }, 0);
  }

  if (limits.length) {
    newList = newList.map((item) => {
      const limit = limits.find(
        (limit) => item.customer_id === limit.customer_id
      );
      return {
        ...item,
        limit: limit ? orderSum + +limit.customs_price : orderSum,
      };
    });
  } else newList = newList.map((item) => ({ ...item, limit: orderSum }));

  const items = newList.map((item: any) => ({
    ...item,
    limit: item.country.name !== "USA" ? item.limit : 0,
  }));

  return items;
};

export function makeLoopIds(arr: any[], keyName: string) {
  var idArr: string[] = [];
  arr.forEach((item) => {
    const keyValue = `${keyName}[]=${item.id}`;
    if (!idArr.includes(keyValue)) {
      idArr.push(keyValue);
    }
  });
  return idArr.toString().replace(/,/g, "&");
}

export const getPickups = (country: string) => {
  return country === "UZB" ? [pickups[0]] : pickups;
};

export const filterTariffs = (list: TariffType[], addresses: any) => {
  return list.filter(
    (item) =>
      item.sender_country === addresses.sender_address.country.id &&
      item.receiver_country === addresses.recipient_address.country.id
  );
};

export const filterDocuments = (documents: DocumentFilesType[]) => {
  return documents.map((item: DocumentFilesType) => {
    for (const key in item) {
      if (item[key as keyof DocumentFilesType]?.url) {
        delete item[key as keyof DocumentFilesType];
      }
    }
    return item;
  });
};

export const getAddressDefaultValues = (address: any) => {
  if (!address) {
    return { country_id: 1 };
  } else {
    return {
      ...address,
      country_id: address.country.id,
      region_id: address.region?.id,
      district_id: address.district?.id,
    };
  }
};

export const removeEmptyValues = (obj: any) => {
  for (var key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  }
  return obj;
};

export const actionStatusTime = (record: any, status: string) => {
  const statusAction = (record.action_statuses || []).find(
    (s: any) => s.status === status
  );
  if (statusAction) {
    return statusAction.created_at.split(", ")[0];
  }
  return "";
};

export const getRecordsSum = (orders: any[]) => {
  const total = orders.reduce((acc, ord) => {
    const sum =
      +ord[`${ord.special_rate ? "special_rate" : "price"}`] * +ord.quantity;
    return (acc += sum);
  }, 0);
  return total;
};

export const remove = (list: string[]) => {
  const uniqueArray = list.filter((item, index, self) => {
    return self.indexOf(item) === index;
  });
  console.log(uniqueArray.length);
};

export const errorMessage = (data: any) => {
  if (data.error) return data.error;
  else if (data.errors) return i18next.t(Object.values(data.errors).join("\n"));
  else return "Что-то пошло не так. Пожалуйста, повторите попытку позже";
};

export const removeProduct = (products: ProductType[], id: number) => {
  return products.filter((rec: any) =>
    rec.id !== id ? { ...rec, product_id: rec.product.id } : false
  );
};

export const getOrderTypes = (ordersTypes: object, branchId: number) => {
  return Object.entries(ordersTypes).reduce(
    (acc: OrderType[], [orderTypeName, branches]) => {
      const branchExists = branches.some(
        (branch: OptionType) => branch.id === branchId
      );
      if (branchExists) {
        acc.push(orderTypes.find((type) => type.name === orderTypeName)!);
      }
      return acc;
    },
    []
  );
};
