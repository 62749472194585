import { ErrorMessage } from "@hookform/error-message";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  options: CountryType[];
  watch: (name: string) => void;
  setValue: (name: string, value: number) => void;
  errors?: object;
  inputClass?: string;
  labelClass?: string;
  parentClass?: string;
  label?: string;
}

export default function CountrySelector({
  name,
  errors,
  setValue,
  watch,
  options,
  labelClass = "",
  parentClass = "",
  label = "",
}: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const country: CountryType = options.find(
    (option) => option.id === +watch(name)
  )!;

  const onHandleChange = (country: CountryType) => {
    setValue(name, country.id);
    setIsOpen(!isOpen);
  };

  return (
    <div className={`country-selector position-relative ${parentClass}`}>
      <div
        className="country-selector-body pointer d-flex-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="img-box mr-3 rounded-circle d-flex-center">
          <img src="/icons/marker.svg" alt="flag" />
        </div>
        <div className="selected">
          {label && <h6 className={labelClass}>{t(label)}</h6>}
          <h6 className="size-6 text-medium mb-0 natural-2">{country.name}</h6>
        </div>
        <img
          src="/icons/down-arrow.svg"
          className="arrow ml-auto"
          width={24}
          alt="arrow"
        />
      </div>
      {isOpen && (
        <ul className="select-options mt-1">
          {options.map((c: CountryType) => (
            <li
              className="option pointer natural-2 text-medium mb-3 size-7"
              value={country.id}
              key={c.id}
              onClick={() => onHandleChange(c)}
            >
              <img src={"/icons/marker.svg"} width={30} alt="flag" />
              {c.name}
            </li>
          ))}
        </ul>
      )}
      {errors && (
        <span className="primary-3 size-8 error-message mt-1 position-absolute">
          <ErrorMessage errors={errors} name={name} />
        </span>
      )}
    </div>
  );
}
