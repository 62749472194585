import React, { useState } from "react";
import CustomModal from "..";
import { useTranslation } from "react-i18next";
import Button from "../../buttons";
import { useFieldArray, useForm } from "react-hook-form";
import SearchProducts from "../../form-components/SearchProducts";
import Input from "../../form-components";
import { deleteOrderById, updateOrder } from "@/api/order";
import LoaderLayout from "@/components/LoaderLayout";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import WeightInput from "@/components/form-components/WeightInput";

interface Props {
  order: any;
  modal: any;
  setModal: (data: any) => void;
  setOrder: (data: object) => void;
}

export default function UpdateOrder({
  order,
  setOrder,
  modal,
  setModal,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: order.boxes[0],
  });
  const { fields, remove } = useFieldArray({
    control,
    name: "products",
    keyName: "_id",
  });

  const closeModal = () => {
    setModal({ edit: false });
  };

  const submit = async (values: any) => {
    setLoading(true);
    let { recipient_address, sender_address, products, ...filteredBox } =
      values;
    if (fields.length < 1) {
      try {
        await deleteOrderById(order.id);
        toast.success("Successfully deleted");
        return navigate(-1);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      return;
    }
    const product = products.map((product: ProductType) => ({
      ...product,
      product_id: product.product_id ?? product.product.id,
    }));
    const orderObject = {
      sender_country_id: order.sender_country_id,
      receiver_country_id: order.receiver_country_id,
      shipment_type_id: order.shipment_type.id,
      branch_id: order.branch.id,
      unit: order.unit,
      boxes: [
        {
          ...filteredBox,
          products: product,
          sender_address_id: sender_address.id,
          recipient_address_id: recipient_address.id,
        },
      ],
    };
    try {
      const { data } = await updateOrder(order.id, orderObject);
      setOrder(data);
      setModal({ edit: false });
      toast.success("Successfully updated");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <CustomModal
      className="order-update-modal"
      isOpen={modal.edit}
      closeModal={closeModal}
    >
      <div className="react-modal-header">
        <h5 className="size-6 natural-2 mb-1 lh-32 text-bold">
          {t("Обновить заказ")}
        </h5>
        <Button
          className="modal-close-btn natural-6-border"
          onClick={closeModal}
        >
          <img src="/icons/close.svg" width={24} alt="close" />
        </Button>
      </div>
      <hr className="my-4" />
      <div className="react-modal-body">
        {fields.length > 0 ? (
          fields.map((_, i) => {
            return (
              <div className="order-info d-table w-100" key={i}>
                <div className="info-body w-100 table-cell">
                  <Button className="delete-btn" onClick={() => remove(i)}>
                    <img src="/icons/delete.svg" alt="delete" />
                  </Button>
                  <SearchProducts
                    parentClass="d-flex flex-column"
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    name={`products[${i}]`}
                    labelClass="size-7 natural-5 text-medium m-0"
                    validations={{ required: t("This is required.") }}
                    label={t("Название товара *")}
                    errors={errors}
                    inputClass="natural-6-border text-bold"
                    placeholder={t("Пример: Игровая консоль")}
                  />
                  <div className="d-flex-center gap-20">
                    <Input
                      parentClass="d-flex flex-column"
                      register={register}
                      name={`products[${i}].quantity`}
                      type="number"
                      labelClass="size-7 natural-5 text-medium m-0"
                      validations={{
                        required: t("This is required."),
                        min: { value: 1, message: "Minimum is 1" },
                      }}
                      label={t("Количество *")}
                      errors={errors}
                      inputClass="natural-6-border text-bold"
                      placeholder={t("Кол-во")}
                    />
                    <Input
                      parentClass="d-flex flex-column"
                      register={register}
                      name={`products[${i}].weight`}
                      type="number"
                      labelClass="size-7 natural-5 text-medium m-0"
                      label={t("Вес")}
                      errors={errors}
                      inputClass="natural-6-border text-bold"
                      placeholder="Вес"
                    />
                    <Input
                      parentClass="d-flex flex-column"
                      register={register}
                      name={`products[${i}].price`}
                      type="number"
                      labelClass="size-7 natural-5 text-medium m-0"
                      validations={{
                        required: t("This is required."),
                        min: { value: 1, message: "Minimum is 1" },
                      }}
                      label={t("Стоимость *")}
                      errors={errors}
                      inputClass="natural-6-border text-bold"
                      placeholder="$"
                    />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <img
            width={100}
            className="d-block mx-auto mb-4"
            src="/images/empty.png"
            alt="empty"
          />
        )}
        <hr className="mt-1 mb-4" />
        <div className="d-flex-center justify-content-between gap-20 mb-4">
          <Input
            parentClass="d-flex flex-column"
            register={register}
            name="width"
            type="number"
            labelClass="size-7 natural-5 text-medium m-0"
            label={t("Ширина")}
            errors={errors}
            inputClass="natural-6-border text-bold"
            placeholder={t("Ширина")}
          />
          <Input
            parentClass="d-flex flex-column"
            register={register}
            name="height"
            type="number"
            labelClass="size-7 natural-5 text-medium m-0"
            label={t("Высота")}
            errors={errors}
            inputClass="natural-6-border text-bold"
            placeholder={t("Высота")}
          />
          <Input
            parentClass="d-flex flex-column"
            register={register}
            name="length"
            type="number"
            labelClass="size-7 natural-5 text-medium m-0"
            label={t("Длина")}
            errors={errors}
            inputClass="natural-6-border text-bold"
            placeholder={t("Длина")}
          />
          <WeightInput
            name="weight"
            selectName="unit"
            register={register}
            errors={errors}
            labelClass="size-7 natural-5 text-medium m-0"
            label={t("Вес")}
          />
        </div>
      </div>
      <hr className="mt-1 mb-4" />
      <Button
        className="add-btn w-100 primary-1-bg natural-8"
        onClick={handleSubmit(submit)}
      >
        <LoaderLayout
          loading={loading}
          style={{ width: "16px" }}
          children={t("Сохранять")}
        />
      </Button>
    </CustomModal>
  );
}
