import React from "react";
import { useTranslation } from "react-i18next";
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  Thumbs,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { reviewsBreakpoints } from "../constants";

const reviews = [
  {
    img: "/icons/person.svg",
    name: "Дмитрий",
    review: "5,0",
    comment:
      "Очень доволен работой этой компании по доставке из Америки. Мои посылки всегда приходили в срок, упаковка была качественной, и ни один товар не пострадал в пути.",
  },
  {
    img: "/icons/person.svg",
    name: "Дмитрий",
    review: "5,0",
    comment:
      "Очень доволен работой этой компании по доставке из Америки. Мои посылки всегда приходили в срок, упаковка была качественной, и ни один товар не пострадал в пути.",
  },
  {
    img: "/icons/person.svg",
    name: "Дмитрий",
    review: "5,0",
    comment:
      "Очень доволен работой этой компании по доставке из Америки. Мои посылки всегда приходили в срок, упаковка была качественной, и ни один товар не пострадал в пути.",
  },
  {
    img: "/icons/person.svg",
    name: "Дмитрий",
    review: "5,0",
    comment:
      "Очень доволен работой этой компании по доставке из Америки. Мои посылки всегда приходили в срок, упаковка была качественной, и ни один товар не пострадал в пути.",
  },
];

export default function ReviewsSection() {
  const { t } = useTranslation();

  return (
    <section id="reviews" className="reviews-section">
      <div className="container-fluid">
        <h3 className="size-3 text-bold mb-3 text-center">
          {t("Вот что говорят наши клиенты")}
        </h3>
        <h6 className="size-8 natural-4 text-medium lh-24 text-center m-0">
          {t("Наши клиенты довольны нашими услугами")}
        </h6>
        <Swiper
          autoplay
          spaceBetween={132}
          initialSlide={1}
          slidesPerView={2}
          centeredSlides={true}
          navigation
          modules={[Navigation, Autoplay]}
          loop={true}
          breakpoints={reviewsBreakpoints}
        >
          {reviews.map((item, i) => (
            <SwiperSlide key={i}>
              <div className="slide-shadow" />
              <div className="review-card">
                <div className="d-flex-center">
                  <img
                    className="rounded-circle"
                    src={item.img ?? "/icons/person.svg"}
                    alt="photo"
                    width={64}
                  />
                  <div className="info ml-3">
                    <h6 className="size-6 text-bold lh-32 m-0">{item.name}</h6>
                    <p className="primary-1 text-medium lh-32 m-0">
                      {item.review}
                    </p>
                  </div>
                </div>
                <h6 className="text-medium m-0">{item.comment}</h6>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
