import React, { useContext } from "react";
import { Context } from "@/components/utils";
import Balance from "./Balance";
import PaymentMethod from "./PaymentMethod";
import Stripe from "./Stripe";
import Payme from "./Payme";
import OrderPayment from "./OrderPayment";

export default function PaymentContentSwitcher({ payment }: { payment: any }) {
  const currentStep = useContext(Context).state.payment.step;
  console.log(payment);

  switch (currentStep) {
    case 1:
      return <Balance />;
    case 2:
      return <PaymentMethod />;
    case 3:
      return <Stripe />;
    case 4:
      return <Payme />;
    case 5:
      return <OrderPayment />;
    default:
      return <Balance />;
  }
}
