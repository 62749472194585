import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "../utils";
import Button from "../buttons";
import { emailValidation } from "../constants";
import LoaderLayout from "../LoaderLayout";

export default function SendEmailSection() {
  const { t } = useTranslation();
  const [email, setEmail] = useState({
    email: "",
    eMessage: "",
    loading: false,
  });

  const setComment = (e: ChangeEvent<HTMLInputElement>) => {
    debounce(
      () => setEmail({ email: e.target.value, eMessage: "", loading: false }),
      "email",
      500
    );
  };

  const submit = () => {
    if (email.email.match(emailValidation.pattern.value)) {
      setEmail({ ...email, eMessage: "", loading: true });
      alert(email.email);
      setEmail({ ...email, eMessage: "", loading: false });
    } else setEmail({ ...email, eMessage: emailValidation.pattern.message });
  };

  return (
    <section className="send-email-section">
      <div className="container">
        <div className="d-flex-center justify-content-between">
          <h6 className="size-7 text-bold lh-24 natural-3 ws-pw m-0">
            {t(
              "Каждый будний вечер мы рассылаем сводку новостей без белиберды и рекламы. Две минуты на чтение — и вы в курсе главных событий."
            )}
          </h6>
          <div className="d-flex-center">
            <div className="custom-input">
              <input
                style={{ borderColor: email.eMessage ? "#A52625" : "#FFFFFF" }}
                onChange={setComment}
                className="form-control text-bold bg-transparent"
                placeholder={t("Введите ваш Email")}
              />
            </div>
            <Button className="natural-8 size-8 ml-2" onClick={submit}>
              <LoaderLayout
                loading={email.loading}
                children={t("Подписаться")}
              />
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
