import React, { useContext, useEffect, useState } from "react";
import Logo from "../Logo";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Button from "../buttons";
import LoginButton from "../buttons/LoginButton";
import Calculator from "../modals/Calculator";
import { HashLink } from "react-router-hash-link";
import { Context } from "../utils";

export default function Header() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const user = useContext(Context).state.user;
  const [opener, setOpener] = useState<{ menu?: boolean; calc?: boolean }>({
    menu: false,
    calc: false,
  });

  useEffect(() => {
    setOpener({});
  }, [pathname]);

  return (
    <div className="header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8 col-7">
            <div className="d-flex-center left-side">
              <LoginButton user={user} />
              <Logo width="85px" height="88px" />
              <div className="v-line mx-4" />
              <div
                className={`routers d-flex-center justify-content-center w-auto ${
                  opener.menu ? "active" : ""
                }`}
              >
                <Link to="/rates" className="mr-6 text-bold natural-4">
                  {t("Rates")}
                </Link>
                <Link to="/services" className="mr-6 text-bold natural-4">
                  {t("Services")}
                </Link>
                <Link to="/shops" className="mr-6 text-bold natural-4">
                  {t("Shops")}
                </Link>
                {/* <Link to="/about-us" className="mr-6 text-bold natural-4">
                  {t("About Us")}
                </Link> */}
                <HashLink to="/#reviews" className="mr-6 text-bold natural-4">
                  {t("Reviews")}
                </HashLink>
                <Link to="/contacts" className="mr-6 text-bold natural-4">
                  {t("Contacts")}
                </Link>
                <Link to="/help" className="text-bold natural-4">
                  {t("Help")}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-5">
            <div className="d-flex-center right-side justify-content-end">
              {!user && (
                <Link to="/notice">
                  <img src="" alt="" />
                </Link>
              )}
              <Button
                className="primary-5-gradient-bg natural-8 text-bold cacl-btn size-8"
                onClick={() => setOpener({ ...opener, calc: true })}
              >
                <img src="/icons/star.svg" alt="star" />
                {t("Калькулятор")}
              </Button>
              <LoginButton user={user} />
              <button
                className="simple-btn only-mobile hamburger-btn"
                onClick={() => setOpener({ menu: !opener.menu })}
              >
                <img src="/icons/lines.svg" alt="lines" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {opener.calc && <Calculator modal={opener} setModal={setOpener} />}
    </div>
  );
}
