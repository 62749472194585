import React from "react";
import FAQ from "./FAQ";
import FreeAddresses from "./FreeAddresses";
import ContentHeader from "./ContentHeader";

export default function UserCabinetMain() {
  return (
    <div className="cabinet-content">
      <ContentHeader title="Мой кабинет" />
      <FreeAddresses />
      <hr className="styled-hr" />
      <FAQ />
    </div>
  );
}
