import React, { useEffect, useState } from "react";

interface Props {
  text: string;
  className: string;
  onClick: () => void;
}

export default function TimerButton({ className, onClick, text }: Props) {
  const [timer, setTimer] = useState(60);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [reset]);

  return (
    <button
      disabled={timer > 0}
      className={`timer-btn btn p-0 bg-transparent ${className}`}
      onClick={() => {
        onClick();
        setTimer(60);
        setReset(!reset);
      }}
    >
      {text}:{" "}
      {timer > 0 ? (
        timer
      ) : (
        <img width={20} src="/icons/refresh.svg" alt="refresh" />
      )}
    </button>
  );
}
