import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../buttons";
import Banner from "../banners";

export default function TopVideoSection() {
  const { t } = useTranslation();

  return (
    // <div className="container-fluid">
      <section className="top-video-section">
        <h3 className="text-bold size-3 ls-0 text-center mb-3 natural-8">
          {t("Получите личный адрес в США прямо сейчас!")}
        </h3>
        <h6 className="size-8 natural-8 text-bold lh-24 text-center mb-32">
          {t(
            "Выбирайте товары в интернет-магазинах США, Европы и Азии. Оформляйте доставку на ваш персональный адрес."
          )}
        </h6>
        <Button className="m-auto natural-3" onClick={() => {}}>
          {t("Подробнее о том, как мы работаем")}
        </Button>
        <div className="video-banner text-center">
          <Banner src="/images/model.png" />
          <Button className="play-btn" onClick={() => {}}>
            <img src="/icons/play.svg" width={20} alt="play" />
          </Button>
        </div>
      </section>
    // </div>
  );
}
