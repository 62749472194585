import { getShops } from "@/api";
import LoaderLayout from "@/components/LoaderLayout";
import { shopCategories } from "@/components/constants";
import Pagination from "@/components/pagination";
import { debounce, removeEmptyValues } from "@/components/utils";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const initialQuery = {
  size: 6,
  page: 1,
  title: "",
  category: "",
};

export default function Shops() {
  const { t } = useTranslation();
  const [result, setResult] = useState<any>({ loading: false, items: [] });
  const [query, setQuery] = useState(initialQuery);

  const setTitle = (e: ChangeEvent<HTMLInputElement>) => {
    debounce(
      () => setQuery({ ...query, title: e.target.value }),
      "search",
      500
    );
  };

  const setFilter = (e: ChangeEvent<HTMLSelectElement>) => {
    setQuery({ ...query, category: e.target.value });
  };

  const onPageChange = (e: any) => {
    setQuery({ ...query, page: e.selected + 1 });
  };

  const getAllShops = async () => {
    setResult({ ...result, loading: true });
    try {
      const { data } = await getShops(removeEmptyValues(query));
      setResult({ ...data, loading: false });
    } catch (error) {
      setResult({ ...result, loading: false });
      console.log(error);
    }
  };

  useEffect(() => {
    getAllShops();
  }, [query]);

  return (
    <div className="shops-page">
      <div className="container">
        <h4 className="size-4 text-bold">{t("Популярные магазины")}</h4>
        <div className="d-flex-center">
          <div className="d-flex-center search w-100">
            <img
              src="/icons/search.svg"
              width={24}
              className="mr-3"
              alt="icon"
            />
            <input
              onChange={setTitle}
              className="form-control text-bold bg-transparent"
              placeholder={t("Европа")}
            />
          </div>
          <div className="d-flex-center">
            <span className="size-8 lh-24 natural-4 text-medium">
              {t("Сортировка")}:
            </span>
            &nbsp;
            <select
              className="form-control text-bold size-8 natural-1"
              onChange={setFilter}
              defaultValue=""
            >
              <optgroup>
                {shopCategories.map((option) => (
                  <option value={option.value} key={option.value}>
                    {t(option.label)}
                  </option>
                ))}
              </optgroup>
            </select>
          </div>
        </div>
        <div className="row results">
          <LoaderLayout
            loading={result.loading}
            style={{ width: "100px", margin: "40px auto" }}
            children={
              result.items.length ? (
                result.items.map((item: any) => (
                  <div className="col-md-4 col-6" key={item.id}>
                    <div className="result-card text-center">
                      <div className="img-box">
                        <img src={item.logo.url} alt="market-logo" />
                      </div>
                      <h6 className="size-6 natural-3 text-bold lh-32 mb-1">
                        {item.title}
                      </h6>
                      <span className="size-9 lh-20 natural-4">
                        {t(item.description)}
                      </span>
                      <a
                        href={item.link}
                        target="_blank"
                        className="d-block size-8 text-bold natural-2"
                      >
                        {t("Начать покупки")}
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <div className="mx-auto mt-4">
                <img
                  width={100}
                  className="d-block m-auto"
                  src="/images/empty.png"
                  alt="empty"
                />
                <p className="m-0 mt-1 text-bold">{t("Ничего не найдено")}</p>
              </div>
              )
            }
          />
        </div>
        {result.meta && (
          <Pagination
            className="mt-5"
            pageCount={Math.ceil(result.meta.total / result.size)}
            onChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
}
