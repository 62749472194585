import Button from "@/components/buttons";
import { Context } from "@/components/utils";
import { SET_NEW_ORDER } from "@/controllers/actions";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function BranchSelector() {
  const { t } = useTranslation();
  const {
    state: { settings, newOrder },
    dispatch,
  } = useContext(Context);

  const branches = settings.branches.filter(
    (branch: BranchType) => branch.country === newOrder.order.country_id
  );

  const handleBack = () => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        prevStep: 0,
        currentStep: 1,
        order: { ...newOrder.order, branch_id: null, country_id: null },
      },
    });
  };

  const onHandleClick = (id: number) => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        prevStep: 2,
        currentStep: 3,
        order: { ...newOrder.order, branch_id: id },
      },
    });
  };

  return (
    <>
      <Button className="size-3 back-btn" onClick={handleBack}>
        <img
          src="/icons/arrow-left.svg"
          className="mr-3"
          width={40}
          alt="back"
        />
        {t("Добавить ожидаемый заказ")}
      </Button>
      <div className="content-body mx-auto">
        <div className="content">
          <h6 className="text-bold size-6">
            <span className="primary-1 text-bold">2. </span>
            {t("Выберите филиал")}
          </h6>
          {branches.map((branch: BranchType) => (
            <Button
              key={branch.id}
              className="selector-btn natural-6-border"
              onClick={() => onHandleClick(branch.id)}
            >
              <div className="image-box rounded-circle d-flex-center justify-content-center">
                <img src="/icons/marker.svg" alt="branch" />
              </div>
              <h6 className="size-6 text-medium natural-2 lh-32 m-0">
                {branch.name}
              </h6>
            </Button>
          ))}
        </div>
      </div>
    </>
  );
}
