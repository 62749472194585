import React from "react";

interface Props {
  register: any;
  name: string;
  errors: any;
  validations?: object;
  label?: string;
  inputClass?: string;
  labelClass?: string;
  parentClass?: string;
}

export default function Checkbox({
  register,
  name,
  validations,
  errors,
  label,
  inputClass = "",
  labelClass = "",
  parentClass = "",
}: Props) {

  return (
    <div className={`custom-checkbox cntr position-relative ${parentClass}`}>
      {label && (
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
      )}
      <input
        type="checkbox"
        id={name}
        {...register(name, validations)}
        className={`hidden-xs-up ${inputClass}`}
      />
      <label htmlFor={name} className={`cbx ${errors[name] ? "error" : ""}`} />
    </div>
  );
}
