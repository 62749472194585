import { ErrorMessage } from "@hookform/error-message";
import React, { ChangeEvent, useState } from "react";
import { debounce } from "../utils";
import { searchProducts } from "@/api/order";
import LoaderLayout from "../LoaderLayout";

interface Props {
  register: any;
  name: string;
  setValue: (name: string, data: any) => void;
  errors?: object;
  watch: (name: string) => any;
  validations?: object;
  placeholder?: string;
  label?: string;
  inputClass?: string;
  labelClass?: string;
  parentClass?: string;
}

export default function SearchProducts({
  register,
  errors,
  name,
  validations,
  watch,
  setValue,
  label,
  placeholder = "",
  inputClass = "",
  labelClass = "",
  parentClass = "",
}: Props) {
  const [localStore, setLocalStore] = useState<{
    product?: ProductType;
    results: ProductType[];
    active: boolean;
  }>({ product: undefined, results: [], active: false });
  const [loading, setLoading] = useState<boolean>(false);
  const record = watch(name);
  const getProducts = async (keyword: string) => {
    setLoading(true);
    try {
      const { data } = await searchProducts(keyword);
      setLocalStore({ ...localStore, results: data.items, active: true });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const search = (e: ChangeEvent<HTMLInputElement>) => {
    debounce(
      () => {
        getProducts(e.target.value);
      },
      "search",
      500
    );
  };

  const selectProduct = (product: any) => {
    setValue(name, {
      id: record?.id,
      product_id: product.id,
      product_name: product.name,
      name: product.name,
      quantity: 1,
      price: product.special_rate,
      weight: null,
      link: null,
      color: null,
      size: null,
    });
    setLocalStore({ ...localStore, product: product, active: false });
  };

  return (
    <div
      className={`custom-input product-search position-relative ${parentClass}`}
    >
      {label && (
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
      )}
      <input
        key={localStore.product?.id}
        type="text"
        {...register(`${name}.product_name`, validations)}
        onChange={search}
        defaultValue={localStore.product?.name}
        className={`form-control ${inputClass}`}
        placeholder={placeholder}
      />
      <LoaderLayout
        loading={loading}
        style={{ width: "24px" }}
        children={<img src="/icons/search.svg" alt="search" />}
      />
      {localStore.active && (
        <div
          className="results"
          onBlur={() => {
            setLocalStore({ ...localStore, active: false });
          }}
        >
          {localStore.results.length ? (
            localStore.results.map((result: ProductType) => (
              <button
                key={result.id}
                className="simple-btn result-btn"
                onClick={() => selectProduct(result)}
              >
                {result.name}
              </button>
            ))
          ) : (
            <img
              width={60}
              className="d-block mx-auto my-4"
              src="/images/empty.png"
              alt="empty"
            />
          )}
        </div>
      )}
      {errors && (
        <span className="primary-3 size-8 error-message mt-1 position-absolute">
          <ErrorMessage errors={errors} name={`${name}.name`} />
        </span>
      )}
    </div>
  );
}
