import React, { useContext, useEffect } from "react";
import PaymentContentSwitcher from "./PaymentContentSwitcher";
import CustomModal from "..";
import { Context } from "@/components/utils";
import { SET_PAYMENT } from "@/controllers/actions";

export default function Payment() {
  const {
    state: { payment },
    dispatch,
  } = useContext(Context);

  const closeModal = () => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        step: 1,
        amount: 0,
        isOpen: false,
      },
    });
  };

  return (
    <CustomModal
      className="balance-modal"
      isOpen={payment.isOpen}
      closeModal={closeModal}
    >
      <PaymentContentSwitcher payment={payment}/>
    </CustomModal>
  );
}
