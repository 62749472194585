import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@/components/buttons";
import { Context } from "@/components/utils";
import { SET_PAYMENT } from "@/controllers/actions";
import { getCurrencyRate, orderPay } from "@/api/profile";
import LoaderLayout from "@/components/LoaderLayout";
import { toast } from "react-toastify";

export default function OrderPayment() {
  const { t } = useTranslation();
  const {
    state: {
      user: { customer },
      payment,
    },
    dispatch,
  } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const amount =
    customer.wallet_amount <= payment.resource.total_price
      ? customer.wallet_amount
      : payment.resource.total_price;

  const closeModal = () => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        step: 1,
        amount: 0,
        isOpen: false,
      },
    });
  };

  const setBalance = () => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        ...payment,
        step: 1,
      },
    });
  };

  const pay = async () => {
    const dataObject = {
      amount: amount,
      resource_type: payment.resourceType,
      resource_id: payment.resource.id,
    };

    setLoading(true);
    try {
      const { data } = await orderPay(dataObject);
      toast.success("Оплата прошла успешно");
      console.log(data);
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getCurrency = async () => {
    setLoader(true);
    try {
      const { data } = await getCurrencyRate();
      dispatch({
        type: SET_PAYMENT,
        payload: { ...payment, currency: data },
      });
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getCurrency();
  }, []);

  return (
    <div className="balance-modal payment-modal">
      <div className="react-modal-header">
        <h5 className="size-5 natural-2 mb-0 text-bold">{t("Оплата")}</h5>
        <Button
          className="modal-close-btn natural-6-border"
          onClick={closeModal}
        >
          <img src="/icons/close.svg" width={24} alt="close" />
        </Button>
      </div>
      <div className="react-modal-body position-relative">
        <div className="balance-content-body d-flex-center justify-content-between">
          <div className="balance-info">
            <h6 className="size-8 natural-8 text-medium mb-1">
              {t("Текущий остаток")}
            </h6>
            <h5 className="size-6 natural-8 text-bold m-0">
              $ {customer.wallet_amount}
            </h5>
          </div>
          <Button
            className="natural-8 natural-6-border size-8"
            onClick={setBalance}
          >
            {t("Пополнить")}&nbsp;
            <span className="d-md-inline d-none text-bold">{t("баланс")}</span>
          </Button>
        </div>
        <div className="d-flex justify-content-between price-info">
          <h4 className="size-6 text-medium m-0">{t("Сумма платежа")}:</h4>
          <h4 className="size-6 text-bold m-0 mx-2 text-nowrap text-right">
            $ {amount ?? 0} <br />
            <span className="size-7 text-bold primary-1 d-flex align-items-center">
              <LoaderLayout
                loading={loader}
                style={{ width: "16px" }}
                children={amount * payment.currency?.Rate}
              />
              &nbsp;UZS
            </span>
          </h4>
        </div>
        <Button
          className="simple-btn submit-btn primary-1-bg w-100 natural-8 size-7 mt-4"
          onClick={pay}
        >
          <LoaderLayout
            style={{ width: "16px" }}
            loading={loading}
            children={<>{t("Платить")}</>}
          />
        </Button>
      </div>
    </div>
  );
}
