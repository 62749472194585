import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { lazy, useEffect, useReducer } from "react";
import Home from "./pages/home";
import "./i18n";
import Layout from "./components/Layout";
import Login from "./pages/login";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "react-tabs/style/react-tabs.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/bootstrap/bootstrap.min.css";
import "@/styles/App.scss";
import SuspenseLoad from "./components/SuspenseLoad";
import ProfileLayout from "./pages/profile";
import NewOrder from "./pages/new-order";
import InitialLoadData from "./InitialLoadData";
import Shops from "./pages/shops";
import Help from "./pages/help";
import Services from "./pages/servicess";
import Contacts from "./pages/contacts";
import UserTrackings from "./pages/profile/trackings/UserTrackings";
import UserShopOrders from "./pages/profile/shops/UserShopOrders";
import UserOrders from "./pages/profile/orders/UserOrders";
import UserParcels from "./pages/profile/UserParcels";
import ShopOrder from "./pages/profile/shops/ShopOrder";
import TrackingOrder from "./pages/profile/trackings/TrackingOrder";
import { ToastContainer } from "react-toastify";
import reducer, { loadState, saveState } from "./controllers/reducer";
import { Context } from "./components/utils";
import Rates from "./pages/rates";
import UserOrder from "@/pages/profile/orders/Order";
import UserCabinetMain from "@/pages/profile/UserCabinetMain";
import UserBalance from "@/pages/profile/UserBalance";
import UserAddresses from "./pages/profile/UserAddresses";
import UserSettings from "./pages/profile/UserSettings";

function App() {
  const [state, dispatch] = useReducer(reducer, loadState());

  useEffect(() => {
    saveState(state);
  }, [state]);

  return (
    <Context.Provider value={{ state, dispatch }}>
      <Router basename="/">
        <InitialLoadData>
          <ToastContainer />
          <Layout>
            <Routes>
              <Route path="/" Component={Home} />
              <Route path="/login" Component={Login} />
              <Route path="/new-order" Component={NewOrder} />
              <Route path="/shops" Component={Shops} />
              <Route path="/rates" Component={Rates} />
              <Route path="/help" Component={Help} />
              <Route path="/services" Component={Services} />
              <Route path="/contacts" Component={Contacts} />
              <Route path="*" element={<ProfileLayout />}>
                <Route
                  path="profile"
                  element={
                    <SuspenseLoad>
                      <UserCabinetMain />
                    </SuspenseLoad>
                  }
                />
                <Route path="parcels" element={<UserParcels />}>
                  <Route
                    path="orders"
                    element={
                      <SuspenseLoad>
                        <UserOrders />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="trackings"
                    element={
                      <SuspenseLoad>
                        <UserTrackings />
                      </SuspenseLoad>
                    }
                  />
                  <Route
                    path="shop-orders"
                    element={
                      <SuspenseLoad>
                        <UserShopOrders />
                      </SuspenseLoad>
                    }
                  />
                </Route>
                <Route
                  path="parcels/orders/:id"
                  element={
                    <SuspenseLoad>
                      <UserOrder />
                    </SuspenseLoad>
                  }
                />
                <Route
                  path="parcels/shop-orders/:id"
                  element={
                    <SuspenseLoad>
                      <ShopOrder />
                    </SuspenseLoad>
                  }
                />
                <Route
                  path="parcels/trackings/:id"
                  element={
                    <SuspenseLoad>
                      <TrackingOrder />
                    </SuspenseLoad>
                  }
                />
                <Route
                  path="addresses"
                  element={
                    <SuspenseLoad>
                      <UserAddresses />
                    </SuspenseLoad>
                  }
                />
                <Route
                  path="balance"
                  element={
                    <SuspenseLoad>
                      <UserBalance />
                    </SuspenseLoad>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <SuspenseLoad>
                      <UserSettings />
                    </SuspenseLoad>
                  }
                />
              </Route>
            </Routes>
          </Layout>
        </InitialLoadData>
      </Router>
    </Context.Provider>
  );
}

export default App;
