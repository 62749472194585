import { Outlet } from "react-router-dom";
import PagePagination from "@/components/page-navigation";
import { useTranslation } from "react-i18next";
import ProfileRouter from "./ProfileRouter";
import ProfileMobileNavbar from "./ProfileMobileNavbar";
import { useContext, useEffect, useState } from "react";
import { getUserInfo } from "@/api/profile";
import LoaderLayout from "@/components/LoaderLayout";
import { Context } from "@/components/utils";
import { SET_USER } from "@/controllers/actions";

const links = [
  {
    to: "/",
    text: "Главная",
    className: "natural-4 text-bold size-8",
  },
  {
    to: "/profile",
    text: "Мой кабинет",
    className: "natural-2 text-bold size-8",
  },
];

export default function ProfileLayout() {
  const { t } = useTranslation();
  const {
    state: { user },
    dispatch,
  } = useContext(Context);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);

  const getUser = async () => {
    try {
      const { data } = await getUserInfo();
      dispatch({ type: SET_USER, payload: { ...data, access_token: token } });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="profile-page">
      <div className="d-md-block d-none">
        <PagePagination backText={t("На главную")} links={links} />
      </div>
      <LoaderLayout
        loading={loading}
        style={{ width: "50px", margin: "60px auto" }}
        children={
          user && (
            <div className="container">
              <div className="row profile-body">
                <div className="col-lg-3 d-md-block d-none">
                  <ProfileRouter />
                </div>
                <div className="col-lg-9 col-12">
                  <Outlet />
                </div>
                <div className="d-md-none d-block">
                  <ProfileMobileNavbar />
                </div>
              </div>
            </div>
          )
        }
      />
    </div>
  );
}
