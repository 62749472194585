import React from "react";
import AuthMediaSwitcher from "./AuthMediaSwitcher";

export default function AuthMedia() {
  return (
    <div className="auth-media">
      <AuthMediaSwitcher />
    </div>
  );
}
