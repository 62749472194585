import React from "react";
import { Link } from "react-router-dom";

interface Porps {
  to: string;
  className: string;
  children: any;
}

export default function LinkButton({ to, className, children }: Porps) {
  return (
    <Link to={to} className={`link-btn ${className}`}>
      {children}
    </Link>
  );
}
