import { getBranches } from "@/api";
import LoaderLayout from "@/components/LoaderLayout";
import Button from "@/components/buttons";
import PagePagination from "@/components/page-navigation";
import { Context } from "@/components/utils";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const links = [
  {
    to: "/",
    text: "Главная",
    className: "natural-4 text-bold size-8",
  },
  {
    to: "/contacts",
    text: "Контакты",
    className: "natural-2 text-bold size-8",
  },
];

export default function Contacts() {
  const { t } = useTranslation();
  const countries = useContext(Context).state.settings.countries;
  const [branches, setBranches] = useState([]);
  const [active, setActive] = useState<{
    country: any;
    branch: any;
    loading: boolean;
  }>({
    country: countries[0],
    branch: null,
    loading: true,
  });

  const getAllBranches = async () => {
    try {
      const { data } = await getBranches();
      setBranches(data.items);
      setActive({
        ...active,
        branch: data.items.filter(
          (b: any) => b.country === active.country.id
        )[0],
        loading: false,
      });
    } catch (error) {
      console.log(error);
      setActive({ ...active, loading: false });
    }
  };

  useEffect(() => {
    getAllBranches();
  }, []);

  useEffect(() => {
    setActive({
      ...active,
      branch: branches.filter((b: any) => b.country === active.country.id)[0],
    });
  }, [active.country]);

  return (
    <div className="contacts-page">
      <PagePagination backText={t("Back")} links={links} />
      <div className="page-body">
        <div className="container">
          <LoaderLayout
            style={{ width: "100px", margin: "0 auto" }}
            loading={active.loading}
            children={
              <div className="row">
                <div className="col-md-5">
                  <div className="info">
                    <div className="d-flex-center tabs">
                      {countries.map((loc: CountryType) => (
                        <Button
                          key={loc.id}
                          className={`size-8 text-nowrap ${
                            active.country.id === loc.id
                              ? "primary-1-bg natural-8"
                              : "natural-4"
                          }`}
                          onClick={() => setActive({ ...active, country: loc })}
                        >
                          {loc.name}
                        </Button>
                      ))}
                    </div>
                    <div className="d-flex-center tabs my-4">
                      {branches
                        .filter((b: any) => b.country === active.country.id)
                        .map((loc: BranchType) => (
                          <Button
                            key={loc.id}
                            className={`size-8 text-nowrap ${
                              active.branch?.id === loc.id
                                ? "primary-1-bg natural-8"
                                : "natural-4"
                            }`}
                            onClick={() =>
                              setActive({ ...active, branch: loc })
                            }
                          >
                            {loc.name}
                          </Button>
                        ))}
                    </div>
                    <h2 className="size-2 text-bold mb-3">
                      {t(active.branch?.name)}
                    </h2>
                    <div className="address-card-header d-flex-center mb-3">
                      <img src="/icons/marker.png" width={64} alt="location" />
                      <div className="header-info ml-3">
                        <h4 className="size-7 primary-1 text-bold mb-1">
                          {t("Адрес отправителя")}
                        </h4>
                        <h4 className="size-7 natural-2 text-bold m-0">
                          {active.country.name}, {active.branch?.name},{" "}
                          {active.branch?.address_line}
                        </h4>
                      </div>
                    </div>
                    <hr />
                    {active.branch?.phone && (
                      <>
                        <h5 className="size-7 lh-24 primary-1 text-medium mb-1">
                          {t("Phone number")}
                        </h5>
                        <a
                          href={`tel:${active.branch?.phone}`}
                          target="_blank"
                          className="size-7 lh-24 natural-2 text-bold mb-0"
                        >
                          +{active.branch?.phone}
                        </a>
                      </>
                    )}
                    {active.branch?.working_time && (
                      <>
                        <h5 className="size-7 lh-24 primary-1 text-medium mb-1">
                          {t("Рабочие дни")}
                        </h5>
                        <h6 className="size-7 lh-24 natural-2 text-bold mb-0">
                          {t("с понедельника по пятницу")}
                          <br />
                          {t("Мы работаем с")} {active.branch?.working_time.min}{" "}
                          {t("до")} {active.branch?.working_time.max}
                        </h6>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-7 d-flex-center">
                  {active.branch?.coordination ? (
                    <div className="map w-100">
                      <Map
                        height={800}
                        center={[
                          +active.branch?.coordination.latitude,
                          +active.branch?.coordination.longitude,
                        ]}
                        defaultZoom={16}
                      >
                        <ZoomControl />
                        <Marker
                          width={50}
                          color="#56698F"
                          anchor={[
                            +active.branch?.coordination.latitude,
                            +active.branch?.coordination.longitude,
                          ]}
                        />
                      </Map>
                    </div>
                  ) : (
                    <h4 className="m-auto w-50 text-center text-medium">
                      <img
                        className="d-block mx-auto mb-1"
                        width={50}
                        src="/icons/marker.svg"
                        alt="marker"
                      />
                      {t("Координаты будут добавлены в ближайшее время.")}
                    </h4>
                  )}
                </div>
              </div>
            }
          />
        </div>
      </div>
      <div className="container">
        <div className="size-9 natural-4 lh-20 ws-pw question">
          {t(
            "Хотите быстро получить ответ на свой вопрос?\nИзучите раздел сайта Вопросы-ответы, в котором мы собрали ответы на самые популярные вопросы.\n\nЕсли ваш вопрос связан с тем, когда внесут входящую посылку в систему, то напоминаем, что на внесение груза нам нужно 1-2 рабочих дня, в редких случаях чуть больше. Советуем писать по этому вопросу только если груз не внесен более 3-х дней."
          )}
        </div>
      </div>
    </div>
  );
}
