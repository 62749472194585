import React, { useContext } from "react";
import ContentHeader from "./ContentHeader";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons";
import FAQ from "./FAQ";
import Payment from "@/components/modals/payment";
import { Context } from "@/components/utils";
import { SET_PAYMENT } from "@/controllers/actions";

const payments = [
  {
    image: "/images/humo.png",
  },
  {
    image: "/images/uzcard.png",
  },
  {
    image: "/images/visa.png",
  },
  {
    image: "/images/mastercard.png",
  },
];

export default function UserBalance() {
  const { t } = useTranslation();
  const {
    state: { user, payment },
    dispatch,
  } = useContext(Context);

  const openPayment = () => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        isOpen: true,
        paymentMethod: "stripe",
      },
    });
  };

  return (
    <div className="balance-content">
      <ContentHeader title="Мой баланс" />
      <div className="balance-content-body d-flex-center justify-content-between">
        <div className="balance-info">
          <h6 className="size-8 natural-8 text-medium mb-1">
            {t("Текущий остаток")}
          </h6>
          <h5 className="size-6 natural-8 text-bold m-0">
            $ {user.customer.wallet_amount ?? 0}
          </h5>
        </div>
        <Button className="natural-8 size-8" onClick={openPayment}>
          {t("Пополнить баланс")}
        </Button>
      </div>
      <h5 className="history-title size-7 natural-2 text-bold mb-0">
        {t("Последние действия")}
      </h5>
      <div className="balance-content-history d-flex-center">
        {payments.map((payment, i) => (
          <div className="payment px-3" key={i}>
            <img src={payment.image} alt="payment" />
          </div>
        ))}
      </div>
      <hr className="styled-hr" />
      <FAQ />
      {console.log(payment)}
      {payment.isOpen && <Payment />}
    </div>
  );
}
