import React from "react";
import { Link } from "react-router-dom";
interface Props {
  width: string;
  height: string;
}

export default function Logo({ width, height }: Props) {
  return (
    <Link to="/" className="logo" style={{ width: width, height: height }}>
      <img src="/images/fgf-logo.png" alt="logo" width="100%" height="100%" />
    </Link>
  );
}
