import { getAddresses } from "@/api/profile";
import Button from "@/components/buttons";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Context,
  makeLoopIds,
  margeLimitAddress,
  orderStepNavigator,
} from "@/components/utils";
import Address from "@/components/modals/Address";
import { getAddressesLimits } from "@/api/address";
import Pagination from "@/components/pagination";
import LoaderLayout from "@/components/LoaderLayout";
import PickUp from "./PickUp";
import { SET_NEW_ORDER } from "@/controllers/actions";

const initialQuery = {
  size: 10,
  page: 1,
  items: [],
};

export default function AddressSelector() {
  const { t } = useTranslation();
  const {
    state: { newOrder },
    dispatch,
  } = useContext(Context);
  const [adresses, setAdresses] = useState<any>(initialQuery);
  const isSender = newOrder.currentStep === 7;
  const address_field = isSender ? "sender_address" : "recipient_address";
  const [modal, setModal] = useState<{ isOpen: boolean; loading?: boolean }>({
    isOpen: false,
    loading: false,
  });
  const currentStep =
    isSender && newOrder.prevStep === 6
      ? 11
      : orderStepNavigator(newOrder.currentStep, newOrder.order.order_type);

  const handleBack = () => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        prevStep:
          !isSender && newOrder.order.order_type === 6
            ? 7
            : !isSender && newOrder.order.order_type !== 6
            ? 3
            : newOrder.order.order_type,
        currentStep:
          !isSender && newOrder.order.order_type === 6
            ? 11
            : newOrder.order.order_type,
        order: {
          ...newOrder.order,
          [address_field]: null,
        },
      },
    });
  };

  const onPageChange = (e: any) => {
    setAdresses({ ...adresses, page: e.selected + 1 });
  };

  const setAddress = (address: object) => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        ...newOrder,
        order: {
          ...newOrder.order,
          [address_field]: address,
        },
      },
    });
  };

  const handleClick = () => {
    const pickupBtn = document.getElementById("pickup-submit");
    if (pickupBtn) {
      return pickupBtn.click();
    }
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        ...newOrder,
        prevStep: newOrder.currentStep,
        currentStep: currentStep,
      },
    });
  };

  const getUserAddresses = async () => {
    setModal({ ...modal, loading: true });
    try {
      const { data } = await getAddresses({
        page: adresses.page,
        size: adresses.size,
        country: isSender
          ? newOrder.order.country_id
          : newOrder.order.country_id_2,
      });
      const { data: limits } = await getAddressesLimits(
        makeLoopIds(
          data.items.map((item: any) => item.customer),
          "customers"
        )
      );
      const items = margeLimitAddress(data.items, limits, newOrder.order);
      setAdresses({ ...adresses, items, meta: data.meta });
    } catch (error) {
      console.log(error);
    }
    setModal({ ...modal, loading: false });
  };

  useEffect(() => {
    getUserAddresses();
  }, [adresses.page]);

  return (
    <>
      <Button className="size-3 back-btn" onClick={handleBack}>
        <img
          src="/icons/arrow-left.svg"
          className="mr-3"
          width={40}
          alt="back"
        />
        {t("Добавить ожидаемый заказ")}
      </Button>
      <div className="content-body mx-auto">
        <div className="content">
          <h6 className="text-bold size-6 lh-32">
            <span className="primary-1 text-bold">5. </span>
            {t(isSender ? "Выбор отправителя" : "Выбор получателя")}
          </h6>
          <h6 className="size-8 natural-4 lh-20 m-0">
            {t(
              "Вы можете выбрать адрес получателя из сохранившехся адресов, либо добавить новый адрес"
            )}
          </h6>
          <hr className="my-4" />
          <div className="row address-list" key={newOrder.currentStep}>
            <LoaderLayout
              loading={modal.loading ?? false}
              style={{ width: "50px", margin: "16px auto 40px" }}
              children={
                adresses.items.length ? (
                  adresses.items.map((address: any) => (
                    <div className="col-md-6" key={address.id}>
                      <div
                        onClick={() => setAddress(address)}
                        className={`address-card box-shadow ${
                          address.id === newOrder.order[address_field]?.id
                            ? "active"
                            : ""
                        }`}
                      >
                        <div className="address-card-header d-flex-center mb-3">
                          <img
                            src="/icons/marker.png"
                            width={64}
                            alt="location"
                          />
                          <div className="header-info ml-3">
                            <h4 className="size-7 primary-1 text-bold mb-1">
                              {t("Адрес отправителя")}
                            </h4>
                            <h4 className="size-7 natural-2 text-bold m-0">
                              {t(address.country.name)},{" "}
                              {address.region ? address.region.name + ", " : ""}
                              {address.first_address}
                            </h4>
                          </div>
                        </div>
                        <hr />
                        <div className="address-card-body">
                          <h5 className="size-7 primary-1 text-medium mb-1">
                            {t("Firstname")}
                          </h5>
                          <h4 className="size-7 natural-2 text-bold mb-0">
                            {address.customer.first_name}{" "}
                            {address.customer.last_name ?? ""}
                          </h4>
                          <h5 className="size-7 primary-1 text-medium mb-1">
                            {t("Telephone")}
                          </h5>
                          <h4 className="size-7 natural-2 text-bold m-0">
                            +{address.customer.phones[0]}
                          </h4>
                        </div>
                        {!isSender && newOrder.order.country_id_2 === 2 && (
                          <div
                            className={`address-card-footer d-flex-center justify-content-between ${
                              address.limit > 900 ? "over" : ""
                            }`}
                          >
                            <span className="size-8 text-medium primary-4">
                              {t("Таможенный лимит")}
                            </span>
                            <span className="size-8 text-medium primary-4">
                              $ {address.limit}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="m-auto">
                    <img
                      width={100}
                      className="d-block m-auto"
                      src="/images/empty.png"
                      alt="empty"
                    />
                    <p className="m-0 mt-1 text-bold">
                      {t("Ничего не найдено")}
                    </p>
                  </div>
                )
              }
            />
          </div>
          {adresses.meta && (
            <Pagination
              className="mb-4"
              pageCount={Math.ceil(adresses.meta.total / adresses.size)}
              onChange={onPageChange}
            />
          )}
          <Button
            className="add-button size-8 w-100 primary-1 primary-1-border"
            onClick={() => setModal({ isOpen: true })}
          >
            {t("Добавить нового получателя")}
          </Button>
          <hr className="my-4" />
          {newOrder.order.order_type === 6 && (
            <PickUp
              addressField={address_field}
              currentStep={currentStep}
            />
          )}
          <Button
            className="submit-btn size-7 w-100 natural-8 primary-1-bg"
            onClick={handleClick}
          >
            {t("Продолжить")}
            <img src="/icons/star.svg" width={16} alt="star" />
          </Button>
        </div>
      </div>
      {modal.isOpen && (
        <Address
          modal={modal}
          setAddresses={setAddress}
          addresses={adresses}
          setModal={setModal}
        />
      )}
    </>
  );
}
