export const SET_USER = "SET_USER";

export const SET_SETTINGS = "SET_SETTINGS";

export const SET_AUTH_STEP = "SET_AUTH_STEP";

export const SET_NEW_ORDER = "SET_NEW_ORDER";

export const SET_CONFIRM = "SET_CONFIRM";

export const SET_PAYMENT = "SET_PAYMENT";