import { pickups } from "@/components/constants";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PickupTime from "./PickupTime";
import { Context } from "@/components/utils";
import { SET_NEW_ORDER } from "@/controllers/actions";

interface Props {
  addressField: string;
  currentStep: number;
}

export default function PickUp({ addressField, currentStep }: Props) {
  const { t } = useTranslation();
  const {
    state: { newOrder },
    dispatch,
  } = useContext(Context);
  const country = newOrder.order[addressField]?.country;
  const isPickup = newOrder.order[addressField]?.isPickup;
  const pickupType = newOrder.order[addressField]?.pickupType?.type;

  const handlePickup = (p: PickupType) => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        ...newOrder,
        order: {
          ...newOrder.order,
          [addressField]: {
            ...newOrder.order[addressField],
            pickupType: p,
            delivery: true,
          },
        },
        pickup:
          addressField === "sender_address" && p.type !== 1
            ? null
            : newOrder.order.pickup,
      },
    });
  };

  const setPickup = () => {
    if (isPickup) {
      dispatch({
        type: SET_NEW_ORDER,
        payload: {
          ...newOrder,
          order: {
            ...newOrder.order,
            [addressField]: {
              ...newOrder.order[addressField],
              isPickup: !isPickup,
              pickupType: null,
            },
          },
        },
      });
    } else {
      dispatch({
        type: SET_NEW_ORDER,
        payload: {
          ...newOrder,
          order: {
            ...newOrder.order,
            [addressField]: {
              ...newOrder.order[addressField],
              isPickup: !isPickup,
            },
          },
        },
      });
    }
  };

  return newOrder.order[addressField] ? (
    <div className="pick-content mb-32">
      <div className="custom-checkbox cntr position-relative d-flex-center flex-row-reverse justify-content-end">
        <label
          htmlFor="pickup"
          className="m-0 natural-1 size-6 lh-32 text-bold pointer"
        >
          {t(
            `${
              addressField === "sender_address" ? "Забрать" : "Доставьте"
            } посылку самому (${
              addressField === "sender_address" ? "Самовывоз" : "Доставка"
            } ${newOrder.order[addressField].region.name})`
          )}
        </label>
        <input
          type="checkbox"
          checked={isPickup ? true : false}
          id="pickup"
          className="hidden-xs-up w-auto"
          onChange={() => setPickup()}
        />
        <label htmlFor="pickup" className="cbx" />
      </div>
      {isPickup && (
        <div className="d-flex-center">
          <span className="size-7 text-bold lh-24 natural-2">
            {t("Выберите службу доставки")}
          </span>
          <div className="pickup-list d-flex-center">
            {pickups.map((pickUp: PickupType) => (
              <button
                key={pickUp.id}
                onClick={() => handlePickup(pickUp)}
                className={`pickup flex-grow text-center bg-transparent 
                ${pickupType === pickUp.type ? `active` : ``}`}
              >
                <img src={pickUp.logo} alt="logo" />
                <h6 className="primary-1 size-8 text-bold lh-24 m-0 mt-1">
                  {pickUp.name}
                </h6>
              </button>
            ))}
          </div>
        </div>
      )}
      {
        pickupType &&
          addressField === "sender_address" &&
          (pickupType === 1 ? (
            <PickupTime currentStep={currentStep} countryId={country.id} />
          ) : (
            <h4 className="text-center mt-4 w-100 text-bold">{t("Soon")}</h4>
          ))
        // <DeliveryService pickup={pickup.pickup} newOrder={newOrder}/>
      }
      <hr className="my-32" />
    </div>
  ) : null;
}
