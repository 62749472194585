import {
  createReceiver,
  getReceiverByIds,
  getUserReceivers,
  updateReceiver,
} from "@/api/profile";
import React, { useEffect, useState } from "react";
import Button from "../buttons";
import Input from "../form-components";
import { emailValidation } from "@/components/constants";
import { doc_types } from "../constants";
import Select from "../form-components/Select";
import { useTranslation } from "react-i18next";
import FileUpload from "../form-components/FileUpload";
import { filterDocuments, makeLoopIds } from "../utils";
import LoaderLayout from "@/components/LoaderLayout";
import { useFieldArray, useForm } from "react-hook-form";
import CustomModal from ".";
import { toast } from "react-toastify";

interface Props {
  modal: any;
  setModal: (data: any) => void;
}

export default function Receivers({ modal, setModal }: Props) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: modal.customer });
  const [loading, setLoading] = useState(false);
  const [localStore, setLocalStore] = useState<{
    active: boolean;
    receivers: any[];
    receiver: any;
  }>({
    active: modal.customer,
    receivers: [],
    receiver: {},
  });
  const { fields, remove, append } = useFieldArray({
    control,
    name: "customer.documents",
    keyName: "_id",
  });

  const setReceiver = (receiver: any) => {
    setModal({ ...modal, customer: receiver, notSelf: false });
  };

  const newReceiver = () => {
    setLocalStore({ ...localStore, active: true });
  };

  const getReceivers = async () => {
    setLoading(true);
    try {
      const { data } = await getUserReceivers();
      setLocalStore({ ...localStore, receivers: data });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getReceiver = async () => {
    setLoading(true);
    try {
      const { data: receiver } = await getReceiverByIds(
        makeLoopIds([modal.customer], "id")
      );
      append(receiver[0].documents);
      setValue("customer", receiver[0]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const submit = async (values: any) => {
    let receiver;
    values.customer.phones[0] = values.customer.phones[0].replace(/\D/g, "");
    try {
      if (values.id) {
        values.customer._method = "PUT";
        values.customer.documents = filterDocuments(values.customer.documents);
        receiver = await updateReceiver(values.customer);
      } else {
        receiver = await createReceiver(values.customer);
      }
      setModal({ ...modal, customer: receiver.data, notSelf: false });
      toast.success("Receiver updated successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    if (modal.customer) {
      setModal({ ...modal, notSelf: false });
    } else setLocalStore({ ...localStore, active: false });
  };

  useEffect(() => {
    if (localStore.active) {
      getReceiver();
    } else getReceivers();
  }, []);

  return (
    <CustomModal
      className="new-address-modal"
      isOpen={modal.notSelf}
      closeModal={closeModal}
    >
      <div className="react-modal-body">
        <div className="form">
          <LoaderLayout
            loading={loading}
            style={{ width: "40px", margin: "auto" }}
            children={
              localStore.active ? (
                <>
                  <div className="react-modal-header mb-3 justify-content-between">
                    <div>
                      <h5 className="size-5 natural-2 mb-1 text-bold">
                        {t(
                          modal.customer
                            ? "Обновить получателя"
                            : `Добавить новый получателя`
                        )}
                      </h5>
                    </div>
                    <div>
                      <Button
                        className="modal-close-btn natural-6-border"
                        onClick={closeModal}
                      >
                        <img src="/icons/close.svg" width={24} alt="close" />
                      </Button>
                    </div>
                  </div>
                  <div className="d-flex-center gap-20">
                    <Input
                      parentClass="d-flex flex-column flex-grow"
                      register={register}
                      name="customer.first_name"
                      errors={errors}
                      label={t("Имя *")}
                      validations={{ required: t("This is required.") }}
                      placeholder={t("Введите имя")}
                      inputClass="natural-6-border text-bold"
                      labelClass="size-7 natural-5 text-medium m-0"
                    />
                    <Input
                      parentClass="d-flex flex-column flex-grow"
                      register={register}
                      name="customer.last_name"
                      errors={errors}
                      validations={{ required: t("This is required.") }}
                      label={t("Фамилия *")}
                      inputClass="natural-6-border text-bold"
                      placeholder={t("Введите фамилия")}
                      labelClass="size-7 natural-5 text-medium m-0"
                    />
                  </div>
                  <Input
                    parentClass="d-flex flex-column"
                    register={register}
                    name="customer.birth_date"
                    type="date"
                    errors={errors}
                    label={t("Дата рождения")}
                    inputClass="natural-6-border text-bold"
                    placeholder={t("день.месяц.год")}
                    labelClass="size-7 natural-5 text-medium m-0"
                  />
                  <div className="d-flex-center gap-20">
                    <Input
                      parentClass="d-flex flex-column w-50"
                      register={register}
                      name="customer.phones[0]"
                      errors={errors}
                      label={t("Номер телефона *")}
                      validations={{
                        required: t("This is required."),
                      }}
                      placeholder="+998"
                      inputClass="natural-6-border text-bold"
                      labelClass="size-7 natural-5 text-medium m-0"
                    />
                    <Input
                      parentClass="d-flex flex-column flex-grow"
                      register={register}
                      name="customer.email"
                      errors={errors}
                      validations={{
                        required: t("This is required."),
                        ...emailValidation,
                      }}
                      label={t("Электронный адрес *")}
                      inputClass="natural-6-border text-bold"
                      placeholder={t("Введите электронный адрес")}
                      labelClass="size-7 natural-5 text-medium m-0"
                    />
                  </div>
                  <div className="form-documents">
                    {fields.map((_, i) => {
                      return (
                        <div className="doc-info" key={i}>
                          <Button
                            className="delete-btn"
                            onClick={() => remove(i)}
                          >
                            <img src="/icons/delete.svg" alt="delete" />
                          </Button>
                          <div className="row align-items-end">
                            <div className="col-md-4">
                              <Select
                                register={register}
                                options={doc_types}
                                name={`customer.documents[${i}].type`}
                                label="Тип документа будет *"
                                labelClass="size-7 natural-5 text-medium m-0"
                                inputClass="natural-6-border text-bold pointer"
                              />
                            </div>
                            <div className="col-md-8 pl-md-0">
                              <Input
                                parentClass="d-flex flex-column"
                                register={register}
                                name={`customer.documents[${i}].number`}
                                type="text"
                                validations={{
                                  required: t("This is required."),
                                }}
                                errors={errors}
                                inputClass="natural-6-border text-bold"
                                placeholder={t("Серия и номер “Грин-карта”")}
                              />
                            </div>
                          </div>
                          <div className="d-flex-center upload-area">
                            <FileUpload
                              register={register}
                              validations={{
                                required: true,
                                maxLength: 2,
                              }}
                              errors={errors}
                              getValues={getValues}
                              setValue={setValue}
                              parentClass="h-100 flex-1"
                              label="JPG, PNG"
                              name={`customer.documents[${i}].file`}
                              labelClass="m-0 size-8 natural-4 text-bold"
                              inputClass="natural-6-border text-bold"
                            />
                            <FileUpload
                              register={register}
                              errors={errors}
                              getValues={getValues}
                              setValue={setValue}
                              parentClass="h-100 flex-1"
                              label="JPG, PNG"
                              name={`customer.documents[${i}].backFile`}
                              labelClass="m-0 size-8 natural-4 text-bold"
                              inputClass="natural-6-border text-bold"
                            />
                          </div>
                          <h6 className="size-8 natural-5 text-medium mt-1">
                            {t("Прикрепите фотографию с 2 сторон документа")}
                          </h6>
                          <hr className="mb-4" />
                        </div>
                      );
                    })}
                    {fields.length < 8 && (
                      <Button
                        className="add-button size-8 primary-1 primary-1-border"
                        onClick={() => append({})}
                      >
                        {t("Добавить документ")}
                      </Button>
                    )}
                  </div>
                  <Button
                    className="w-100 mt-3 natural-6-border"
                    onClick={handleSubmit(submit)}
                  >
                    {t(modal.customer ? "Обновить" : "Добавить")}
                  </Button>
                </>
              ) : (
                <>
                  <div className="d-flex-center justify-content-between">
                    <h6 className="size-6 text-bold m-0">
                      {t("Выберите получателя")}
                    </h6>
                    <Button
                      className="size-8 w-auto primary-1 primary-1-border"
                      onClick={newReceiver}
                    >
                      {t("+ Добавить")}
                    </Button>
                  </div>
                  {localStore.receivers.map((receiver) => (
                    <Button
                      key={receiver.id}
                      className={`rec-btn mt-3 natural-6-border w-100 ${
                        localStore.receiver.id === receiver.id ? "active" : ""
                      }`}
                      onClick={() => setReceiver(receiver)}
                    >
                      {receiver.first_name} {receiver.last_name}
                    </Button>
                  ))}

                  <Button
                    className="w-100 mt-3 natural-6-border"
                    onClick={() => setModal({ ...modal, notSelf: false })}
                  >
                    {t("Отмена")}
                  </Button>
                </>
              )
            }
          />
        </div>
      </div>
    </CustomModal>
  );
}
