import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons";

interface Props {
  setAddress?: (data: any) => void;
  address: any;
  isSender?: boolean;
}

export default function AddressCard({
  setAddress,
  address,
  isSender = true,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="address-card box-shadow">
      <div className="address-card-header d-flex-center mb-3">
        <img src="/icons/marker.png" width={64} alt="location" />
        <div className="header-info ml-3">
          <h4 className="size-7 primary-1 text-bold mb-1">
            {t(isSender ? "Адрес отправителя" : "Адрес получателя")}
          </h4>
          <h4 className="size-7 natural-2 text-bold m-0">
            {t(address.country.name)},{" "}
            {address.region ? address.region.name + ", " : ""}
            {address.first_address}
          </h4>
        </div>
      </div>
      <hr />
      <div className="address-card-body">
        <h5 className="size-7 primary-1 text-medium mb-1">{t("Firstname")}</h5>
        <h4 className="size-7 natural-2 text-bold mb-0">
          {address.customer.first_name} {address.customer.last_name ?? ""}
        </h4>
        <h5 className="size-7 primary-1 text-medium mb-1">{t("Telephone")}</h5>
        <h4 className="size-7 natural-2 text-bold m-0">
          +{address.customer.phones[0]}
        </h4>
      </div>
      {setAddress && (
        <Button
          onClick={() => setAddress(address)}
          className="edit-btn natural-6-border primary-1 size-8 w-100 mt-3"
        >
          {t("Редактировать")}
        </Button>
      )}
    </div>
  );
}
