import { ErrorMessage } from "@hookform/error-message";
import React, { useState } from "react";

interface Props {
  register: any;
  name: string;
  errors?: object;
  validations?: object;
  label?: string;
  inputClass?: string;
  labelClass?: string;
  parentClass?: string;
}

export default function PasswordInput({
  register,
  name,
  errors,
  validations,
  label,
  inputClass = "",
  labelClass = "",
  parentClass = "",
}: Props) {
  const [type, setType] = useState(false);

  return (
    <div className={`custom-input position-relative ${parentClass}`}>
      {label && (
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
      )}
      <div className="password-input position-relative">
        <input
          type={type ? "text" : "password"}
          {...register(name, validations)}
          className={`form-control ${inputClass}`}
          placeholder="···"
        />
        <button
          className="simple-btn position-absolute"
          onClick={(e) => {
            e.preventDefault();
            setType(!type);
          }}
        >
          <img
            width={24}
            src={`/icons/eye${type ? "-open" : ""}.svg`}
            alt="eye"
          />
        </button>
      </div>
      {errors && (
        <span className="primary-3 size-8 error-message mt-1 position-absolute">
          <ErrorMessage errors={errors} name={name} />
        </span>
      )}
    </div>
  );
}
