import Button from "@/components/buttons";
import { Context } from "@/components/utils";
import { SET_PAYMENT } from "@/controllers/actions";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function PaymentMethod() {
  const { t } = useTranslation();
  const {
    state: { payment },
    dispatch,
  } = useContext(Context);

  const closeModal = () => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        step: 1,
        amount: 0,
        isOpen: false,
      },
    });
  };

  const back = () => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        ...payment,
        step: 1,
      },
    });
  };

  const setMethod = (method: string) => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        ...payment,
        paymentMethod: method,
      },
    });
  };

  const submit = () => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        ...payment,
        step: payment.paymentMethod === "stripe" ? 3 : 4,
      },
    });
  };

  return (
    <div className="balance-modal">
      <div className="react-modal-header">
        <button
          className="back-btn bg-transparent border-0 p-0 d-flex-center gap-10 size-5 text-bold"
          onClick={back}
        >
          <img src="/icons/arrow-left.svg" width={24} alt="arrow" />
          {t("Выберите метод оплаты")}
        </button>

        <Button
          className="modal-close-btn natural-6-border"
          onClick={closeModal}
        >
          <img src="/icons/close.svg" width={24} alt="close" />
        </Button>
      </div>
      <div className="react-modal-body position-relative">
        <div className="paymet-methods">
          <Button
            className={`w-100 natural-8 size-7 mt-5 ${
              payment.paymentMethod === "stripe"
                ? "primary-1-border"
                : "natural-6-border"
            }`}
            onClick={() => setMethod("stripe")}
          >
            <span className="rounded-circle natural-6-border">
              <span className="d-block rounded-circle" />
            </span>
            <div className="d-flex-center gap-10">
              <img width={40} src="/images/visa.png" alt="visa" />
              <img width={40} src="/images/mastercard.png" alt="mastercard" />
            </div>
          </Button>
          <Button
            className={`w-100 natural-8 size-7 mt-3 ${
              payment.paymentMethod === "payme"
                ? "primary-1-border"
                : "natural-6-border"
            }`}
            onClick={() => setMethod("payme")}
          >
            <span className="rounded-circle natural-6-border">
              <span className="d-block rounded-circle" />
            </span>
            <div className="d-flex-center gap-10">
              <img width={30} src="/images/payme.svg" alt="payme" />
              <img width={40} src="/images/uzcard.png" alt="uzcard" />
              <img width={40} src="/images/humo.png" alt="humo" />
            </div>
          </Button>
        </div>
        <Button
          className="submit-btn primary-1-bg w-100 natural-8 size-7 mt-5"
          onClick={submit}
        >
          {t("Далее")}
        </Button>
      </div>
    </div>
  );
}
