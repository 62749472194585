import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function HowItWorksSection() {
  const { t } = useTranslation();

  return (
    <section className="how-it-works-section row">
      <div className="absolute-div p-4">
        <h6 className="size-6 lh-32 text-bold">01.</h6>
        <h6 className="size-7 lh-24 text-bold natural-4">
          {t("Показываем информацию по доставке")}
        </h6>
      </div>
      <div className="col-md-6">
        <div className="left-content">
          <h2 className="size-2 text-bold ls-0 mb-3 ws-pw">
            {t("Как всё\nработает")}
          </h2>
          <h6 className="size-8 natural-4 text-medium lh-24 ws-pw">
            {t(
              "Выполните четыре простых шага, чтобы\nполучить покупку из 30 стран"
            )}
          </h6>
        </div>
      </div>
      <div className="col-md-6">
        <div className="right-content text-center">
          <img src="/images/dron.png" alt="dron" />
          <h4 className="size-4 natural-8 text-bold">{t("Регистрируйтесь")}</h4>
          <h6 className="size-7 lh-24 text-medium ws-pw natural-6">
            {t(
              "Зарегистрируйтесь на сайте, чтобы получить\nперсональный адрес в США. Это займет 1 минуту."
            )}
          </h6>
          <Link to="login" className="size-8 lh-24 text-bold natural-8">
            {t("Получить личный адрес")}
          </Link>
        </div>
      </div>
    </section>
  );
}
