import React, { useContext, useEffect, useState } from "react";
import CustomModal from ".";
import { useTranslation } from "react-i18next";
import Button from "../buttons";
import { useForm } from "react-hook-form";
import Select from "../form-components/Select";
import CountrySelector from "../form-components/CountrySelector";
import Input from "../form-components";
import {
  createAddress,
  getDistrictsByRegionId,
  getRegionsByCountryId,
  updateAddress,
} from "@/api/address";
import { Context, formatToOptions } from "../utils";
import { toast } from "react-toastify";
import Receivers from "./Receivers";

interface Props {
  modal: any;
  setModal: (data: any) => void;
  setAddresses: (data: object) => void;
  addresses: object;
}

export default function Address({ modal, setModal }: Props) {
  const { t } = useTranslation();
  const {
    state: {
      user: { customer },
      settings: { countries },
    },
  } = useContext(Context);

  const [districts, setDistricts] = useState<OptionType[]>([]);
  const [localStore, setLocalStore] = useState<{
    regions: OptionType[];
    customer: any;
    notSelf: boolean;
  }>({
    regions: [],
    notSelf: false,
    customer: modal.content?.customer ?? null,
  });
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: modal.content ?? { country_id: countries[0].id },
  });

  const closeModal = () => setModal({ isOpen: false });

  const newReceiver = () => {
    setLocalStore({ ...localStore, notSelf: true });
  };

  const getRegions = async () => {
    try {
      const { data: regions } = await getRegionsByCountryId(
        watch("country_id")
      );
      if (!modal.content) {
        setValue("region_id", regions.items[0].id);
      }

      setLocalStore({
        ...localStore,
        regions: regions.items,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async () => {
    try {
      if (watch("region_id")) {
        const { data: districts } = await getDistrictsByRegionId(
          watch("region_id")
        );
        setDistricts(districts.items);
        if (!modal.content) {
          setValue("district_id", districts.items[0].id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async (values: any) => {
    var newAddress = null;
    try {
      if (values.customer) {
        values.customer.phones[0] = values.customer.phones[0].replace(
          /\D/g,
          ""
        );
      }
      if (values.id) {
        const { data } = await updateAddress(values);
        newAddress = data;
      } else {
        values.customer_id = localStore.customer?.id ?? customer.id;
        const { data } = await createAddress(values);
        newAddress = data;
      }
      setModal({ isOpen: false });
      toast.success("Done successfully");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRegions();
  }, [watch("country_id")]);

  useEffect(() => {
    getDistricts();
  }, [watch("region_id")]);

  return (
    <CustomModal
      className="new-address-modal"
      isOpen={modal.isOpen}
      closeModal={closeModal}
    >
      <div className="react-modal-header justify-content-between">
        <div>
          <h5 className="size-5 natural-2 mb-1 text-bold">
            {t(localStore.customer ? "Обновить адрес" : `Добавить новый адрес`)}
          </h5>
        </div>
        <div>
          <Button
            className="modal-close-btn natural-6-border"
            onClick={closeModal}
          >
            <img src="/icons/close.svg" width={24} alt="close" />
          </Button>
          <div className="ml-auto custom-checkbox cntr position-relative d-flex-center flex-row-reverse justify-content-end">
            <label
              htmlFor="is-self"
              className="m-0 natural-1 size-8 text-semi-bold pointer text-nowrap"
            >
              {t("Для другого человека")}
            </label>
            <input
              disabled={localStore.customer ? true : false}
              id="is-self"
              type="checkbox"
              checked={localStore.notSelf}
              onChange={newReceiver}
              className="hidden-xs-up w-auto"
            />
            <label htmlFor="is-self" className="cbx" />
          </div>
        </div>
      </div>
      <div className="react-modal-body position-relative">
        {localStore.customer && localStore.customer.id !== customer.id && (
          <div className="d-flex-center justfy-content-between mt-3">
            <h6 className="m-0 size-6 text-semi-bold text-nowrap">
              {localStore.customer.first_name} {localStore.customer.last_name}
            </h6>
            <Button
              className="simple-btn ml-auto size-8 w-auto primary-1 primary-1-border"
              onClick={newReceiver}
            >
              {t("Обновить")}
            </Button>
          </div>
        )}
        <form className="form">
          <CountrySelector
            name="country_id"
            options={countries}
            setValue={setValue}
            watch={watch}
            label={t("С какой стороны нужно доставить")}
            labelClass="size-7 natural-5 text-medium mb-1"
          />
          <div className="row mx-auto gap-20" key={localStore.regions.length}>
            <Select
              register={register}
              options={formatToOptions(localStore.regions)}
              name="region_id"
              validations={{ required: t("This is required.") }}
              label={t("Регион *")}
              labelClass="size-7 natural-5 text-medium m-0"
              inputClass="natural-6-border text-bold pointer"
            />
            <Select
              key={districts.length}
              register={register}
              disabled={!watch("region_id")}
              options={formatToOptions(districts)}
              name="district_id"
              validations={{ required: t("This is required.") }}
              label={t("Город / Район *")}
              labelClass="size-7 natural-5 text-medium m-0"
              inputClass="natural-6-border text-bold pointer"
            />
          </div>
          <div className="row mx-auto gap-20 extra-info">
            <Input
              parentClass="d-flex flex-column"
              register={register}
              name="first_address"
              errors={errors}
              label={t("Улица *")}
              validations={{ required: t("This is required.") }}
              placeholder={t("Введите улицу")}
              inputClass="natural-6-border text-bold"
              labelClass="size-7 natural-5 text-medium m-0"
            />
            <Input
              parentClass="d-flex flex-column"
              register={register}
              name="house"
              errors={errors}
              label={t("Дом *")}
              validations={{ required: t("This is required.") }}
              placeholder={t("Номер дома")}
              inputClass="natural-6-border text-bold"
              labelClass="size-7 natural-5 text-medium m-0"
            />
            <Input
              parentClass="d-flex flex-column"
              register={register}
              name="apartment"
              label={t("Квартира/офис")}
              placeholder={t("Номер дома")}
              inputClass="natural-6-border text-bold"
              labelClass="size-7 natural-5 text-medium m-0"
            />
          </div>
          <Input
            parentClass="d-flex flex-column"
            register={register}
            name="second_address"
            label={t("Ориентир")}
            placeholder={t("Видите ориентир или комментарии для курьера")}
            inputClass="natural-6-border text-bold"
            labelClass="size-7 natural-5 text-medium m-0"
          />
        </form>
        <Button
          className="submit-btn primary-1-bg w-100 natural-8 size-7"
          onClick={handleSubmit(submit)}
        >
          {t(watch("id") ? "Обновить" : `Добавить`)}
        </Button>
      </div>
      {localStore.notSelf && (
        <Receivers modal={localStore} setModal={setLocalStore} />
      )}
    </CustomModal>
  );
}
