export const accordionContent = (id: number) => {
  switch (id) {
    case 1:
      return [
        {
          id: 1,
          question: "Будет ли склад в безналоговом штате?",
          answer:
            "Мы работаем над этим и в скором времени запустим наш склад в безналоговом штате. После этого заказы будут обходиться вам дешевле :)",
          link: "/",
        },
        {
          id: 2,
          question: "Как зарегистрировать интернет заказ?",
          answer:
            "Мы работаем над этим и в скором времени запустим наш склад в безналоговом штате. После этого заказы будут обходиться вам дешевле :)",
          link: "/",
        },
        {
          id: 3,
          question: "Как создать личный кабинет?",
          answer:
            "Мы работаем над этим и в скором времени запустим наш склад в безналоговом штате. После этого заказы будут обходиться вам дешевле :)",
          link: "/",
        },
        {
          id: 4,
          question: "В какие дни отправляете из США в Узбекистан?",
          answer:
            "Мы работаем над этим и в скором времени запустим наш склад в безналоговом штате. После этого заказы будут обходиться вам дешевле :)",
          link: "/",
        },
      ];
    case 2:
      return [];
    case 3:
      return [];
    case 4:
      return [];
    case 5:
      return [];
    case 6:
      return [];
    default:
      return [];
  }
};
