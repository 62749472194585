import Auth from "@/pages/login/Auth";
import AuthMedia from "@/pages/login/AuthMedia";
import React from "react";

export default function Login() {
  return (
    <div className="login-page d-flex-center justify-content-xl-end">
      <div className="container">
      <div className="auth-content">
          <div className="row">
            <div className="col-md-6 col-12">
              <Auth />
            </div>
            <div className="col-md-6 d-xl-none col-12">
              <AuthMedia />
            </div>
          </div>
        </div>
      </div>
      <div className="position-absolute top-0 right-0 desktop-media">
        <AuthMedia />
      </div>
    </div>
  );
}
