import {
  createPickup,
  createSendOrder,
  createShopOrder,
  createTrackingOrder,
} from "@/api/order";
import LoaderLayout from "@/components/LoaderLayout";
import Button from "@/components/buttons";
import { Context, debounce, getRecordsSum } from "@/components/utils";
import { SET_NEW_ORDER } from "@/controllers/actions";
import React, { ChangeEvent, useContext, useState } from "react";
import { months } from "@/components/constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function OrderConfirm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { newOrder },
    dispatch,
  } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const {
    unit,
    recipient_address,
    sender_address,
    records,
    tracking,
    note,
    branch_id,
    boxes,
    shipment_type,
    pickup,
    ratePrice = 0,
  } = newOrder.order;
  const totalSumProducts = getRecordsSum(boxes ? boxes[0].products : records);

  const setComment = (e: ChangeEvent<HTMLInputElement>) => {
    debounce(
      () => {
        dispatch({
          type: SET_NEW_ORDER,
          payload: {
            ...newOrder,
            order: { ...newOrder.order, note: e.target.value },
          },
        });
      },
      "comment",
      500
    );
  };

  const handleBack = () => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        prevStep: newOrder.prevStep === 9 ? 8 : newOrder.order.order_type,
        currentStep: newOrder.prevStep,
        order: newOrder.order,
      },
    });
  };

  const handleSubmit = async () => {
    let orderData = {};

    setLoading(true);
    try {
      if (newOrder.order.order_type === 4) {
        orderData = {
          tracking: tracking,
          recipient_address_id: recipient_address.id,
          note: note,
          records,
        };
        const { data } = await createTrackingOrder(orderData);
        dispatch({
          type: SET_NEW_ORDER,
          payload: {
            currentStep: 1,
            prevStep: 0,
            order: {},
          },
        });
        navigate(`/parcels/trackings/${data.id}`);
      } else if (newOrder.order.order_type === 5) {
        orderData = {
          recipient_address_id: recipient_address.id,
          note: note,
          records,
        };
        const { data } = await createShopOrder(orderData);
        dispatch({
          type: SET_NEW_ORDER,
          payload: {
            currentStep: 1,
            prevStep: 0,
            order: {},
          },
        });
        navigate(`/parcels/shop-orders/${data.id}`);
      } else {
        orderData = {
          unit: unit,
          note: note,
          branch_id: branch_id,
          shipment_type_id: shipment_type.id,
          sender_country_id: sender_address.country.id,
          receiver_country_id: recipient_address.country.id,
          boxes: [
            {
              ...boxes[0],
              weight: +boxes[0].weight,
              delivery: recipient_address.delivery,
              sender_address_id: sender_address.id,
              recipient_address_id: recipient_address.id,
            },
          ],
        };
        const { data } = await createSendOrder(orderData);
        if (pickup?.time) {
          const pickupData = {
            date: `${pickup.year}-${pickup.month}-${pickup.day}`,
            start_time: pickup.time.split("-")[0],
            end_time: pickup.time.split("-")[1],
          };
          await createPickup(data.id, pickupData);
        }
        dispatch({
          type: SET_NEW_ORDER,
          payload: {
            currentStep: 1,
            prevStep: 0,
            order: {},
          },
        });
        navigate(`/parcels/orders/${data.id}`);
      }
      toast.success("Order created successfully");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      <Button className="size-3 back-btn" onClick={handleBack}>
        <img
          src="/icons/arrow-left.svg"
          className="mr-3"
          width={40}
          alt="back"
        />
        {t("Подтверждения заказа ")}
      </Button>
      <div className="content-body mx-auto">
        <div className="content confirm">
          <div className="address-list">
            {sender_address && (
              <div className="address-card-header d-flex-center mb-3">
                <img src="/icons/marker.png" width={64} alt="location" />
                <div className="header-info ml-3">
                  <h4 className="size-7 primary-1 text-bold mb-1">
                    {t("Адрес отправителя")}
                  </h4>
                  <h4 className="size-7 natural-2 text-bold m-0">
                    {t(sender_address.country.name)},{" "}
                    {sender_address.region
                      ? sender_address.region.name + ", "
                      : ""}
                    {sender_address.first_address}
                  </h4>
                </div>
              </div>
            )}
            <div className="address-card-header d-flex-center mb-3">
              <img src="/icons/marker.png" width={64} alt="location" />
              <div className="header-info ml-3">
                <h4 className="size-7 primary-1 text-bold mb-1">
                  {t("Адрес получателя")}
                </h4>
                <h4 className="size-7 natural-2 text-bold m-0">
                  {t(recipient_address.country.name)},{" "}
                  {recipient_address.region
                    ? recipient_address.region.name + ", "
                    : ""}
                  {recipient_address.first_address}
                </h4>
              </div>
            </div>
          </div>
          <div className="order-content-body-info">
            {(boxes ? boxes[0].products : records).map(
              (record: any, i: number) => (
                <div className="order-info d-table w-100" key={i}>
                  <div className="number size-8 text-bold natural-4 d-table-cell align-middle">
                    №&nbsp;{i + 1}
                  </div>
                  <div className="info-body w-100 table-cell">
                    <div className="d-flex-center justify-content-between">
                      <h5 className="size-8 m-0 text-medium natural-4">
                        {t("Название посылки")}
                      </h5>
                      <h5 className="size-8 m-0 text-bold natural-2 text-right">
                        {record.name}
                      </h5>
                    </div>
                    <div className="d-flex-center justify-content-between">
                      <h5 className="size-8 m-0 text-medium natural-4">
                        {t("Количество")}
                      </h5>
                      <h5 className="size-8 m-0 text-bold natural-2 text-right">
                        {record.quantity}&nbsp;{t("шт")}.
                      </h5>
                    </div>
                    <div className="d-flex-center justify-content-between">
                      <h5 className="size-8 m-0 text-medium natural-4">
                        {t("Стоимость")}
                      </h5>
                      <h5 className="size-8 m-0 text-bold natural-2 text-right">
                        $ {record.price}
                      </h5>
                    </div>
                    <div className="d-flex-center justify-content-between">
                      <h5 className="size-8 m-0 text-medium natural-4 m-0">
                        {t("Общая стоимость")}
                      </h5>
                      <h5 className="size-8 m-0 text-bold natural-2 text-right">
                        $ {record.quantity * record.price}
                      </h5>
                    </div>
                  </div>
                </div>
              )
            )}

            {newOrder.order.recipient_address.pickupType && (
              <>
                <hr />
                <div className="d-flex-center justify-content-between">
                  <h5 className="size-8 m-0 text-medium natural-4">
                    {t("Служба доставки")}
                  </h5>
                  <img
                    width={50}
                    src={newOrder.order.recipient_address.pickupType.logo}
                    alt="Delivery service logo"
                  />
                </div>
                <div className="d-flex-center justify-content-between">
                  <h5 className="size-8 m-0 text-medium natural-4">
                    {t("Стоимость доставки")}
                  </h5>
                  <h5 className="size-8 m-0 text-bold natural-2 text-right">
                    $ {recipient_address.region.delivery_rate + ratePrice}
                  </h5>
                </div>
                {/* <div className="d-flex-center justify-content-between">
                  <h5 className="size-8 m-0 text-medium natural-4">
                    {t("Тарифный план доставки FedEx")}
                  </h5>
                  <h5 className="size-8 m-0 text-bold natural-2 text-right">
                    FEDEX_EXPRESS_SAVER | {t("Доставка будет стоить")} 5$
                  </h5>
                </div> */}
              </>
            )}
            {pickup?.time && (
              <>
                <div className="d-flex-center justify-content-between">
                  <h5 className="size-8 m-0 text-medium natural-4">
                    {t(
                      "Выбранное вами время, в которое наш курьер сможет собрать посылку"
                    )}
                  </h5>
                  <h5 className="size-8 m-0 text-bold natural-2 text-right">
                    {pickup.day}{" "}
                    {t(months.find((m) => m.value === pickup.month)!.label)}{" "}
                    {pickup.year} г.
                    <br />
                    {t("С start по end", {
                      start: pickup.time.split("-")[0],
                      end: pickup.time.split("-")[1],
                    })}
                  </h5>
                </div>
                <div className="d-flex-center justify-content-between">
                  <h5 className="size-8 m-0 text-medium natural-4">
                    {t("Оставить комментарий")}
                  </h5>
                  <h5 className="size-8 m-0 text-bold natural-2 text-right">
                    {pickup.comment}
                  </h5>
                </div>
              </>
            )}

            <hr />
            <div className="d-flex-center justify-content-between">
              <h5 className="size-8 m-0 text-medium natural-4">
                {t("Стоимость товаров")}
              </h5>
              <h5 className="size-8 m-0 text-bold natural-2 text-right">
                $ {totalSumProducts}
              </h5>
            </div>
            {boxes && (
              <div className="d-flex-center justify-content-between">
                <h5 className="size-8 m-0 text-medium natural-4">
                  {t("Общий вес посылки")}
                </h5>
                <h5 className="size-8 m-0 text-bold natural-2 text-right">
                  {boxes[0].weight} {t("кг")}
                </h5>
              </div>
            )}
            {/* <div className="d-flex-center justify-content-between">
              <h5 className="size-8 m-0 text-medium natural-4">
                {t("Стоимости перевозки")}
              </h5>
              <h5 className="size-8 m-0 text-bold natural-2 text-right">
                $ 45
              </h5>
            </div> */}
            {/* <div className="d-flex-center justify-content-between">
              <h5 className="size-8 m-0 text-medium natural-4">
                {t("Дополнительные услуги (0)")}
              </h5>
              <h5 className="size-8 m-0 text-bold natural-2 text-right">$ 0</h5>
            </div> */}
            {/* <div className="d-flex-center justify-content-between">
              <h5 className="size-8 m-0 text-medium natural-4">
                {t("Оплачено")}
              </h5>
              <h5 className="size-8 m-0 primary-4 text-bold text-right">
                $ 45
              </h5>
            </div> */}
            <hr />
            <div className="">
              <h4 className="size-8 mb-1 text-medium natural-4">
                {t("Общая стоимость товаров")}
              </h4>
              <h5 className="size-6 m-0 mt-2 text-bold primary-1">
                ${" "}
                {(
                  totalSumProducts +
                  +recipient_address.region.delivery_rate +
                  ratePrice
                ).toFixed(2)}
                {/* {records.reduce(
                  (acc: number, current: any) => acc + +current.price,
                  0
                )} */}
              </h5>
            </div>
          </div>
          <div className="custom-input">
            <label htmlFor="note" className="size-7 natural-5 text-medium m-0">
              {t("Комментарий для оператора")}
            </label>
            <input
              name="note"
              onChange={setComment}
              type="text"
              className="form-control natural-6-border text-bold"
              placeholder="..."
            />
          </div>
          <Button
            className="submit-btn size-7 w-100 natural-8 primary-1-bg"
            onClick={handleSubmit}
          >
            {t("Оформить")}
            <LoaderLayout
              loading={loading}
              style={{ width: "16px", marginRight: "12px" }}
              children={<img src="/icons/star.svg" width={16} alt="star" />}
            />
          </Button>
        </div>
      </div>
    </>
  );
}
