import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../buttons";
import Calculator from "../modals/Calculator";

export default function BannersSection() {
  const { t } = useTranslation();
  const [opener, setOpener] = useState<{ calc?: boolean }>({
   
    calc: false,
  });

  return (
    <section className="banners-section row">
      <div className="col-md-3 pr-md-0">
        <div className="banner-content">
          <h6 className="size-6 text-bold lh-32 natural-8">
            {t("Давайте посчитаем примерную стоимость доставки")}
          </h6>
          <p className="size-8 natural-8 text-medium lh-24 mb-4">
            {t(
              "Наши специалисты разработали удобный онлайн-калькулятор, который позволит вам быстро и точно определить цену на необходимые товары."
            )}
          </p>
          <Button
            onClick={() => setOpener({ calc: true })}
            className="calculator-btn"
          >
            {t("Рассчитать")}
          </Button>
        </div>
      </div>
      <div className="col-md-3 pr-md-0">
        <div className="banner-content">
          <h4 className="size-4 lh-lg natural-8 text-bold mb-3">
            {t("Поддержка клиентов 7 дней в неделю")}
          </h4>
          <p className="size-7 natural-8 text-medium lh-24 m-0">
            {t(
              "Мы всегда на связи. Готовы выполнить любой ваш запрос или найдем достойную альтернативу Вы всегда можете рассчитывать на нашу помощь в любом вопросе"
            )}
          </p>
        </div>
      </div>
      <div className="col-md-6">
        <div className="banner-content">
          <h6 className="size-6 natural-8 text-bold lh-32 mb-0 ws-pw">
            {t("Быстрая и надежная\nдоставка по всему миру!")}
          </h6>
          <Button className="play-btn" onClick={() => {}}>
            <img src="/icons/play.svg" width={20} alt="play" />
          </Button>
        </div>
      </div>
      {opener.calc && <Calculator modal={opener} setModal={setOpener} />}
    </section>
  );
}
