import Button from "@/components/buttons";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContentSwitcher from "./AuthContentSwitcher";
import { useTranslation } from "react-i18next";
import { Context } from "@/components/utils";
import { SET_AUTH_STEP, SET_USER } from "@/controllers/actions";

export default function Auth() {
  const { t } = useTranslation();
  const navigete = useNavigate();
  const {
    state: { authStep, user },
    dispatch,
  } = useContext(Context);

  const back = () => {
    if (authStep.prevStep) {
      dispatch({
        type: SET_AUTH_STEP,
        payload: {
          prevStep: authStep.currentStep,
          currentStep: authStep.prevStep,
        },
      });
    } else navigete("/");
  };

  useEffect(() => {
    return () => {
      if (user) {
        dispatch({
          type: SET_USER,
          payload: {},
        });
        dispatch({
          type: SET_AUTH_STEP,
          payload: {
            prevStep: undefined,
            currentStep: 1,
          },
        });
      }
    };
  }, []);

  return (
    <div className="auth">
      <Button
        className="back-btn natural-6-border size-8 natural-2"
        onClick={back}
      >
        <img src="/icons/arrow-left.svg" alt="back" /> {t("Back")}
      </Button>
      <AuthContentSwitcher />
    </div>
  );
}
