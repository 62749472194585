import React from "react";
import Button from "../buttons";
import { useNavigate } from "react-router-dom";
import BreadCrumb, { LinkType } from "../bread-crumb";

interface Props {
  backText: string;
  links: LinkType[];
}

export default function PagePagination({ backText, links }: Props) {
  const navigate = useNavigate();

  return (
    <div className="page-pagination">
      <div className="container">
        <div className="d-flex-center justify-content-between">
          <Button
            className="back-btn natural-6-border size-8 natural-2"
            onClick={() => navigate(-1)}
          >
            <img src="/icons/arrow-left.svg" alt="back" /> {backText}
          </Button>
          <BreadCrumb links={links} />
        </div>
      </div>
    </div>
  );
}
