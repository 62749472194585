import React from "react";
import Button from "../../components/buttons";
import { useTranslation } from "react-i18next";
import LinkButton from "../../components/buttons/LinkButton";
import { useLocation } from "react-router-dom";

interface Props {
  title: string;
}

export default function ContentHeader({ title }: Props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const newAddress = () => {
    const button = document.getElementById("new-address");
    button!.click();
  };

  return (
    <div className="content-header d-flex-center justify-content-between">
      <h1 className="natural-2 text-semi-bold m-0 size-4">{t(title)}</h1>
      {(pathname === "/profile" || pathname.startsWith("/parcels")) && (
        <div className="buttons d-flex-center">
          <LinkButton
            className="order-btn simple-btn natural-6-border size-8 natural-2"
            to="/new-order"
          >
            {t("Новый заказ")}
          </LinkButton>
        </div>
      )}
      {pathname === "/addresses" && (
        <Button
          className="simple-btn natural-6-border size-8 natural-2"
          onClick={newAddress}
        >
          {t("Новый адрес")}
        </Button>
      )}
    </div>
  );
}
