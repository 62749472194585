import React, { ReactNode } from "react";
import Banner from "./banners";

interface Props {
  loading: boolean;
  style?: object;
  children: ReactNode;
}

export default function LoaderLayout({ loading, children, style }: Props) {
  if (loading) {
    return (
      <div style={style} className="loader-layout">
        <img src="/images/spin.svg" alt="loader" width="100%" height="100%" />
      </div>
    );
  }
  return children;
}
