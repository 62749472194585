import Button from "@/components/buttons";
import { Context, orderStepNavigator } from "@/components/utils";
import { SET_NEW_ORDER } from "@/controllers/actions";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function CountrySelector() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { settings, newOrder },
    dispatch,
  } = useContext(Context);
  const currentStep = orderStepNavigator(7, newOrder.order.order_type);

  const handleBack = () => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        prevStep: newOrder.prevStep ? 6 : 0,
        currentStep: newOrder.prevStep ?? 1,
        order: newOrder.prevStep
          ? { ...newOrder.order, country_id_2: null }
          : {},
      },
    });
    if (newOrder.prevStep === 0) {
      navigate(-1);
    }
  };

  const onHandleClick = (id: number) => {
    if (newOrder.prevStep) {
      dispatch({
        type: SET_NEW_ORDER,
        payload: {
          prevStep: 11,
          currentStep: currentStep,
          order: { ...newOrder.order, country_id_2: id },
        },
      });
    } else {
      dispatch({
        type: SET_NEW_ORDER,
        payload: { prevStep: 1, currentStep: 2, order: { country_id: id } },
      });
    }
  };

  return (
    <>
      <Button className="size-3 back-btn" onClick={handleBack}>
        <img
          src="/icons/arrow-left.svg"
          className="mr-3"
          width={40}
          alt="back"
        />
        {t("Добавить ожидаемый заказ")}
      </Button>
      <div className="content-body mx-auto">
        <div className="content">
          <h6 className="text-bold size-6">
            <span className="primary-1 text-bold">1. </span>
            {t("Выберите страну")}
          </h6>
          {settings.countries
            .filter((c: OptionType) => c.id !== newOrder.order.country_id)
            .map((country: CountryType) => (
              <Button
                key={country.id}
                className="selector-btn natural-6-border"
                onClick={() => onHandleClick(country.id)}
              >
                <div className="image-box rounded-circle d-flex-center justify-content-center">
                  <img src="/icons/marker.svg" alt="flag" />
                </div>
                <h6 className="size-6 text-medium natural-2 lh-32 m-0">
                  {country.name}
                </h6>
              </Button>
            ))}
        </div>
      </div>
    </>
  );
}
