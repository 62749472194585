import React, { useMemo, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import Banner from "../banners";

interface Props {
  name: string;
  label: string;
  register: any;
  validations?: object;
  errors?: object;
  setValue: (name: string, data: any) => void;
  getValues: (name: string) => { url: string };
  multiple?: boolean;
  inputClass?: string;
  labelClass?: string;
  parentClass?: string;
}

export default function FileUpload({
  name,
  errors,
  getValues,
  label,
  setValue,
  multiple = false,
  inputClass = "",
  labelClass = "",
  parentClass = "",
}: Props) {
  const [images, setImages] = useState<string[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length) {
      setImages(files.map((file) => URL.createObjectURL(file)));
    }
    if (multiple) {
      setValue(name, files);
    } else setValue(name, files[0]);
  };

  useMemo(() => getValues(name) && setImages([`${getValues(name).url}`]), []);

  // const removeFile = (index: number) => {
  //   setImages(images.filter((_, i) => i !== index));
  //   if (multiple) {
  //     setValue(name[index], null);
  //   } else setValue(name, null);
  // };

  return (
    <div className={`custom-upload position-relative ${parentClass}`}>
      <label htmlFor={name} className={labelClass}>
        {images.length ? (
          images.filter(Boolean).map(
            (img, i) => <Banner key={i} src={img} className="doc-img" />
            //   <Button className="delete-btn" onClick={() => removeFile(i)}>
            //   <img src="/icons/delete.svg" width={12} alt="delete" />
            // </Button>
          )
        ) : (
          <>
            <img src="/icons/upload.svg" width={24} alt="upload-img" />
            {label}
          </>
        )}
      </label>
      <input
        accept="image/*"
        type="file"
        // {...register(name, validations)}
        multiple={multiple}
        onChange={handleChange}
        className={`form-control ${inputClass}`}
      />

      {errors && (
        <span className="primary-3 size-8 error-message mt-1 position-absolute">
          <ErrorMessage errors={errors} name={name} />
        </span>
      )}
    </div>
  );
}
