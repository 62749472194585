import { errorMessage } from "@/components/utils";
import axios from "axios";
import { toast } from "react-toastify";

export const getMainUrl = () => {
  return `${import.meta.env.VITE_APP_HOST || "https://cabinet.fgf.ai"}/api`;
};

const request = axios.create({
  baseURL: getMainUrl(),
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
});

request.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken && !window.location.href?.includes("login")) {
      if (
        !config.headers["Authorization"] ||
        !config.headers["Authorization"].toString().startsWith("Bearer")
      ) {
        config.headers["authorization"] = `Bearer ${jwtToken}`;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let notificationParam = {
      message: "",
    };
    // Remove token and redirect
    toast.error(errorMessage(error.response.data));
    if (
      error.response.status === 401 &&
      localStorage.getItem("token") &&
      window.location.href.match(/(profile|parcels|addresses|balance|settings)/)
    ) {
      notificationParam.message = "Please login again";
      localStorage.removeItem("token");
      window.location.replace("/login");
    }

    if (error.response.status === 404) {
      notificationParam.message = "Not Found";
    }

    if (error.response.status === 403) {
      notificationParam.message = "Access denied";
    }

    if (error.response.status === 500) {
      notificationParam.message = "Internal Server Error";
    }

    if (error.response.status === 508) {
      notificationParam.message = "Time Out";
    }
    if (error.response.status === 422) {
      notificationParam.message = "Form values are invalid";
    }

    return Promise.reject(error);
  }
);

export async function mainCaller(
  path: string,
  method = "GET",
  data?: object,
  headers = {},
  params?: object
) {
  const options = {
    url: `${getMainUrl()}${path}`,
    headers: headers,
    params: params,
    method,
    data: data,
    withCredentials: true,
  };
  const res = await request(options);
  return res;
}
