import React from "react";
import { ErrorMessage } from "@hookform/error-message";

interface Props {
  register: any;
  name: string;
  type?: string;
  maxLength?: number;
  errors?: object;
  minLength?: number;
  validations?: object;
  placeholder?: string;
  label?: string;
  readonly?: boolean;
  inputClass?: string;
  labelClass?: string;
  parentClass?: string;
}

export default function Input({
  register,
  name,
  errors,
  type = "text",
  validations,
  label,
  readonly = false,
  maxLength,
  minLength,
  placeholder = "",
  inputClass = "",
  labelClass = "",
  parentClass = "",
}: Props) {

  return (
    <div className={`custom-input position-relative ${parentClass}`}>
      {label && (
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
      )}
      <input
        type={type}
        maxLength={maxLength}
        minLength={minLength}
        readOnly={readonly}
        {...register(name, validations)}
        className={`form-control ${inputClass}`}
        placeholder={placeholder}
      />
      {errors && (
        <span className="primary-3 size-8 error-message mt-1 position-absolute">
          <ErrorMessage errors={errors} name={name} />
        </span>
      )}
    </div>
  );
}
