import { mainCaller } from "./mainCaller";

export const getOrderById = (id: string) => {
  return mainCaller(`/orders/${id}`, "GET");
};

export const getShopOrderById = (id: string) => {
  return mainCaller(`/shop-orders/${id}`, "GET");
};

export const getTrackningOrderById = (id: string) => {
  return mainCaller(`/trackings/${id}`, "GET");
};

export const getUserInfo = () => {
  return mainCaller("/auth/me", "GET");
};

export const getUserWallets = () => {
  return mainCaller("/wallets", "GET");
};

export const getUserOrders = (type: string, query: object) => {
  return mainCaller(`/${type}`, "GET", undefined, {}, query);
};

export const getAddresses = (query: object) => {
  return mainCaller("/addresses", "GET", undefined, {}, query);
};

export const getAddress = (id: number) => {
  return mainCaller(`/addresses/${id}`, "GET");
};

export const cardConfirmCode = (data: object) => {
  return mainCaller("/payments/bank-cards", "POST", data);
};

export const orderPay = (data: object) => {
  return mainCaller("/wallets/pay", "POST", data);
};

export const resendCardCode = () => {
  return mainCaller("/payments/resend-verify", "POST");
};

export const paymePay = (data: object) => {
  return mainCaller("/payments/pay", "POST", data);
};

export const updateUser = (data: object) => {
  return mainCaller(
    `/profiles/me`,
    "POST",
    { ...data, _method: "PUT" },
    {
      "Content-type": "multipart/form-data",
    }
  );
};

export const getUserReceivers = () => {
  return mainCaller("/profiles/receivers", "GET");
};
export const updatePassword = (data: object) => {
  return mainCaller("/profiles/change-password", "POST", data);
};
export const changePassword = (data: object) => {
  return mainCaller("/forgot-password/confirm", "POST", data);
};

export const getReceiverByIds = (ids: string) => {
  return mainCaller(`/profiles/receivers?${ids}`, "GET");
};

export const createReceiver = (data: object) => {
  return mainCaller("/profiles/receivers", "POST", data, {
    "Content-type": "multipart/form-data",
  });
};

export const updateReceiver = (data: OptionType) => {
  return mainCaller(`/profiles/receivers/${data.id}`, "POST", data, {
    "Content-type": "multipart/form-data",
  });
};

export const getStripePublishingKey = () => {
  return mainCaller("/payments/stripe/publishable-key", "GET");
};

export const getCurrencyRate = () => {
  return mainCaller("/payments/currency-rate", "GET");
};

export const createStripeIntents = (amount: object) => {
  return mainCaller("/payments/stripe/create-intents", "POST", amount);
};
