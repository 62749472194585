import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import Banner from "../banners";
import LinkButton from "../buttons/LinkButton";

const slides = [
  {
    title: "Быстрая и надежная доставка",
    description:
      "Мы гарантируем быструю и надежную доставку вашего товара прямо к вам домой.",
    link: "login",
    img: "/images/airplane.png",
  },
  {
    title: "Abs и надежная доставка",
    description:
      "Мы гарантируем быструю и надежную доставку вашего товара прямо к вам домой.",
    link: "login",
    img: "/images/airplane.png",
  },
  {
    title: "Bsa и надежная доставка",
    description:
      "Мы гарантируем быструю и надежную доставку вашего товара прямо к вам домой.",
    link: "login",
    img: "/images/airplane.png",
  },
];

export default function SliderSection() {
  const { t } = useTranslation();

  return (
    <section className="slider-section">
      <div className="container">
        <Swiper
          autoplay
          spaceBetween={10}
          slidesPerView={1}
          centeredSlides={true}
          navigation
          modules={[Navigation, ]}
          loop={true}
        >
          {slides.map((item, i) => (
            <SwiperSlide key={i}>
              <div className="d-flex-center justify-content-between">
                <div className="info-side">
                  <h4 className="size-4 text-bold m-0 natural-8">
                    {item.title}
                  </h4>
                  <p className="text-medium size-8 natural-8 lh-24 m-0">
                    {item.description}
                  </p>
                  <LinkButton to={item.link} className="link-btn natural-3 size-8 text-bold">
                    {t("Начать пользоваться")}
                  </LinkButton>
                </div>
                <Banner src={item.img} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
