import { mainCaller } from "./mainCaller";

export const signUp = (data: object) => {
  return mainCaller("/registration", "POST", data);
};

export const signIn = (data: object) => {
  return mainCaller("/auth/login", "POST", data);
};

export const getVerifyCode = (data: object) => {
  return mainCaller("/registration/send-verification", "POST", data);
};

export const getPassVerifCode = (data: object) => {
  return mainCaller("/forgot-password/request", "POST", data);
};
