import { mobileNavbar } from "@/components/constants";
import { Context } from "@/components/utils";
import { SET_USER } from "@/controllers/actions";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export default function ProfileMobileNavbar() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useContext(Context).dispatch;

  const logOut = () => {
    localStorage.removeItem("token");
    dispatch({ type: SET_USER, payload: null });
  };
  return (
    <div className="profile-mobil-navbar">
      <div className="container">
        <div className="mobile-navbar-list d-flex-center justify-content-between">
          {mobileNavbar.map((menu, index) => {
            return (
              <Link
                key={index}
                to={menu.link}
                className={`mobile-navbar-item size-9 text-medium ${
                  pathname.startsWith(index !== 1 ? menu.link : "/parcels/")
                    ? "primary-1"
                    : "natural-5"
                }`}
              >
                <img className="mb-1" src={menu.icon} alt={menu.label} />
                {t(menu.label)}
              </Link>
            );
          })}
          <button
            className="btn mobile-navbar-item size-9 natural-5 p-0 text-medium logout-btn"
            onClick={logOut}
          >
            <img src="/icons/logout.svg" alt="" /> {t("Выйти")}
          </button>
        </div>
      </div>
    </div>
  );
}
