import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { useTranslation } from "react-i18next";
import { weightOptions } from "../constants";

interface Props {
  register: any;
  name: string;
  selectName: string;
  errors?: object;
  validations?: object;
  label?: string;
  labelClass?: string;
}

export default function WeightInput({
  register,
  name,
  selectName,
  errors,
  validations,
  label,
  labelClass,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="custom-input position-relative">
      {label && (
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
      )}
      <div className="weight-input d-flex-center">
        <input
          type="number"
          {...register(name, validations)}
          className="form-control"
          placeholder="0"
        />
        <select
          className="form-control"
          defaultValue="kg/sm"
          {...register(selectName)}
        >
          {weightOptions.map((option) => (
            <option value={option.value} key={option.value}>
              {t(option.label)}
            </option>
          ))}
        </select>
      </div>
      {errors && (
        <span className="primary-3 size-8 error-message mt-1 position-absolute">
          <ErrorMessage errors={errors} name={name} />
        </span>
      )}
    </div>
  );
}
