import React from "react";

interface Props {
  onClick?: () => void;
}

export default function StarButton({ onClick = () => {} }: Props) {
  return (
    <button className="star-btn btn p-0 border-0 primary-1-bg rounded-circle">
      <img src="/icons/star.svg" width={16} alt="start" />
    </button>
  );
}
