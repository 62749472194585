import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTrackningOrderById } from "@/api/profile";
import { useTranslation } from "react-i18next";
import Button from "../../../components/buttons";
import { Context, copy, getRecordsSum } from "../../../components/utils";
import AddressCard from "../AddressCard";
import Services from "@/components/modals/Services";
import UpdateTrackingOrder from "@/components/modals/order-update/UpdateTrackingOrder";
import { deleteTrackingOrderById } from "@/api/order";
import LoaderLayout from "@/components/LoaderLayout";
import ConfirmModal from "@/components/modals/ConfirmModal";
import { SET_CONFIRM, SET_PAYMENT } from "@/controllers/actions";
import Payment from "@/components/modals/payment";

export default function TrackingOrder() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    state: { confirm, payment },
    dispatch,
  } = useContext(Context);
  const [order, setOrder] = useState<any>();
  const [locales, setLocales] = useState({
    content: {},
    edit: false,
    service: false,
    image: false,
    copy: false,
    loading: false,
  });

  const setPayment = () => {
    dispatch({
      type: SET_PAYMENT,
      payload: {
        ...payment,
        resource: order,
        resourceType: "order",
        isOpen: true,
        step: 5,
      },
    });
  };

  const copyContent = (htmlId: string) => {
    setLocales({ ...locales, copy: true });
    copy(htmlId);
    setTimeout(() => {
      setLocales({ ...locales, copy: false });
    }, 3000);
  };

  const getOrder = async () => {
    setLocales({ ...locales, loading: true });
    try {
      const { data } = await getTrackningOrderById(id!);
      setOrder(data);
    } catch (error) {
      console.log(error);
    }
    setLocales({ ...locales, loading: false });
  };

  const deleteOrder = async () => {
    setLocales({ ...locales, loading: true });
    try {
      await deleteTrackingOrderById(order.id);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
    setLocales({ ...locales, loading: false });
    dispatch({ type: SET_CONFIRM, payload: false });
  };

  useEffect(() => {
    if (id) {
      getOrder();
    }
  }, [id]);

  return (
    <div className="order-content">
      <LoaderLayout
        loading={locales.loading}
        style={{ width: "50px", margin: "16px auto" }}
        children={
          order && (
            <>
              <div className="order-content-header d-flex-center justify-content-between">
                <Button
                  className="size-3 back-btn p-0"
                  onClick={() => navigate(-1)}
                >
                  <img
                    src="/icons/arrow-left.svg"
                    className="mr-3"
                    width={40}
                    alt="back"
                  />
                  <h1 className="natural-2 text-semi-bold m-0 size-4">
                    {t("Заказ")}&nbsp;№{order.id}
                  </h1>
                </Button>
                <div className="d-flex-center justify-content-between">
                  <div className="status natural-8 size-8 primary-5-bg py-2 px-3 text-bold">
                    <span className="opacity-75">{t("Статус")}:</span>&nbsp;
                    {t(`trackingStatus.${order.status}`)}
                  </div>
                  <Button
                    className="change-btn ml-3 natural-6-border natural-8 size-8"
                    onClick={() => setLocales({ ...locales, edit: true })}
                  >
                    <img src="/icons/pen-edit.svg" width={24} alt="star" />
                  </Button>
                </div>
              </div>
              <hr className="my-3" />
              <div className="order-content-body">
                <div className="order-content-body-top d-flex-center">
                  <div className="flex-grow">
                    <h5 className="text-medium natural-4 size-7 mb-1">
                      {t("Дата создания")}
                    </h5>
                    <h5 className="text-bold natural-2 size-7 m-0">
                      {order.created_at.split("T")[0].replaceAll("-", ".")}
                      {", "}
                      {order.created_at.split("T")[1].slice(0, 5)}
                    </h5>
                  </div>
                  <div className="flex-grow">
                    <h5 className="text-medium natural-4 size-7 mb-1">
                      {t("Дата прибытия")}
                    </h5>
                    <h5 className="text-bold natural-2 size-7 m-0">
                      {order.updated_at.split("T")[0].replaceAll("-", ".")}
                    </h5>
                  </div>
                  <div className="flex-grow mb-2">
                    <h5 className="text-medium natural-4 size-7 mb-1">
                      {t("Трек-номер")}
                    </h5>
                    <button
                      id="truck-num"
                      className="btn d-flex-center copy-btn text-bold natural-2 size-7 m-0 p-0 bg-transparent border-0"
                      onClick={() => copyContent("truck-num")}
                    >
                      {order.tracking}
                      <img
                        src={`/icons/${locales.copy ? "copied" : "copy"}.svg`}
                        width={16}
                        alt="copy"
                      />
                    </button>
                  </div>
                </div>
                <div className="order-content-body-addresses row">
                  <div className="col-md-12">
                    <AddressCard address={order.recipient_address} />
                  </div>
                </div>
                <div className="order-content-body-info">
                  <div className="d-flex-center justify-content-between">
                    <h5 className="size-8 m-0 text-medium natural-4">
                      {t("Дополнительные услуги")} (
                      {order.additional_services.length})
                    </h5>
                    <h5 className="size-8 m-0 text-bold natural-2 text-right">
                      ${" "}
                      {order.additional_services.reduce(
                        (v: number, d: ServiceType) => v + d.price,
                        0
                      )}
                    </h5>
                  </div>
                  <div className="d-flex-center justify-content-between mb-3">
                    <h5 className="size-8 m-0 text-medium natural-4">
                      {t("Оставшаяся сумма платежа")}
                    </h5>
                    <Button
                      className="payment-btn natural-8 size-8 py-2 px-3 text-bold"
                      onClick={setPayment}
                    >
                      $ {getRecordsSum(order.records)}
                    </Button>
                  </div>
                  <hr />
                  <h4 className="size-8 mb-2 text-medium natural-4">
                    {t("Общая стоимость товаров")}
                  </h4>
                  <div className="d-flex-center justify-content-between">
                    <h5
                      className="size-6 text-bold primary-1"
                      style={{ marginBottom: "0px" }}
                    >
                      $ {getRecordsSum(order.records)}
                    </h5>
                    <Button
                      className="delete-btn"
                      onClick={() =>
                        dispatch({ type: SET_CONFIRM, payload: true })
                      }
                    >
                      {t("Удалить заказ")}
                    </Button>
                  </div>
                </div>
                <div className="order-content-body-decloration">
                  <h5 className="size-6 m-0 text-bold natural-2">
                    {t("Декларация")}
                  </h5>
                  <div className="table mb-0">
                    <table className="mb-0">
                      <thead className="primary-1-bg">
                        <tr>
                          <th
                            scope="col"
                            className="size-7 text-bold natural-8"
                          >
                            №
                          </th>
                          <th
                            scope="col"
                            className="size-7 text-bold natural-8"
                          >
                            {t("Название посылки")}
                          </th>
                          <th
                            scope="col"
                            className="size-7 text-bold natural-8"
                          >
                            {t("Количество")}
                          </th>
                          <th
                            scope="col"
                            className="size-7 text-bold natural-8"
                          >
                            {t("Стоимость")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.records.map((record: any) => (
                          <tr key={record.id}>
                            <td scope="row" className="size-7 text-bold">
                              {record.id}
                            </td>
                            <td className="size-7 text-medium natural-4">
                              {record.product_name}
                            </td>
                            <td className="size-7 text-medium natural-4">
                              {record.quantity} {t("шт")}.
                            </td>
                            <td className="size-7 text-medium natural-4">
                              $ {record.price}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )
        }
      />
      {locales.service && (
        <Services content={locales.content} setModal={setLocales} />
      )}
      {locales.edit && (
        <UpdateTrackingOrder
          modal={locales}
          setModal={setLocales}
          order={order}
          setOrder={setOrder}
        />
      )}
      {payment.isOpen && <Payment />}
      {confirm && (
        <ConfirmModal
          loading={locales.loading}
          onClick={deleteOrder}
          text="Вы уверены, что хотите удалить?"
        />
      )}
    </div>
  );
}
