import React from "react";
import ReactPaginate from "react-paginate";

interface Props {
  className?: string;
  pageCount: number;
  onChange: (data: any) => void;
}

export const Pagination = ({ pageCount, onChange, className = "" }: Props) => {
  return pageCount ? (
    <ReactPaginate
      className={`pagination ${className}`}
      breakLabel="..."
      nextLabel=">"
      onPageChange={onChange}
      pageRangeDisplayed={2}
      marginPagesDisplayed={3}
      pageCount={pageCount}
      previousLabel="<"
      activeClassName="active"
    />
  ) : null;
};

export default React.memo(Pagination);
