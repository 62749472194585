import React from "react";
import { useTranslation } from "react-i18next";
import Banner from "../banners";

export default function MapSection() {
  const { t } = useTranslation();

  return (
    <section className="map-section">
      <div className="container">
        <h2 className="size-2 text-bold">
          {t("Мы привезём ваш товар из")}{" "}
          <span className="primary-1 text-bold">30 {t("стран")}</span>
        </h2>
        <p className="size-7 lh-24 text-medium natural-4">
          {t(
            "Наши профессионалы гарантируют быструю и надежную доставку вашего товара прямо к вашему порогу."
          )}
        </p>
        <Banner src="/images/map.png" />
      </div>
    </section>
  );
}
