import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";

interface Props {
  register: any;
  name: string;
  errors?: object;
  disabled?: boolean;
  validations?: object;
  placeholder?: string;
  label?: string;
  options: Array<any>;
  inputClass?: string;
  labelClass?: string;
  parentClass?: string;
}

export default function Select({
  register,
  name,
  errors,
  validations,
  label,
  disabled,
  options = [],
  placeholder = "",
  inputClass = "",
  labelClass = "",
  parentClass = "",
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={`custom-input position-relative ${parentClass}`}>
      {label && (
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
      )}
      <select
        className={`form-control ${inputClass}`}
        disabled={disabled}
        {...register(name, validations)}
      >
        {placeholder && (
          <option disabled value={0}>
            {t(placeholder)}
          </option>
        )}
        {options.map((option, i) => (
          <option value={option.value} key={i}>
            {t(option.label)}
          </option>
        ))}
      </select>
      {errors && (
        <span className="primary-3 size-8 error-message mt-1 position-absolute">
          <ErrorMessage errors={errors} name={name} />
        </span>
      )}
    </div>
  );
}
