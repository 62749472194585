import Button from "@/components/buttons";
import { Context, getOrderTypes } from "@/components/utils";
import { SET_NEW_ORDER } from "@/controllers/actions";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function OrderTypeSelector() {
  const { t } = useTranslation();
  const {
    state: {
      newOrder,
      settings: { location_action },
    },
    dispatch,
  } = useContext(Context);

  const orderTypes = getOrderTypes(location_action, newOrder.order.branch_id);

  const handleBack = () => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        prevStep: 3,
        currentStep: 2,
        order: { ...newOrder.order, order_type: null },
      },
    });
  };

  const onHandleClick = (type: number) => {
    dispatch({
      type: SET_NEW_ORDER,
      payload: {
        prevStep: 3,
        currentStep: type,
        order: { ...newOrder.order, order_type: type },
      },
    });
  };

  return (
    <>
      <Button className="size-3 back-btn" onClick={handleBack}>
        <img
          src="/icons/arrow-left.svg"
          className="mr-3"
          width={40}
          alt="back"
        />
        {t("Добавить ожидаемый заказ")}
      </Button>
      <div className="content-body mx-auto">
        {orderTypes.length ? (
          <div className="content">
            <h6 className="text-bold size-6">
              <span className="primary-1 text-bold">3. </span>
              {t("Выберите тип заказа")}
            </h6>
            {orderTypes.map((type) => (
              <Button
                key={type.value}
                className="selector-btn type-btn natural-6-border"
                onClick={() => onHandleClick(type.value)}
              >
                <h6 className="size-6 text-medium natural-2 lh-32 m-0">
                  {type.label}
                </h6>
                <div className="image-box rounded-circle d-flex-center justify-content-center">
                  <img src={type.icon} alt="icon" />
                </div>
              </Button>
            ))}
          </div>
        ) : (
          <h6 className="text-bold text-center size-6">{t("Скоро запуск")}</h6>
        )}
      </div>
    </>
  );
}
