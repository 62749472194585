import { Context } from "@/components/utils";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const address = [
  {
    countre: "Адрес в США",
    city: "Нью-Йорк",
    first_name: "Dmitriy",
    last_name: "Kovalyov BGIFI",
    addressLine1: "2227 Plumb 2nd street",
    addressLine2: "Unit C 1, UZB16858",
    city2: "Brooklyn",
    state: "New York",
    zipcode: "New York",
    phone: "+1 3476735700",
    phone2: "+1 6466759810",
  },
  {
    countre: "Адрес в США",
    city: "Нью-Йорк",
    first_name: "Dmitriy",
    last_name: "Kovalyov BGIFI",
    addressLine1: "2227 Plumb 2nd street",
    addressLine2: "Unit C 1, UZB16858",
    city2: "Brooklyn",
    state: "New York",
    zipcode: "New York",
    phone: "+1 3476735700",
    phone2: "+1 6466759810",
  },
];

export default function FreeAddresses() {
  const { t } = useTranslation();
  const {
    state: { user },
  } = useContext(Context);

  return (
    <>
      <h5 className="size-7 m-0 text-bold natural-2">
        {t("Ваши бесплатные адреса складов для онлайн покупок")}
      </h5>
      <div className="free-addresses d-flex-center justify-content-between">
        {address.map((address, i) => (
          <div className="address-box box-shadow" key={i}>
            <div className="box-header d-flex-center mb-3">
              <img src="/icons/marker.png" width={64} alt="location" />
              <div className="header-info ml-3">
                <h4 className="size-6 natural-2 text-bold">
                  {address.countre}
                </h4>
                <h4 className="size-7 primary-1 text-bold m-0">
                  {address.city}
                </h4>
              </div>
            </div>
            <div className="liner" />
            <div className="address-info d-flex-center justify-content-between">
              <h5 className="size-7 m-0 text-medium natural-4">
                {t("Firstname")}
              </h5>
              <h5 className="size-7 m-0 text-bold natural-2 text-right">
                {user.customer.first_name}
              </h5>
            </div>
            <div className="address-info d-flex-center justify-content-between">
              <h5 className="size-7 m-0 text-medium natural-4">
                {t("Lastname")}
              </h5>
              <h5 className="size-7 m-0 text-bold natural-2 text-right">
                {user.customer.last_name}
              </h5>
            </div>
            <div className="address-info d-flex-center justify-content-between">
              <h5 className="size-7 m-0 text-medium natural-4">
                {t("Address Line 1")}
              </h5>
              <h5 className="size-7 m-0 text-bold natural-2 text-right">
                {address.addressLine1}
              </h5>
            </div>
            <div className="address-info d-flex-center justify-content-between">
              <h5 className="size-7 m-0 text-medium natural-4">
                {t("Address Line 2")}
              </h5>
              <h5 className="size-7 m-0 text-bold natural-2 text-right">
                {address.addressLine2}
              </h5>
            </div>
            <div className="address-info d-flex-center justify-content-between">
              <h5 className="size-7 m-0 text-medium natural-4">{t("City")}</h5>
              <h5 className="size-7 m-0 text-bold natural-2 text-right">
                {address.city2}
              </h5>
            </div>
            <div className="address-info d-flex-center justify-content-between">
              <h5 className="size-7 m-0 text-medium natural-4">{t("State")}</h5>
              <h5 className="size-7 m-0 text-bold natural-2 text-right">
                {address.state}
              </h5>
            </div>
            <div className="address-info d-flex-center justify-content-between">
              <h5 className="size-7 m-0 text-medium natural-4">
                {t("Zipcode")}
              </h5>
              <h5 className="size-7 m-0 text-bold natural-2 text-right">
                {address.zipcode}
              </h5>
            </div>
            <div className="address-info d-flex-center justify-content-between">
              <h5 className="size-7 m-0 text-medium natural-4">
                {t("Telephone")}
              </h5>
              <h5 className="size-7 m-0 text-bold natural-2 text-right">
                {address.phone}
              </h5>
            </div>
            <div className="address-info d-flex-center justify-content-between">
              <h5 className="size-7 m-0 text-medium natural-4">{t("Cell")}</h5>
              <h5 className="size-7 m-0 text-bold natural-2 text-right">
                {address.phone2}
              </h5>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
