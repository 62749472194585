import { mainCaller } from "./mainCaller";

export const searchProducts = (keyword: string) => {
  return mainCaller(`/products/search?query=${keyword}&size=10`, "GET");
};

export const deleteShopOrderById = (id: number) => {
  return mainCaller(`/shop-orders/${id}`, "DELETE");
};

export const deleteTrackingOrderById = (id: number) => {
  return mainCaller(`/trackings/${id}`, "DELETE");
};

export const deleteOrderById = (id: number) => {
  return mainCaller(`/orders/${id}`, "DELETE");
};

export const deletePoroductById = (id: number) => {
  return mainCaller(`/orders/product/${id}`, "DELETE");
};

export const deleteTrackingRecordById = (id: number) => {
  return mainCaller(`/trackings/record/${id}`, "DELETE");
};

export const deleteShopRecordById = (id: number) => {
  return mainCaller(`/shop-orders/record/${id}`, "DELETE");
};

export const getSummery = (query: string) => {
  return mainCaller(`/${query}/summery`, "GET");
};

export const getPickupTimes = (date: string, country: number) => {
  return mainCaller(
    "/pickups/available",
    "GET",
    undefined,
    {},
    { date, country }
  );
};

export const createTrackingOrder = (data: object) => {
  return mainCaller("/trackings", "POST", data);
};

export const updateTrackingOrder = (id: number, data: object) => {
  return mainCaller(`/trackings/${id}`, "PUT", data);
};

export const shopRecordService = (id: number, data: object) => {
  return mainCaller(`/shop-orders/records/${id}/services`, "PUT", data);
};

export const trackingRecordService = (id: number, data: object) => {
  return mainCaller(`/trackings/${id}/services`, "PUT", data);
};

export const updateShopOrder = (id: number, data: object) => {
  return mainCaller(`/shop-orders/${id}`, "PUT", data);
};
export const updateOrder = (id: number, data: object) => {
  return mainCaller(`/orders/${id}`, "PUT", data);
};

export const createShopOrder = (data: object) => {
  return mainCaller("/shop-orders", "POST", data);
};

export const createPickup = (id: number, data: object) => {
  return mainCaller(`/orders/${id}/pickups`, "POST", data);
};

export const createSendOrder = (data: object) => {
  return mainCaller("/orders", "POST", data);
};
