import React from "react";
import CountrySelector from "./CountrySelector";
import BranchSelector from "./BranchSelector";
import OrderTypeSelector from "./OrderTypeSelector";
import TrackingOrder from "./TrackingOrder";
import ShopOrder from "./ShopOrder";
import SendOrder from "./SendOrder";
import AddressSelector from "./AddressSelector";
import OrderConfirm from "./OrderConfirm";
import RateSelector from "./RateSelector";

interface Props {
  currentStep: number;
}

export default function OrderStepSwitcher({ currentStep }: Props) {
  switch (currentStep) {
    case 1:
      return <CountrySelector />;
    case 2:
      return <BranchSelector />;
    case 3:
      return <OrderTypeSelector />;
    case 4:
      return <TrackingOrder />;
    case 5:
      return <ShopOrder />;
    case 6:
      return <SendOrder />;
    case 7:
      return <AddressSelector key={currentStep} />;
    case 8:
      return <AddressSelector key={currentStep} />;
    case 9:
      return <RateSelector />;
    case 10:
      return <OrderConfirm />;
    default:
      return <CountrySelector />;
  }
}
