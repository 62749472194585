import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomModal from ".";
import Button from "../buttons";
import CountrySelector from "../form-components/CountrySelector";
import Input from "../form-components";
import Checkbox from "../form-components/Checkbox";
import Rates from "../form-components/SelectRates";
import { weightOptions } from "../constants";
import WeightInput from "../form-components/WeightInput";
import { Context } from "../utils";

interface Props {
  modal: { calc?: boolean };
  setModal: (data: object) => void;
}

export default function Calculator({ modal, setModal }: Props) {
  const { t } = useTranslation();
  const countries = useContext(Context).state.settings.countries;
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sender_id: countries[0].id,
      receiver_id: countries[1].id,
      unit: weightOptions[0].value,
      measure: "sm",
      total: 0,
    } as any,
  });

  const closeModal = () => {
    setModal({ ...modal, calc: false });
  };

  const setRate = (name: string, rate: TariffType) => {
    setValue("rate", rate);
  };

  const setCountry = (name: string, id: number) => {
    setValue(name, id);
    setValue("rate", null);
  };

  const submit = async (values: any) => {
    if (values.rate) {
      let dimensionWeight = 0;
      if (values.dimension) {
        if (values.unit === "kg/sm") {
          console.log("aa");

          dimensionWeight =
            ((values.length || 0) *
              (values.width || 0) *
              (values.height || 0)) /
            5000;
        } else {
          console.log("bbb");

          dimensionWeight =
            ((values.length || 0) *
              (values.width || 0) *
              (values.height || 0)) /
            139;
        }
      }
      let weight = values.weight || 0;
      weight = values.dimension ? dimensionWeight : weight;
      setValue("weight", weight);
      const field = values.unit === "kg/sm" ? "rate_kg" : "rate";
      setValue(
        "total",
        (weight < 1
          ? +values.rate.min_price
          : weight * +values.rate.weight_rate[field]
        ).toFixed(2)
      );
    } else alert("Please select a rate");
  };

  return (
    <CustomModal
      className="calculator-modal"
      isOpen={modal.calc!}
      closeModal={closeModal}
    >
      <div className="react-modal-header">
        <div>
          <h5 className="size-5 natural-2 mb-1 text-bold">
            {t("Расчет стоимости доставки")}
          </h5>
          <h6 className="size-7 primary-1 text-medium mb-0">
            {t("Давайте посчитаем примерную стоимость доставки")}
          </h6>
        </div>
        <Button
          className="modal-close-btn natural-6-border"
          onClick={closeModal}
        >
          <img src="/icons/close.svg" width={24} alt="close" />
        </Button>
      </div>
      <div className="react-modal-body position-relative">
        <form className="form">
          <div className="d-flex-center gap-20">
            <CountrySelector
              name="sender_id"
              parentClass="flex-grow "
              options={countries}
              setValue={setCountry}
              watch={watch}
              label={t("Откуда")}
              labelClass="size-7 natural-5 text-medium mb-1"
            />
            <CountrySelector
              name="receiver_id"
              parentClass="flex-grow"
              options={countries}
              setValue={setCountry}
              watch={watch}
              label={t("Куда")}
              labelClass="size-7 natural-5 text-medium mb-1"
            />
          </div>
          <WeightInput
            name="weight"
            selectName="unit"
            validations={{
              required: watch("dimension") ? false : t("This is required."),
            }}
            register={register}
            errors={errors}
            labelClass="size-7 natural-5 text-medium m-0"
            label={t("Вес")}
          />
          <Rates
            order={{
              unit: watch("unit"),
              sender_address: {
                country: { id: watch("sender_id") },
              },
              recipient_address: {
                country: { id: watch("receiver_id") },
              },
            }}
            setValue={setRate}
            parentClass="d-flex-center"
            label={t("Выберите тарифный план доставки *")}
            labelClass="size-7 natural-5 text-medium m-0 rate-label"
          />
          <Checkbox
            name="dimension"
            register={register}
            errors={errors}
            inputClass="w-auto"
            labelClass="m-0 natural-1 size-6 text-bold pointer"
            label={t("Расчет стоимости доставки крупногабаритных посылок")}
            parentClass="d-flex-center flex-row-reverse justify-content-end mb-3"
          />
          {watch("dimension") && (
            <div className="row mx-auto gap-20">
              <Input
                parentClass="d-flex flex-column"
                register={register}
                name="width"
                type="number"
                label={t("Ширина")}
                validations={{ required: t("This is required.") }}
                errors={errors}
                placeholder="0"
                labelClass="size-7 natural-5 text-medium m-0"
                inputClass="natural-6-border text-bold"
              />
              <Input
                parentClass="d-flex flex-column"
                register={register}
                name="height"
                type="number"
                label={t("Высота")}
                validations={{ required: t("This is required.") }}
                errors={errors}
                placeholder="0"
                labelClass="size-7 natural-5 text-medium m-0"
                inputClass="natural-6-border text-bold"
              />
              <Input
                parentClass="d-flex flex-column"
                register={register}
                name="length"
                type="number"
                label={t("Длина")}
                errors={errors}
                validations={{ required: t("This is required.") }}
                placeholder="0"
                labelClass="size-7 natural-5 text-medium m-0"
                inputClass="natural-6-border text-bold"
              />
              <div className="custom-input">
                <label className="fade">a</label>
                <input
                  disabled
                  {...register("measure")}
                  value={t(watch("unit") === "kg/sm" ? "sm" : "inch")}
                  type="text"
                  className="form-control natural-6-border text-bold"
                />
              </div>
            </div>
          )}
        </form>
        <hr />
        <h6 className="size-6 natural-4 text-semi-bold lh-32 title">
          {t("Стоимость авиаперевозки")}:{" "}
          <span className="natural-2 text-bold">$ {watch("total")}</span>
          <br />
          {t("Сроки доставки")}:{" "}
          <span className="natural-2 text-bold">7-10 {t("дней")}</span>
        </h6>
        <h6 className="size-9 natural-4 lh-20 ws-pw">
          {t(
            "Хотите быстро получить ответ на свой вопрос?\nИзучите раздел сайта Вопросы-ответы, в котором мы собрали ответы на самые популярные вопросы.\n\nЕсли ваш вопрос связан с тем, когда внесут входящую посылку в систему, то напоминаем, что на внесение груза нам нужно 1-2 рабочих дня, в редких случаях чуть больше. Советуем писать по этому вопросу только если груз не внесен более 3-х дней."
          )}
        </h6>
        <Button
          className="submit primary-1-bg w-100 natural-8 size-7"
          onClick={handleSubmit(submit)}
        >
          {t("Посчитать доставку")}
        </Button>
        <Button className="natural-6-border w-100 size-7" onClick={closeModal}>
          {t("Отмена")}
        </Button>
      </div>
    </CustomModal>
  );
}
