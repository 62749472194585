import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SignUp from "./SignUp";
import ChangePassword from "./ChangePassword";
import SignIn from "./SignIn";
import CheckCode from "./CheckCode";
import { Context } from "@/components/utils";

export default function AuthContentSwitcher() {
  const { t } = useTranslation();
  const currentStep = useContext(Context).state.authStep.currentStep;
  const [active, setActive] = useState(0);

  const handleTabClick = (index: number) => {
    setActive(index);
  };

  switch (currentStep) {
    case 1:
      return (
        <Tabs selectedIndex={active} onSelect={handleTabClick}>
          <h1 className="natural-2 text-semi-bold my-3 size-3">
            {t(active === 0 ? "Войти в личный кабинет " : "Регистрация")}
          </h1>
          <TabList>
            <Tab className="simple-btn size-8">{t("Вход")}</Tab>
            <Tab className="simple-btn size-8">{t("Регистрация")}</Tab>
          </TabList>
          <TabPanel>
            <SignIn />
          </TabPanel>
          <TabPanel>
            <SignUp />
          </TabPanel>
        </Tabs>
      );
    case 2:
      return <ChangePassword />;
    default:
      return <CheckCode />;
  }
}
