import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../buttons";

export default function WhyUsSection() {
  const { t } = useTranslation();

  return (
    <section className="why-us-section">
      <div className="container">
        <h1 className="size-1 text-bold ws-pw mb-3">
          {t("Почему")}&nbsp;
          <br className="d-md-block d-none" />
          {t("стоит")}&nbsp;
          <br className="d-md-none d-block" />
          {t("выбрать")}&nbsp;
          <br className="d-md-block d-none" />
          {t("нас?")}
        </h1>
        <div className="row">
          <div className="col-md-4 col-6 left pr-md-0">
            <div className="reason-card">
              <h6 className="size-6 text-bold natural-3 lh-32">
                {t("Доставляем из магазинов США")}
              </h6>
              <p className="size-7 text-bold natural-4 lh-24">
                {t(
                  "Доставим ваши посылки от 7 дней и от $10, дадим возможность следить за процессом 24/7, проведем через таможню."
                )}
              </p>
            </div>
            <div className="reason-card">
              <h6 className="size-6 text-bold natural-3 lh-32">
                {t("Честная и прозрачная система расчетов")}
              </h6>
              <p className="size-7 text-bold natural-4 lh-24">
                {t(
                  "Логичная и понятная система расчетов за товары и услуги; Вы всегда в курсе, на что и как потрачены ваши деньги; Никаких скрытых платежей и доплат"
                )}
              </p>
            </div>
          </div>
          <div className="col-md-4 col-6 middle">
            <div className="reason-card">
              <h6 className="size-6 text-bold natural-3">
                {t(
                  "Вы всегда знаете, где сейчас находится ваша посылка и когда ее ждать"
                )}
              </h6>
              <p className="size-7 text-bold natural-4 lh-24 m-0">
                {t(
                  "Показываем информацию по доставке каждой партии в режиме реального времени."
                )}
              </p>
            </div>
            <div className="reason-card">
              <h6 className="size-6 text-bold natural-3 lh-32">
                {t("Поддержка клиентов 7 дней в неделю")}
              </h6>
              <p className="size-7 text-bold natural-4 lh-24 m-0">
                {t(
                  "Мы всегда на связи; Готовы выполнить любой ваш запрос или найдем достойную альтернативу Вы всегда можете рассчитывать на нашу помощь в любом вопросе"
                )}
              </p>
            </div>
          </div>
          <div className="col-md-4 col-12 right pl-md-0">
            <div className="reason-card">
              <h4 className="size-4 text-bold natural-8 lh-32 mb-3 ls-0">
                {t("Поддержка клиентов 7 дней в неделю")}
              </h4>
              <p className="size-7 text-bold natural-8 lh-24 m-0">
                {t(
                  "Мы всегда на связи; Готовы выполнить любой ваш запрос или найдем достойную альтернативу Вы всегда можете рассчитывать на нашу помощь в любом вопросе"
                )}
              </p>
              <Button onClick={() => {}} className="more-btn">
                {t("Подробнее")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
